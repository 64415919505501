import React, {Component} from 'react';
import sortingArr, {getFormated} from '../functions/functions'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faSpinner} from '@fortawesome/free-solid-svg-icons'

class TableCountry extends Component {
    showCampaignsInCountry = (data, e) => {
        e.preventDefault();
        this.props.showCampaignsInCountry(data, e);
    };

    render() {
        const {Countries, sorting, targetROAS, top5} = this.props;
        const _this = this;
        let newCountries = [];
        if (Countries !== undefined) {

            let countries = Countries;

            for (let key in countries) {
                console.log(countries[key]);
                if (countries[key] !== undefined && countries[key].metrics !== undefined) {
                    if (countries[key].metrics.tracked_installs <= 0 && countries[key].metrics.spend <= 0) {

                    } else {
                        countries[key].name = key;
                        newCountries.push(countries[key]);
                    }
                }

            }
        }


        if (sorting !== "") {
            newCountries = sortingArr(newCountries, sorting, true, targetROAS);
        }
        if (top5) {
            newCountries = newCountries.slice(0, 5);
        }

        return (
            <>
                {
                    Object.keys(newCountries).map(function (objectKey, d) {
                        let country = newCountries[objectKey];
                        let metrics = country.metrics;
                        let PredictedGrossProfit = "";
                        let PredictedROAS = "";
                        let RecommendCPI = "";
                        let prevDownloads = "";
                        let prevSpend = "";
                        let prevMetrics = null;
                        let predictedType = "Direct";
                        let prevPredictedGrossProfit = "";
                        let prevPredictedROAS = "";
                        let predicted = null;


                        if (country.predicted === undefined || country.predicted.tracked_installs < 100) {
                            predicted = _this.props.predicted;
                            predictedType = "Indirect";
                            if(predicted !== null) {
                                predicted.predictedType = "Indirect";
                            }
                        }   else {
                            predicted = country.predicted;
                        }

                        country.predicted_k = predicted.total_rev_30d / predicted.total_rev_1d;

                        let newCampaigns = [];
                        country.showCampaigns && country.campaigns !== null && Object.keys(country.campaigns).map(function (objectKey, b) {
                            newCampaigns.push(country.campaigns[objectKey].attributes);
                            return null;
                        });
                        if (newCampaigns.length > 0) {
                            console.log(newCampaigns);
                            if (sorting !== "") {
                                newCampaigns = sortingArr(newCampaigns, sorting, false,targetROAS);
                                console.log(sorting);
                                console.log(newCampaigns);
                            }
                        }

                        if (country.prevMetrics !== undefined) {
                            prevMetrics = country.prevMetrics;
                        }

                        if (country.predicted_k !== undefined && country.predicted_k > 0 && country.total_rev_1d > 0 && country.spend > 1) {
                            PredictedGrossProfit = ((country.total_rev_1d * country.predicted_k) - country.spend);
                            PredictedROAS = ((country.total_rev_1d * country.predicted_k) / country.spend) * 100;
                            if (country.cpi !== null) {
                                RecommendCPI = country.cpi * (PredictedROAS/targetROAS);
                                RecommendCPI = "$" + (RecommendCPI).toFixed(2);
                                RecommendCPI = RecommendCPI.replace("$-", "-$");
                            }
                            PredictedROAS = (PredictedROAS).toFixed(2) + "%";

                            PredictedGrossProfit = "$" + (PredictedGrossProfit).toFixed(2);
                            PredictedGrossProfit = PredictedGrossProfit.replace("$-", "-$");
                        }

                        if (prevMetrics !== null) {
                            if (country.predicted_k !== undefined && country.predicted_k > 0 && prevMetrics.total_rev_1d > 0 && country.spend > 1) {
                                let predictedGrossProfit = ((country.total_rev_1d * country.predicted_k) - country.spend);
                                let predictedRoas = ((country.total_rev_1d * country.predicted_k) / country.spend) * 100;
                                prevPredictedGrossProfit = ((prevMetrics.total_rev_1d * country.predicted_k) - prevMetrics.spend);
                                prevPredictedROAS = ((prevMetrics.total_rev_1d * country.predicted_k) / prevMetrics.spend) * 100;

                                prevPredictedGrossProfit = ((predictedGrossProfit - prevPredictedGrossProfit) / prevPredictedGrossProfit * 100).toFixed(2);
                                prevPredictedROAS = ((predictedRoas - prevPredictedROAS) / prevPredictedROAS * 100).toFixed(2);
                            }

                            prevDownloads = ((country.tracked_installs - prevMetrics.tracked_installs) / prevMetrics.tracked_installs * 100).toFixed(2);
                            prevSpend = ((country.spend - prevMetrics.spend) / prevMetrics.spend * 100).toFixed(2);

                        }


                        return (
                            <>
                                <tr key={d}>
                                    <th className="country-name">
                                        <a href="#/"
                                           onClick={_this.showCampaignsInCountry.bind(this, {
                                               key: _this.props.channel_id + "" + _this.props.app_id,
                                               country: country.name
                                           })}
                                        >
                                            {country.showCampaigns
                                                ? <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                                : ""
                                            }
                                            {country.name}
                                        </a>
                                    </th>
                                    <th>
                                        <span
                                            className="left-value">{metrics.tracked_installs > 0 ? getFormated(metrics.tracked_installs) : 0} </span>
                                        {prevDownloads !== "" && !isNaN(prevDownloads) && prevDownloads !== "Infinity" ?
                                            <span
                                                className={prevDownloads >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                                    <span className="prev">{prevDownloads}%</span>
                                                                    <span
                                                                        className="prevValue">{
                                                                        prevMetrics.tracked_installs !== undefined
                                                                        ? getFormated((prevMetrics.tracked_installs).toFixed(2))
                                                                            : 0
                                                                    }</span>
                                             </span>
                                            :
                                            <span className="stat"> </span>
                                        }</th>
                                    {metrics !== undefined && metrics.cpi !== null ?
                                        <th>
                                        <span className="left-value">
                                            ${metrics.cpi.toFixed(2)}
                                        </span>
                                        </th>
                                        :
                                        <th>-</th>
                                    }
                                    {metrics.spend > 1 ?
                                        <th>
                                            <span className="left-value">${metrics.spend.toFixed(2)} </span>
                                            {prevSpend !== "" && !isNaN(prevSpend) && prevSpend !== "Infinity" ?
                                                <span
                                                    className={prevSpend >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                                    <span className="prev">{prevSpend}%</span>
                                                                    <span
                                                                        className="prevValue">${
                                                                        prevMetrics.spend !== undefined
                                                                        ? (prevMetrics.spend).toFixed(2)
                                                                            : 0
                                                                    }</span>
                                                </span>
                                                :
                                                <span className="stat"> </span>
                                            }</th>
                                        :
                                        <th>
                                            <span className="left-value">$0.00</span><span className="stat"> </span>
                                        </th>
                                    }
                                    {metrics.total_rev_120d > 1 ?
                                        <th>${metrics.total_rev_120d.toFixed(2)}
                                        </th>
                                        :
                                        <th>-</th>
                                    }
                                    {metrics.total_rev_120d > 1 && metrics.spend > 1 ?
                                        <th>
                                            {('$' + ((metrics.total_rev_120d - metrics.spend).toFixed(2))).replace("$-", "-$")}
                                        </th>
                                        :
                                        <th>-</th>
                                    }
                                    <th>
                                        <span className="left-value">
                                        {PredictedGrossProfit !== "" ? PredictedGrossProfit : "-"}
                                        </span>
                                        {prevPredictedGrossProfit !== "" && prevPredictedGrossProfit !== "Infinity" ?
                                            <span
                                                className={prevPredictedGrossProfit >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                <span className="prev">{prevPredictedGrossProfit}%</span>
                                                <span
                                                    className="prevValue">{("$"+((prevMetrics.total_rev_1d * country.predicted_k) - prevMetrics.spend).toFixed(2)).replace("$-","-$")}</span>
                                            </span>
                                            :
                                            <span className="stat"> </span>
                                        }
                                    </th>
                                    <th>
                                        <span className="left-value">
                                        {PredictedROAS !== "" ? PredictedROAS : "-"}
                                        </span>
                                        {prevPredictedROAS !== "" && prevPredictedROAS !== "Infinity" ?
                                            <span
                                                className={prevPredictedROAS >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                <span className="prev">{prevPredictedROAS}%</span>
                                                <span
                                                    className="prevValue">{(((prevMetrics.total_rev_1d * country.predicted_k) / prevMetrics.spend) * 100).toFixed(2)}%</span>
                                            </span>
                                            :
                                            <span className="stat"> </span>
                                        }
                                    </th>
                                    <th>{RecommendCPI !== "" ? RecommendCPI : "-"}</th>
                                    <th>{
                                        country.predicted_installs !== undefined ?
                                            country.predicted_installs > 100 ?
                                                <span className="stat stat-good">Good</span>
                                                :
                                                <span className="stat stat-normal">Normal</span>
                                            : "-"
                                    }</th>
                                    <th>{predictedType}</th>
                                    <th>{country.predicted_k !== undefined && country.predicted_k !== null ?
                                        country.predicted_k.toFixed(2)
                                    : "-"}</th>
                                    <th>-</th>
                                </tr>
                                {country.showCampaigns && newCampaigns !== null && Object.keys(newCampaigns).map(function (objectKey, c) {
                                    let campaign = newCampaigns[objectKey];
                                    campaign.metrics = campaign;
                                    let prevMetrics = null;

                                    //let id = campaigns[objectKey].id;
                                    //                         let campaign = campaigns[id];
                                    // console.log(campaign);
                                    if (campaign.metrics === undefined) {
                                        return null;
                                    }
                                    if (campaign.prevMetrics !== undefined) {
                                        prevMetrics = campaign.prevMetrics;
                                    }
                                    let metrics = campaign.metrics;

                                    // Значения для вывода процентного изменения показателей
                                    let prevDownloads = "";
                                    let prevSpend = "";
                                    let PredictedGrossProfit = "";
                                    let PredictedROAS = "";
                                    let predictedType = "Direct";
                                    let prevPredictedGrossProfit = "";
                                    let prevPredictedROAS = "";
                                    let predicted_k = 0;
                                    let predictedCountry = null;
                                    let predicted_installs = 0;


                                    if (campaign.predicted === undefined || campaign.predicted.tracked_installs < 100) {
                                        predictedType = "Indirect";
                                        predictedCountry = predicted;
                                        if(predictedCountry !== null) {
                                            predictedCountry.predictedType = "Indirect";
                                        }
                                    }   else {
                                        predictedCountry = campaign.predicted;
                                    }

                                    predicted_k = predictedCountry.total_rev_30d / predictedCountry.total_rev_1d;
                                    predicted_installs = predictedCountry.tracked_installs;



                                    if (prevMetrics !== null) {
                                        if (predicted_k !== undefined && predicted_k > 0 && prevMetrics.total_rev_1d > 0 && metrics.spend > 1) {
                                            let predictedGrossProfit = ((metrics.total_rev_1d * predicted_k) - metrics.spend);
                                            let predictedRoas = ((metrics.total_rev_1d * predicted_k) / metrics.spend) * 100;
                                            prevPredictedGrossProfit = ((prevMetrics.total_rev_1d * predicted_k) - prevMetrics.spend);
                                            prevPredictedROAS = ((prevMetrics.total_rev_1d * predicted_k) / prevMetrics.spend) * 100;

                                            prevPredictedGrossProfit = ((predictedGrossProfit - prevPredictedGrossProfit) / prevPredictedGrossProfit * 100).toFixed(2);
                                            prevPredictedROAS = ((predictedRoas - prevPredictedROAS) / prevPredictedROAS * 100).toFixed(2);
                                        }

                                        prevDownloads = ((metrics.tracked_installs - prevMetrics.tracked_installs) / prevMetrics.tracked_installs * 100).toFixed(2);
                                        prevSpend = ((metrics.spend - prevMetrics.spend) / prevMetrics.spend * 100).toFixed(2);

                                    }

                                    if (predicted_k !== undefined && predicted_k > 0 && metrics.total_rev_1d > 0 && metrics.spend > 1) {
                                        console.log(predicted_k);
                                        PredictedGrossProfit = ((metrics.total_rev_1d * predicted_k) - metrics.spend);
                                        PredictedROAS = ((metrics.total_rev_1d * predicted_k) / metrics.spend) * 100;
                                        PredictedROAS = (PredictedROAS).toFixed(2) + "%";

                                        PredictedGrossProfit = "$" + (PredictedGrossProfit).toFixed(2);
                                        PredictedGrossProfit = PredictedGrossProfit.replace("$-", "-$");

                                    }

                                    return (
                                        <>
                                            <tr key={c}>
                                                <th className="campaign-name">
                                                    {campaign.name}
                                                </th>
                                                <th>
                                                    <span className="left-value">{getFormated(metrics.tracked_installs)} </span>
                                                    {prevDownloads !== "" && !isNaN(prevDownloads) ?
                                                        <span
                                                            className={prevDownloads >= 0 ? "stat stat-good" : "stat stat-bad"}>{prevDownloads}%</span>
                                                        :
                                                        <span className="stat"> </span>
                                                    }</th>
                                                <th>-</th>
                                                {metrics.spend > 1 ?
                                                    <th>
                                                        <span className="left-value">${metrics.spend.toFixed(2)} </span>
                                                        {prevSpend !== "" ?
                                                            <span
                                                                className={prevSpend >= 0 ? "stat stat-good" : "stat stat-bad"}>{prevSpend}%</span>
                                                            :
                                                            <span className="stat"> </span>
                                                        }</th>
                                                    :
                                                    <th><span className="left-value">$0.00</span><span
                                                        className="stat"> </span></th>
                                                }
                                                {metrics.total_rev_120d > 1 ?
                                                    <th>
                                                        ${metrics.total_rev_120d.toFixed(2)}
                                                    </th>
                                                    :
                                                    <th>-</th>
                                                }
                                                {metrics.total_rev_120d > 1 && metrics.spend > 1 ?
                                                    <th>
                                                        {('$' + ((metrics.total_rev_120d - metrics.spend).toFixed(2))).replace("$-", "-$")}
                                                    </th>
                                                    :
                                                    <th>-</th>
                                                }

                                                <th>
                                                    <span className="left-value">
                                                        {PredictedGrossProfit !== "" ? PredictedGrossProfit : "-"}
                                                    </span>
                                                        {prevPredictedGrossProfit !== "" && prevPredictedGrossProfit !== "Infinity" ?
                                                        <span
                                                            className={prevPredictedGrossProfit >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                            <span className="prev">{prevPredictedGrossProfit}%</span>
                                                            <span
                                                    className="prevValue">{("$"+((prevMetrics.total_rev_1d * predicted_k) - prevMetrics.spend).toFixed(2)).replace("$-","-$")}</span>
                                                        </span>
                                                        :
                                                        <span className="stat"> </span>
                                                    }
                                                </th>
                                                <th>
                                                    <span className="left-value">
                                                         {PredictedROAS !== "" ? PredictedROAS : "-"}
                                                    </span>
                                                        {prevPredictedROAS !== "" && prevPredictedROAS !== "Infinity" ?
                                                        <span
                                                            className={prevPredictedROAS >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                            <span className="prev">{prevPredictedROAS}%</span>
                                                            <span
                                                    className="prevValue">{(((prevMetrics.total_rev_1d * predicted_k) / prevMetrics.spend) * 100).toFixed(2)}%</span>
                                                        </span>
                                                        :
                                                        <span className="stat"> </span>
                                                    }
                                                </th>
                                                <th>-</th>
                                                <th>{
                                                    predicted_installs !== undefined ?
                                                        predicted_installs > 100 ?
                                                            <span className="stat stat-good">Good</span>
                                                            :
                                                            <span className="stat stat-normal">Normal</span>
                                                        : "-"
                                                }</th>
                                                <th>{predictedType}</th>
                                                <th>{predicted_k.toFixed(2)}</th>
                                                <th>
                                                    {predicted_k !== undefined && !_this.props.showDetails ?
                                                        <a href="#/"
                                                           onClick={_this.props.getDetails.bind(this,  {
                                                               predicted: predictedCountry,
                                                               app_id: _this.props.app_id,
                                                               channel_id: _this.props.channel_id,
                                                               campaign_id: campaign.campaign_id,
                                                               country: country.name,
                                                           })}
                                                        >
                                                            History
                                                        </a>
                                                        : <FontAwesomeIcon icon={faSpinner} spin size="lg"/>
                                                    }
                                                </th>
                                            </tr>
                                        </>
                                    )
                                })}
                            </>
                        )
                    })
                }
            </>
        )

    }

}


export default TableCountry;
