import React, {Component} from 'react';
import sortingArr, {getFormated} from '../functions/functions'
import TableCountries from "../table-countries";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faSpinner} from '@fortawesome/free-solid-svg-icons'

import './table-campaigns.css';

class TableCampaigns extends Component {
    state = {
        Campaigns: [],
        organicK: 0,
    };

    ShowCountries = (key, e) => {
        e.preventDefault();
        let campaigns = this.state.Campaigns;
        campaigns[key].showCounties = !campaigns[key].showCounties;
        this.setState(() => {
            return {
                Campaigns: campaigns,
            }
        })
    };

    componentDidMount() {
        const {campaigns} = this.props;
        let newCampaigns = [];
        for (let key in campaigns) {
            if (campaigns[key] && campaigns[key].metrics !== undefined) {
                newCampaigns.push(campaigns[key]);
            }
        }
        this.setState(() => {
            return {
                Campaigns: newCampaigns,
            }
        })
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {campaigns} = this.props;
        const curCampaigns = this.state.Campaigns;
        let newCampaigns = [];
        for (let key in campaigns) {
            if (campaigns[key] && campaigns[key].metrics !== undefined) {
                newCampaigns.push(campaigns[key]);
            }
        }
        if (newCampaigns.length > curCampaigns.length) {
            this.setState(() => {
                return {
                    Campaigns: newCampaigns,
                }
            })
        }
    }


    render() {
        const {sorting, targetROAS, top5, organicK, organic1LTV, organicInstalls} = this.props;

        console.log(organicK);
        console.log(organic1LTV);
        console.log(organicInstalls);


        const _this = this;
        let newCampaigns = this.state.Campaigns;

        if (sorting !== "") {
            newCampaigns = sortingArr(newCampaigns, sorting, true, targetROAS);
        }

        return (
            <>
                {
                    Object.keys(newCampaigns).map(function (objectKey, c) {
                        let campaign = newCampaigns[objectKey];
                        let prevMetrics = null;

                        //let id = campaigns[objectKey].id;
                        //                         let campaign = campaigns[id];
                        // console.log(campaign);
                        if (campaign.metrics === undefined) {
                            return null;
                        }
                        if (campaign.prevMetrics !== undefined) {
                            prevMetrics = campaign.prevMetrics;
                        }
                        let metrics = campaign.metrics;
                        if (campaign.attributes === undefined) {
                            return null;
                        }

                        // Значения для вывода процентного изменения показателей
                        let prevDownloads = "";
                        let prevSpend = "";
                        let PredictedGrossProfit = "";
                        let PredictedROAS = "";
                        let predictedCampaign = campaign.predicted;
                        let predicted_installs = 0;
                        if(predictedCampaign !== undefined && predictedCampaign.tracked_installs !== undefined) {
                            predicted_installs = predictedCampaign.tracked_installs;
                        }
                        let predictedType = "Direct";
                        let prevPredictedGrossProfit = "";
                        let prevPredictedROAS = "";
                        let predicted = null;
                        let predicted_k = undefined;


                        if (predicted_installs === undefined || predicted_installs < 100) {
                            predicted = _this.props.predicted;
                            predictedType = "Indirect";
                            if(predicted !== null) {
                                predicted.predictedType = "Indirect";
                                if(predicted.tracked_installs !== undefined && predicted.tracked_installs !== null && predicted.tracked_installs > 100) {
                                    predicted_k = predicted.total_rev_30d / predicted.total_rev_1d;
                                }   else {
                                    predicted_k = 2;
                                }
                            }

                        }   else {
                            predicted = predictedCampaign;
                            predicted_k = predicted.total_rev_30d / predicted.total_rev_1d;
                        }


                        if (prevMetrics !== null) {
                            if (predicted_k !== undefined && predicted_k > 0 && prevMetrics.total_rev_1d > 0 && metrics.spend > 1) {
                                let predictedGrossProfit = ((metrics.total_rev_1d * predicted_k) - metrics.spend);
                                let predictedRoas = ((metrics.total_rev_1d * predicted_k) / metrics.spend) * 100;
                                prevPredictedGrossProfit = ((prevMetrics.total_rev_1d * predicted_k) - prevMetrics.spend);
                                prevPredictedROAS = ((prevMetrics.total_rev_1d * predicted_k) / prevMetrics.spend) * 100;

                                prevPredictedGrossProfit = ((predictedGrossProfit - prevPredictedGrossProfit) / prevPredictedGrossProfit * 100).toFixed(2);
                                prevPredictedROAS = ((predictedRoas - prevPredictedROAS) / prevPredictedROAS * 100).toFixed(2);
                            }   else {
                            }

                            prevDownloads = ((metrics.tracked_installs - prevMetrics.tracked_installs) / prevMetrics.tracked_installs * 100).toFixed(2);
                            prevSpend = ((metrics.spend - prevMetrics.spend) / prevMetrics.spend * 100).toFixed(2);

                        }

                        if (predicted_k !== undefined && predicted_k > 0 && metrics.total_rev_1d > 0 && metrics.spend > 1) {
                            PredictedGrossProfit = ((metrics.total_rev_1d * predicted_k) - metrics.spend);
                            PredictedROAS = ((metrics.total_rev_1d * predicted_k) / metrics.spend) * 100;
                            PredictedROAS = (PredictedROAS).toFixed(2) + "%";

                            PredictedGrossProfit = "$" + (PredictedGrossProfit).toFixed(2);
                            PredictedGrossProfit = PredictedGrossProfit.replace("$-", "-$");
                        }
                        metrics.predicted_k = predicted_k;
                        metrics.predicted_installs = predicted_installs;


                        return (
                            <>
                                <tr key={c}>
                                    {campaign.countries !== undefined ?
                                        <th className="campaign-name">
                                            <a href="#/"
                                               onClick={_this.ShowCountries.bind(this, objectKey)}
                                            >
                                                {campaign.showCounties
                                                    ? <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                                    : ""
                                                }
                                                {campaign.attributes.name}
                                            </a>

                                        </th>
                                        :
                                        <th className="campaign-name">
                                            {campaign.attributes.name}
                                        </th>
                                    }

                                    <th>
                                        <span className="left-value">{getFormated(metrics.tracked_installs)} </span>
                                        {prevDownloads !== "" && !isNaN(prevDownloads) && prevDownloads !== "Infinity" ?
                                            <span
                                                className={prevDownloads >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                                    <span className="prev">{prevDownloads}%</span>
                                                                    <span className="prevValue">{
                                                                        prevMetrics.tracked_installs !== undefined ?
                                                                            getFormated((prevMetrics.tracked_installs).toFixed(2))
                                                                        : 0
                                                                    }</span>
                                             </span>
                                            :
                                            <span className="stat"> </span>
                                        }</th>
                                    <th>-</th>
                                    {metrics.spend > 1 ?
                                        <th>
                                            <span className="left-value">${metrics.spend.toFixed(2)} </span>
                                            {prevSpend !== "" && !isNaN(prevSpend) && prevDownloads !== "Infinity" ?
                                                <span
                                                    className={prevSpend >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                                    <span className="prev">{prevSpend}%</span>
                                                                    <span className="prevValue">${(prevMetrics.spend).toFixed(2)}</span>
                                             </span>
                                                :
                                                <span className="stat"> </span>
                                            }</th>
                                        :
                                        <th>
                                            <span className="left-value">$0.00</span><span className="stat"> </span>
                                        </th>
                                    }
                                    {metrics.total_rev_120d > 1 ?
                                        <th>
                                            ${metrics.total_rev_120d.toFixed(2)}
                                        </th>
                                        :
                                        <th>-</th>
                                    }
                                    {metrics.total_rev_120d > 1 && metrics.spend > 1 ?
                                        <th>
                                            {('$' + ((metrics.total_rev_120d - metrics.spend).toFixed(2))).replace("$-", "-$")}
                                        </th>
                                        :
                                        <th>-</th>
                                    }

                                    <th>
                                        <span className="left-value">
                                        {PredictedGrossProfit !== "" ? PredictedGrossProfit : "-"}
                                        </span>
                                        {prevPredictedGrossProfit !== "" && prevPredictedGrossProfit !== "Infinity" ?
                                            <span
                                                className={prevPredictedGrossProfit >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                <span className="prev">{prevPredictedGrossProfit}%</span>
                                                <span
                                                    className="prevValue">{("$"+((prevMetrics.total_rev_1d * predicted_k) - prevMetrics.spend).toFixed(2)).replace("$-","-$")}</span>
                                            </span>
                                            :
                                            <span className="stat"> </span>
                                        }
                                    </th>
                                    <th>
                                        <span className="left-value">
                                        {PredictedROAS !== "" ? PredictedROAS : "-"}
                                        </span>
                                        {prevPredictedROAS !== "" && prevPredictedROAS !== "Infinity" ?
                                            <span
                                                className={prevPredictedROAS >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                <span className="prev">{prevPredictedROAS}%</span>
                                                <span
                                                    className="prevValue">{(((prevMetrics.total_rev_1d * predicted_k) / prevMetrics.spend) * 100).toFixed(2)}%</span>
                                            </span>
                                            :
                                            <span className="stat"> </span>
                                        }
                                    </th>
                                    <th>-</th>
                                    <th>{
                                        predicted_installs !== undefined ?
                                            predicted_installs > 100 ? <span className="stat stat-good">Good</span>
                                                :
                                                <span className="stat stat-normal">Normal</span>
                                            : "-"
                                    }</th>
                                    <th>{predictedType}</th>
                                    <th>{predicted_k !== undefined && predicted_k > 0 ? predicted_k.toFixed(2) : "-"}</th>
                                    <th>
                                        {predicted_k !== undefined && !_this.props.showDetails ?
                                            <a href="#/"
                                               onClick={_this.props.getDetails.bind(this,  {
                                                   predicted: predicted,
                                                   app_id: _this.props.app_id,
                                                   channel_id: _this.props.channel_id,
                                                   campaign_id: campaign.attributes.campaign_id,
                                                   campaign_name: campaign.attributes.name,
                                               })}
                                            >
                                                History
                                            </a>
                                            : <FontAwesomeIcon icon={faSpinner} spin size="lg"/>
                                        }

                                    </th>
                                </tr>
                                <TableCountries
                                    getDetails={_this.props.getDetails}
                                    top5={top5}
                                    app_id={_this.props.app_id}
                                    channel_id={_this.props.channel_id}
                                    campaign_id={campaign.attributes.campaign_id}
                                    showDetails={_this.props.showDetails}
                                    predicted={predicted}
                                    organicK={organicK}
                                    organic1LTV={organic1LTV}
                                    organicInstalls={organicInstalls}
                                    targetROAS={targetROAS}
                                    campaign={campaign}
                                    sorting={sorting}
                                    showEmptyCountries={_this.props.showEmptyCountries}
                                />
                            </>
                        )
                    })
                }
            </>
        )

    }

}


export default TableCampaigns;
