import React, {Component} from 'react';
import sortingArr, {getFormated} from '../functions/functions'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from '@fortawesome/free-solid-svg-icons'

class TableCountries extends Component {


    render() {
        const {campaign, sorting, targetROAS, top5, organicK,organic1LTV, organicInstalls} = this.props;
        const _this = this;
        let newCountries = [];
        if (campaign.countries !== undefined) {
            let countries = campaign.countries;
            for (let key in countries) {
                if (countries[key] !== undefined) {
                    if (!_this.props.showEmptyCountries && countries[key].tracked_installs <= 0 && countries[key].spend <= 0) {

                    } else {
                        newCountries.push(countries[key]);
                    }
                }

            }
        }


        if (sorting !== "") {
            newCountries = sortingArr(newCountries, sorting, false, targetROAS);
        }
        if (top5) {
            newCountries = newCountries.slice(0, 5);
        }

        return (
            <>
                {
                    campaign.showCounties && Object.keys(newCountries).map(function (objectKey, d) {
                        let country = newCountries[objectKey];
                        let PredictedGrossProfit = "";
                        let PredictedROAS = "";
                        let RecommendCPI = "";
                        let prevDownloads = "";
                        let prevSpend = "";
                        let prevMetrics = null;
                        let predictedType = "Direct";
                        let prevPredictedGrossProfit = "";
                        let prevPredictedROAS = "";
                        let predicted = null;
                        country.predicted_installs = 0;

                        if (country.predicted === undefined || country.predicted.tracked_installs < 100) {
                            predictedType = "Indirect";
                            predicted = _this.props.predicted;
                            if (predicted !== null) {
                                predicted.predictedType = "Indirect";
                            }
                        } else {
                            predicted = country.predicted;
                            country.predicted_installs = country.predicted.tracked_installs;
                        }

                        if (predicted !== null && predicted.tracked_installs > 100) {
                            country.predicted_k = predicted.total_rev_30d / predicted.total_rev_1d;
                        } else {
                            console.log("props: ", _this.props.predicted);
                            country.predicted_k = 2;
                        }


                        if (country.prevMetrics !== undefined) {
                            prevMetrics = country.prevMetrics;
                        }

                        if (country.predicted_k !== undefined && country.predicted_k > 0 && country.total_rev_1d > 0 && country.spend > 1) {
                            PredictedGrossProfit = ((country.total_rev_1d * country.predicted_k) - country.spend);
                            PredictedROAS = ((country.total_rev_1d * country.predicted_k) / country.spend) * 100;
                            if (country.cpi !== null) {
                                RecommendCPI = country.cpi * (PredictedROAS / targetROAS);
                                RecommendCPI = "$" + (RecommendCPI).toFixed(2);
                                RecommendCPI = RecommendCPI.replace("$-", "-$");
                            }
                            PredictedROAS = (PredictedROAS).toFixed(2) + "%";

                            PredictedGrossProfit = "$" + (PredictedGrossProfit).toFixed(2);
                            PredictedGrossProfit = PredictedGrossProfit.replace("$-", "-$");

                        }   else {
                            RecommendCPI = (organic1LTV) * country.predicted_k / organicInstalls;
                            RecommendCPI = "$" + (RecommendCPI).toFixed(2);
                            RecommendCPI = RecommendCPI.replace("$-", "-$");
                            if(predicted !== null) {
                                predicted.predictedType = "Organic Based";
                            }
                            predictedType = "Organic Based";
                        }

                        if(country.tracked_installs !== null && (country.spend === null || country.spend <= 0)) {
                            country.predicted_k = country.predicted_k / organicK;

                            RecommendCPI = (organic1LTV) * country.predicted_k / organicInstalls;


                            RecommendCPI = "$" + (RecommendCPI).toFixed(2);
                            RecommendCPI = RecommendCPI.replace("$-", "-$");
                            if(predicted !== null) {
                                predicted.predictedType = "Organic Based";
                            }
                            predictedType = "Organic Based";
                        }

                        if (prevMetrics !== null) {
                            if (country.predicted_k !== undefined && country.predicted_k > 0 && prevMetrics.total_rev_1d > 0 && country.spend > 1) {
                                let predictedGrossProfit = ((country.total_rev_1d * country.predicted_k) - country.spend);
                                let predictedRoas = ((country.total_rev_1d * country.predicted_k) / country.spend) * 100;
                                prevPredictedGrossProfit = ((prevMetrics.total_rev_1d * country.predicted_k) - prevMetrics.spend);
                                prevPredictedROAS = ((prevMetrics.total_rev_1d * country.predicted_k) / prevMetrics.spend) * 100;

                                prevPredictedGrossProfit = ((predictedGrossProfit - prevPredictedGrossProfit) / prevPredictedGrossProfit * 100).toFixed(2);
                                prevPredictedROAS = ((predictedRoas - prevPredictedROAS) / prevPredictedROAS * 100).toFixed(2);
                            }

                            prevDownloads = ((country.tracked_installs - prevMetrics.tracked_installs) / prevMetrics.tracked_installs * 100).toFixed(2);
                            prevSpend = ((country.spend - prevMetrics.spend) / prevMetrics.spend * 100).toFixed(2);


                        }


                        return (
                            <tr key={d}>
                                <th className="country-name">
                                    {country.country}
                                    &nbsp;
                                    {country.loaded !== undefined && country.loaded && prevDownloads === "" && country.tracked_installs === undefined
                                        ? <FontAwesomeIcon icon={faSpinner} spin size="lg"/>
                                        : ""
                                    }
                                </th>
                                <th>
                                    <span
                                        className="left-value">{country.tracked_installs > 0 ? getFormated(country.tracked_installs) : 0} </span>
                                    {prevDownloads !== "" && !isNaN(prevDownloads) && prevDownloads !== "Infinity" ?
                                        <span
                                            className={prevDownloads >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                                    <span className="prev">{prevDownloads}%</span>
                                                                    <span className="prevValue">{
                                                                        prevMetrics.tracked_installs !== undefined
                                                                            ? getFormated((prevMetrics.tracked_installs).toFixed(2))
                                                                            : 0
                                                                    }</span>
                                             </span>
                                        :
                                        ""
                                    }</th>
                                {country.cpi !== null ?
                                    <th>
                                        ${country.cpi.toFixed(2)}
                                    </th>
                                    :
                                    <th>-</th>
                                }
                                {country.spend > 1 ?
                                    <th>
                                        <span className="left-value">${country.spend.toFixed(2)} </span>
                                        {prevSpend !== "" && !isNaN(prevSpend) && prevSpend !== "Infinity" ?
                                            <span
                                                className={prevSpend >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                                    <span className="prev">{prevSpend}%</span>
                                                                    <span className="prevValue">${
                                                                        prevMetrics.spend !== undefined
                                                                            ? (prevMetrics.spend).toFixed(2)
                                                                            : 0
                                                                    }</span>
                                             </span>
                                            :
                                            ""
                                        }</th>
                                    :
                                    <th>
                                        <span className="left-value">$0.00</span><span className="stat"> </span>
                                    </th>
                                }
                                {country.total_rev_120d > 1 ?
                                    <th>${country.total_rev_120d.toFixed(2)}
                                    </th>
                                    :
                                    <th>-</th>
                                }
                                {country.total_rev_120d > 1 && country.spend > 1 ?
                                    <th>
                                        {('$' + ((country.total_rev_120d - country.spend).toFixed(2))).replace("$-", "-$")}
                                    </th>
                                    :
                                    <th>
                                        -
                                    </th>
                                }
                                <th>
                                        <span className="left-value">
                                        {PredictedGrossProfit !== "" ? PredictedGrossProfit : "-"}
                                        </span>
                                    {prevPredictedGrossProfit !== "" && prevPredictedGrossProfit !== "Infinity" ?
                                        <span
                                            className={prevPredictedGrossProfit >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                <span className="prev">{prevPredictedGrossProfit}%</span>
                                                <span
                                                    className="prevValue">{("$" + ((prevMetrics.total_rev_1d * country.predicted_k) - prevMetrics.spend).toFixed(2)).replace("$-", "-$")}</span>
                                            </span>
                                        :
                                        <span className="stat"> </span>
                                    }
                                </th>
                                <th>
                                        <span className="left-value">
                                        {PredictedROAS !== "" ? PredictedROAS : "-"}
                                        </span>
                                    {prevPredictedROAS !== "" && !isNaN(prevPredictedROAS) && prevPredictedROAS !== "Infinity" ?
                                        <span
                                            className={prevPredictedROAS >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                <span className="prev">{prevPredictedROAS}%</span>
                                                <span
                                                    className="prevValue">{(((prevMetrics.total_rev_1d * country.predicted_k) / prevMetrics.spend) * 100).toFixed(2)}%</span>
                                            </span>
                                        :
                                        <span className="stat"> </span>
                                    }
                                </th>
                                <th>{RecommendCPI !== "" ? RecommendCPI : "-"}</th>
                                <th>{
                                    country.predicted_installs !== undefined ?
                                        country.predicted_installs > 100 ? <span className="stat stat-good">Good</span>
                                            :
                                            <span className="stat stat-normal">Normal</span>
                                        : "-"
                                }</th>
                                <th>{predictedType}</th>
                                <th>{country.predicted_k !== undefined ?
                                    country.predicted_k.toFixed(2)
                                    : "-"}</th>
                                <th>
                                    {country.predicted_k !== undefined && !_this.props.showDetails ?
                                        <a href="#/"
                                           onClick={_this.props.getDetails.bind(this, {
                                               predicted: predicted,
                                               app_id: _this.props.app_id,
                                               channel_id: _this.props.channel_id,
                                               campaign_id: _this.props.campaign_id,
                                               country: country.country,
                                           })}
                                        >
                                            History
                                        </a>
                                        : <FontAwesomeIcon icon={faSpinner} spin size="lg"/>
                                    }
                                </th>

                            </tr>
                        )
                    })
                }
            </>
        )

    }

}


export default TableCountries;
