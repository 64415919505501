import React, {Component} from 'react';
import './table-geos.css';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleDown, faAngleUp, faSpinner, faTimes} from '@fortawesome/free-solid-svg-icons'

import sortingArr, {getFormated} from "../functions/functions";

class TableGeos extends Component {
    state = {
        searchText: "",
    };

    search = (e) => {
        let value = e.target.value;
        this.props.setSearch(value);
        this.setState(() => {
            return {
                searchText: value,
            }
        });
    };

    getCampaign = (id, e) => {
        e.preventDefault();
        console.log(id);
        this.props.getCampaigns(id)
    };


    getDetails = (data, e) => {
        e.preventDefault();
        this.props.getDetails(data)
    };

    getCampaignData = (data, e) => {
        e.preventDefault();
        this.props.setCampaignsData(data.app_id, data.channel_id);
    };

    showCampaignsInCountry = (data, e) => {
        e.preventDefault();
        this.props.showCampaignsInCountry(data.key, data.country);
    };

    ShowCountries = (data, e) => {
        e.preventDefault();
        this.props.changeShowCountries(data.app_id, data.channel_id, data.campaign_id);
    };
    sorting = (data, e) => {
        e.preventDefault();
        this.props.sorting(data);
    };


    render() {
        let {data, sort, targetROAS} = this.props;


        const _this = this;
        let newData = [];
        if (sort !== "") {
            for (let i in data) {
                newData.push(data[i]);
            }
            data = sortingArr(newData, sort);
        } else {
            newData = data;
        }


        return (
            <>
                {newData && newData.length < 1 && this.state.searchText !== "" ?
                    <div className="alert alert-dark" role="alert">
                        По запросу: "{this.state.searchText}" ничего не найдено...
                    </div>
                    : ""
                }
                <div className="info">
                    <a href="#/" onClick={_this.props.showGeos} className="close-details">
                        <FontAwesomeIcon icon={faTimes} size="2x" color="black"/>
                    </a>
                </div>


                <table className="table-geos" data-toggle="table">
                    <tbody>
                    <tr className="first-tr">
                        <th></th>
                        <th colSpan="5">Input data</th>
                        <th colSpan="7">Calculation results</th>
                    </tr>
                    <tr className="second-tr">
                        <th width="512px" align="left">
                            {data.length > 0 || this.state.searchText !== "" ?
                                <input type="text"
                                       placeholder="Search on app name"
                                       onChange={this.search}
                                       value={this.state.searchText}
                                />
                                :
                                ""
                            }
                        </th>
                        <th>
                            <a href="#/" onClick={_this.sorting.bind(this, "downloads")}>
                                Downloads
                            </a>
                            {sort === 'downloads'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'downloads-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }

                        </th>
                        <th>
                            <a href="#/" onClick={_this.sorting.bind(this, "cpi")}>
                                Current CPI
                            </a>
                            {sort === 'cpi'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'cpi-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }
                        </th>
                        <th><a href="#/"
                               onClick={_this.sorting.bind(this, "spend")}
                        >
                            Spend
                        </a>
                            {sort === 'spend'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'spend-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }
                        </th>
                        <th>
                            <a href="#/" onClick={_this.sorting.bind(this, "revenue")}>
                                Revenue (120LTV)
                            </a>
                            {sort === 'revenue'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'revenue-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }
                        </th>
                        <th>
                            <a href="#/" onClick={_this.sorting.bind(this, "gross")}>
                                Gross Profit
                            </a>
                            {sort === 'gross'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'gross-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }
                        </th>
                        <th>Predicted Gross Profit</th>
                        <th>Predicted ROAS</th>
                        <th>Rec. CPI</th>
                        <th>Quality</th>
                        <th>Type</th>
                        <th>k</th>
                        <th>History</th>
                    </tr>
                    {Object.keys(newData).map(function (objectKey, i) {
                        let item = newData[objectKey];
                        let predicted_installs = "";
                        let predicted_k = 0;


                        if (item.predicted !== undefined) {
                            predicted_installs = item.predicted.tracked_installs;
                            let k = item.predicted.total_rev_30d / item.predicted.total_rev_1d;
                            if (isNaN(k)) {
                                k = 0;
                            }
                            predicted_k = k;
                        } else {

                        }


                        let PredictedGrossProfit = "";
                        let prevPredictedGrossProfit = "";
                        let prevPredictedROAS = "";
                        let PredictedROAS = "";
                        let PredictedROASClass = "";
                        let predictedType = "Direct";
                        let predicted = item.predicted !== undefined ? item.predicted : null;
                        let campaign = null;
                        let RecommendCPI = "";

                        if (predicted_installs === undefined || predicted_installs < 100) {
                            predictedType = "Indirect";
                            predicted_k = 2;
                            let key = item.ad_network_id + "" + item.app_id;
                            if (_this.props.campaigns[key] !== undefined && _this.props.campaigns[key][item.campaign_id] !== undefined) {
                                campaign = _this.props.campaigns[key][item.campaign_id];
                                if (campaign.predicted !== undefined && campaign.predicted.tracked_installs !== null && campaign.predicted.tracked_installs > 100) {
                                        predicted = campaign.predicted;
                                        predicted_k = predicted.total_rev_30d / predicted.total_rev_1d;
                                }   else {
                                    let channel = (_this.props.dataChannels[key]);
                                    if (channel.predicted !== undefined && channel.predicted.tracked_installs !== null && channel.predicted.tracked_installs > 100) {
                                        predicted = channel.predicted;
                                        predicted_k = predicted.total_rev_30d / predicted.total_rev_1d;
                                    }   else {
                                        console.log("omg wtf???: ", channel);
                                    }
                                }
                            }
                            if(predicted !== undefined && predicted !== null) {
                                predicted.predictedType = "Indirect";
                            }
                        }

                        if (predicted_k !== undefined && predicted_k > 0 && item.total_rev_1d > 0 && item.spend > 1) {
                            PredictedGrossProfit = ((item.total_rev_1d * predicted_k) - item.spend);
                            PredictedROAS = ((item.total_rev_1d * predicted_k) / item.spend) * 100;
                            PredictedROASClass = PredictedROAS;
                            if(PredictedROAS > 100 && PredictedROAS < targetROAS) {
                                return null;
                            }
                            PredictedROAS = (PredictedROAS).toFixed(2) + "%";

                            if (item.cpi !== null) {
                                RecommendCPI = item.cpi * (PredictedROASClass / targetROAS);
                                RecommendCPI = "$" + (RecommendCPI).toFixed(2);
                                RecommendCPI = RecommendCPI.replace("$-", "-$");
                            }



                            PredictedGrossProfit = "$" + (PredictedGrossProfit).toFixed(2);
                            PredictedGrossProfit = PredictedGrossProfit.replace("$-", "-$");

                        }

                        if (!item.spend > 0) {
                            console.error("Empty spend", item);
                            return null;
                        }

                        let prevDownloads = "";
                        let prevSpend = "";


                        // 100 * (b - a) / a
                        if (item.prevMetrics !== undefined) {
                            if (predicted_k !== undefined && predicted_k > 0 && item.prevMetrics.total_rev_1d > 0 && item.spend > 1) {
                                let predictedGrossProfit = ((item.total_rev_1d * predicted_k) - item.spend);
                                let predictedRoas = ((item.total_rev_1d * predicted_k) / item.spend) * 100;

                                prevPredictedGrossProfit = ((item.prevMetrics.total_rev_1d * predicted_k) - item.prevMetrics.spend);
                                prevPredictedROAS = ((item.prevMetrics.total_rev_1d * predicted_k) / item.prevMetrics.spend) * 100;

                                prevPredictedGrossProfit = ((predictedGrossProfit - prevPredictedGrossProfit) / prevPredictedGrossProfit * 100).toFixed(2);
                                prevPredictedROAS = ((predictedRoas - prevPredictedROAS) / prevPredictedROAS * 100).toFixed(2);
                            }

                            prevDownloads = ((item.tracked_installs - item.prevMetrics.tracked_installs) / item.prevMetrics.tracked_installs * 100).toFixed(2);

                            //TODO delete
                            // console.log("current: ",item.spend);
                            // console.log("prev: ", item.prevMetrics.spend);
                            prevSpend = ((item.spend - item.prevMetrics.spend) / item.prevMetrics.spend * 100).toFixed(2);


                        }


                        return (
                            <>
                                <tr key={i} className={
                                    PredictedROASClass !== "" && PredictedROASClass > targetROAS ?
                                        "good-geo"
                                        : "bad-geo"
                                }>
                                    <th className="app-name" width="512px">
                                        <img srcSet={item.app_icon_url} alt="icon"/>
                                        {item.app_name} ({item.platform})
                                        / {item.ad_network_name} / {item.name} / {item.country}
                                        {/*<img src={item.icon_url} alt="icon"/>*/}
                                    </th>

                                    <th>
                                        <span className="total_installs">{getFormated(item.tracked_installs)}</span>
                                        {prevDownloads !== "" && !isNaN(prevDownloads) && prevDownloads !== "Infinity" ?
                                            <span
                                                className={prevDownloads >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                <span className="prev">{prevDownloads}%</span>
                                                <span className="prevValue">{
                                                    item.prevMetrics.tracked_installs !== undefined && item.prevMetrics.tracked_installs > 0 ?
                                                        getFormated((item.prevMetrics.tracked_installs).toFixed(2))
                                                        : 0
                                                }</span>
                                            </span>
                                            :
                                            <span className="stat"> </span>
                                        }
                                    </th>
                                    {item.cpi !== null ?
                                        <th>
                                            ${item.cpi.toFixed(2)}
                                        </th>
                                        :
                                        <th>-</th>
                                    }
                                    <th>
                                        <span className="left-value">${item.spend.toFixed(2)}</span>
                                        {prevSpend !== "" && !isNaN(prevSpend) && prevSpend !== "Infinity" ?
                                            <span
                                                className={prevSpend >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                <span className="prev">{prevSpend}%</span>
                                                <span
                                                    className="prevValue">${item.prevMetrics.spend !== undefined && item.prevMetrics.spend > 0 ?
                                                    (item.prevMetrics.spend).toFixed(2)
                                                    : 0
                                                }</span>
                                            </span>
                                            :
                                            <span className="stat"> </span>
                                        }
                                    </th>
                                    <th>
                                        {item.total_rev_120d !== null && item.total_rev_120d > 0
                                            ? "$" + item.total_rev_120d.toFixed(2)
                                            : "-"
                                        }
                                    </th>
                                    <th>
                                        {item.total_rev_120d !== null && item.total_rev_120d > 0 && item.spend > 0 ?
                                            ('$' + ((item.total_rev_120d - item.spend).toFixed(2))).replace("$-", "-$")
                                            : "-"
                                        }
                                    </th>
                                    <th>
                                        <span className="left-value">
                                        {PredictedGrossProfit !== "" ? PredictedGrossProfit : "-"}
                                        </span>
                                        {prevPredictedGrossProfit !== "" && prevPredictedGrossProfit !== "Infinity" ?
                                            <span
                                                className={prevPredictedGrossProfit >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                <span className="prev">{prevPredictedGrossProfit}%</span>
                                                <span
                                                    className="prevValue">{("$" + ((item.prevMetrics.total_rev_1d * predicted_k) - item.prevMetrics.spend).toFixed(2)).replace("$-", "-$")}</span>
                                            </span>
                                            :
                                            <span className="stat"> </span>
                                        }
                                    </th>
                                    <th>
                                        <span className="left-value">
                                        {PredictedROAS !== "" ? PredictedROAS : "-"}
                                        </span>
                                        {prevPredictedROAS !== "" && prevPredictedROAS !== "Infinity" ?
                                            <span
                                                className={prevPredictedROAS >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                <span className="prev">{prevPredictedROAS}%</span>
                                                <span
                                                    className="prevValue">{(((item.prevMetrics.total_rev_1d * predicted_k) / item.prevMetrics.spend) * 100).toFixed(2)}%</span>
                                            </span>
                                            :
                                            <span className="stat"> </span>
                                        }
                                    </th>
                                    {/*<th>${(item.cpi * (1 + ( ((item.total_rev_30d-item.spend)/item.spend)- 150) / ((item.total_rev_30d-item.spend)/item.spend))).toFixed(2)}</th>*/}
                                    <th>{RecommendCPI !== "" ? RecommendCPI : "-"}</th>
                                    <th>{
                                        predicted_installs !== undefined ?
                                            predicted_installs > 100 ? <span className="stat stat-good">Good</span>
                                                :
                                                <span className="stat stat-normal">Normal</span>
                                            : "-"
                                    }</th>
                                    <th>{predictedType}</th>
                                    <th>{predicted_k !== undefined && predicted_k > 0 ? (predicted_k).toFixed(2) : "-"}</th>
                                    <th>
                                        {predicted_k !== undefined && !_this.props.showDetails ?
                                            <a href="#/"
                                               onClick={_this.props.getDetails.bind(this, {
                                                   predicted: predicted,
                                                   app_id: item.app_id,
                                                   channel_id: item.ad_network_id,
                                                   campaign_id: item.campaign_id,
                                                   country: item.country,
                                               })}
                                            >
                                                History
                                            </a>
                                            : <FontAwesomeIcon icon={faSpinner} spin size="lg"/>
                                        }

                                    </th>
                                </tr>
                            </>
                        )
                    })}
                    </tbody>
                </table>
            </>
        );
    }
}


export default TableGeos;
