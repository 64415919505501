import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleDown, faAngleUp, faTimes} from '@fortawesome/free-solid-svg-icons'

import './details.css';
import {sortingDetails} from "../functions/functions";
import moment from "moment";
import Charting from '../charting';
import SwitchSelector from "react-switch-selector";

class Details extends Component {
    state = {
        sortDate: "",
    };


    sortDate = () => {
        let newSort = "";
        if (this.state.sortDate === "up") {
            newSort = "down";
        } else if (this.state.sortDate === "down") {
            newSort = "up";
        } else {
            newSort = "down";
        }
        this.setState(() => {
            return {
                sortDate: newSort,
            }
        });
    };


    sorting = (data, e) => {
        e.preventDefault();
        this.props.sorting(data);
        this.setState(() => {
            return {
                sortDate: "",
            }
        });
    };

    componentDidMount() {
        this.setState(() => {
            return {
                sortDate: "up",
            }
        });
    }




    render() {
        let {detailsData, targetROAS, sort} = this.props;
        let newDate = [];
        let originDate = [];
        const _this = this;
        let predictedK = 0;

        console.log(_this.props.history);

        const options = [
            {
                label: "Daily",
                value: 1,
                selectedBackgroundColor: "#6B7FE3",
            },
            {
                label: "Weekly",
                value: 2,
                selectedBackgroundColor: "#6B7FE3",
            }
        ];

        const onChange = (newValue) => {
            this.props.setHistory(newValue, true);
        };

        if (detailsData !== undefined) {

            for (let key in detailsData) {
                if (detailsData[key] !== undefined && detailsData[key].attributes.tracked_installs !== undefined) {
                    newDate.push(detailsData[key].attributes);
                    originDate.push(detailsData[key].attributes);
                }
            }
        }


        if (sort !== "" && this.state.sortDate === "") {
            newDate = sortingDetails(newDate, sort, targetROAS);
        } else if (this.state.sortDate === "up") {
            newDate = newDate.reverse()
        }


        return (
            <>
                <div className="info">
                    {newDate[0] !== undefined ?
                        <h3>
                            <span>{newDate[0].app_name !== undefined ? newDate[0].app_name : ""}</span>
                            <span>({newDate[0].platform}) / </span>
                            <span>{newDate[0].ad_network_name !== undefined ? newDate[0].ad_network_name + " / " : ""}</span>
                            <span>{newDate[0].name !== undefined && (newDate[0].name !== newDate[0].app_name && newDate[0].name !== newDate[0].ad_network_name) ? newDate[0].name + " / " : ""}</span>
                            <span>{newDate[0].country !== undefined ? newDate[0].country : ""}</span>


                            <span>{newDate[0].campaign_name !== undefined ? newDate[0].campaign_name + " / " : ""}</span>
                        </h3>
                        : ""}
                    <a href="#/" onClick={_this.props.showDetails} className="close-details">
                        <FontAwesomeIcon icon={faTimes} size="4x" color="black"/>
                    </a>
                    <div className="your-required-wrapper" style={{width: 150, height: 31}}>
                        <SwitchSelector
                            onChange={onChange}
                            options={options}
                            backgroundColor={"#353b48"}
                            fontColor={"#f5f6fa"}
                            initialSelectedIndex={_this.props.history-1}
                            forcedSelectedIndex={_this.props.history-1}
                        />
                    </div>
                </div>

                <table className="table-list" data-toggle="table">
                    <tbody>

                    <tr className="first-tr">
                        <th></th>
                        <th colSpan="5">Input data</th>
                        <th colSpan="6">Calculation results</th>
                    </tr>
                    <tr className="second-tr">
                        <th width="512px" align="left">
                            <a href="#/" onClick={_this.sortDate}>
                                Date
                            </a>
                            {_this.state.sortDate === 'down'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : _this.state.sortDate === 'up' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }

                        </th>
                        <th>
                            <a href="#/" onClick={_this.sorting.bind(this, "downloads")}>
                                Downloads
                            </a>
                            {sort === 'downloads'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'downloads-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }

                        </th>
                        <th>
                            <a href="#/" onClick={_this.sorting.bind(this, "cpi")}>
                                Current CPI
                            </a>
                            {sort === 'cpi'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'cpi-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }
                        </th>
                        <th><a href="#/"
                               onClick={_this.sorting.bind(this, "spend")}
                        >
                            Spend
                        </a>
                            {sort === 'spend'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'spend-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }
                        </th>
                        <th>
                            <a href="#/" onClick={_this.sorting.bind(this, "revenue")}>
                                Revenue (120LTV)
                            </a>
                            {sort === 'revenue'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'revenue-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }
                        </th>
                        <th>
                            <a href="#/" onClick={_this.sorting.bind(this, "gross")}>
                                Gross Profit
                            </a>
                            {sort === 'gross'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'gross-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }
                        </th>
                        <th>
                            <a href="#/" onClick={_this.sorting.bind(this, "predgross")}>
                                Predicted Gross Profit
                            </a>
                            {sort === 'predgross'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'predgross-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }
                            </th>
                        <th>
                            <a href="#/" onClick={_this.sorting.bind(this, "roas")}>
                                Predicted ROAS
                            </a>
                            {sort === 'roas'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'roas-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }
                            </th>
                        <th>
                            <a href="#/" onClick={_this.sorting.bind(this, "recomcpi")}>
                                Rec. CPI
                            </a>
                            {sort === 'recomcpi'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'recomcpi-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }
                            </th>
                        <th>Quality</th>
                        <th> Type</th>
                        <th>
                            <a href="#/" onClick={_this.sorting.bind(this, "pred_k")}>
                                k
                            </a>
                            {sort === 'pred_k'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'pred_k-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }
                            </th>
                        <th>N-day</th>
                    </tr>
                    {
                        Object.keys(newDate).map(function (objectKey, d) {
                            let detail = newDate[objectKey];
                            let PredictedGrossProfit = "";
                            let PredictedROAS = "";
                            let RecommendCPI = "";
                            let prevDownloads = "";
                            let prevSpend = "";
                            let detailsK = _this.props.detailsK;
                            let predicted_k;

                            let prevdetail = null;
                            let predictedType = "Direct";
                            let prevPredictedGrossProfit = "";
                            let prevPredictedROAS = "";
                            let nday = 1;


                            if(detailsK != null && detailsK !== undefined) {
                                if (detailsK.predictedType !== undefined) {
                                    predictedType = detailsK.predictedType;
                                }
                            }

                            if (detail.total_rev_1d > 0
                                && detail.spend > 0) {

                                if(detailsK === undefined || detailsK.tracked_installs === undefined  || detailsK.tracked_installs < 100) {
                                    predicted_k = 2;
                                    PredictedGrossProfit = ((detail.total_rev_1d * predicted_k) - detail.spend);
                                    PredictedROAS = ((detail.total_rev_1d * predicted_k) / detail.spend) * 100;
                                }   else {
                                    predicted_k = detailsK.total_rev_30d / detailsK.total_rev_1d;
                                    const offset = moment().diff(moment(detail.date), 'days');
                                    if (offset > 14) {
                                        predicted_k = detailsK.total_rev_30d / detailsK.total_rev_14d;
                                        PredictedGrossProfit = ((detail.total_rev_14d * predicted_k) - detail.spend);
                                        PredictedROAS = ((detail.total_rev_14d * predicted_k) / detail.spend) * 100;
                                        nday = 14;
                                    } else if (offset > 7) {
                                        predicted_k = detailsK.total_rev_30d / detailsK.total_rev_7d;
                                        PredictedGrossProfit = ((detail.total_rev_7d * predicted_k) - detail.spend);
                                        PredictedROAS = ((detail.total_rev_7d * predicted_k) / detail.spend) * 100;
                                        nday = 7;
                                    } else if (offset > 3) {
                                        predicted_k = detailsK.total_rev_30d / detailsK.total_rev_3d;
                                        PredictedGrossProfit = ((detail.total_rev_3d * predicted_k) - detail.spend);
                                        PredictedROAS = ((detail.total_rev_3d * predicted_k) / detail.spend) * 100;
                                        nday = 3;
                                    } else {
                                        PredictedGrossProfit = ((detail.total_rev_1d * predicted_k) - detail.spend);
                                        PredictedROAS = ((detail.total_rev_1d * predicted_k) / detail.spend) * 100;
                                    }
                                }


                                detail.predicted_k = predicted_k;
                                detail.PredictedGrossProfit = PredictedGrossProfit;
                                detail.PredictedROAS = PredictedROAS;


                                if (detail.cpi !== null) {
                                    RecommendCPI = detail.cpi * (PredictedROAS/targetROAS);
                                    detail.RecommendCPI = detail.cpi * (PredictedROAS/targetROAS);
                                    RecommendCPI = "$" + (RecommendCPI).toFixed(2);
                                    RecommendCPI = RecommendCPI.replace("$-", "-$");
                                }
                                PredictedROAS = (PredictedROAS).toFixed(2) + "%";

                                PredictedGrossProfit = "$" + (PredictedGrossProfit).toFixed(2);
                                PredictedGrossProfit = PredictedGrossProfit.replace("$-", "-$");
                            }   else {
                                console.clear();
                                console.log(detailsK);
                                console.log(detail);

                                predictedType = "No data available"
                                detail.predicted_k = 0;
                                detail.PredictedGrossProfit = 0;
                                detail.PredictedROAS = 0;
                                detail.RecommendCPI = 0;
                            }
                            predictedK = predicted_k;



                            return (
                                <>
                                    <tr key={d}>
                                        <th className="detail-name">
                                            {detail.date}
                                        </th>
                                        <th>
                                        <span
                                            className="left-value">{detail.tracked_installs > 0 ? detail.tracked_installs : 0} </span>
                                            {prevDownloads !== "" && !isNaN(prevDownloads) && prevDownloads !== "Infinity" ?
                                                <span
                                                    className={prevDownloads >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                                    <span className="prev">{prevDownloads}%</span>
                                                                    <span
                                                                        className="prevValue">{prevdetail.tracked_installs}</span>
                                             </span>
                                                :
                                                <span className="stat"> </span>
                                            }</th>
                                        {detail !== undefined && detail.cpi !== null ?
                                            <th>
                                        <span className="left-value">
                                            ${detail.cpi.toFixed(2)}
                                        </span>
                                            </th>
                                            :
                                            <th>-</th>
                                        }
                                        {detail.spend > 1 ?
                                            <th>
                                                <span className="left-value">${detail.spend.toFixed(2)} </span>
                                                {prevSpend !== "" && !isNaN(prevSpend) && prevSpend !== "Infinity" ?
                                                    <span
                                                        className={prevSpend >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                                    <span className="prev">{prevSpend}%</span>
                                                                    <span
                                                                        className="prevValue">${prevdetail.spend}</span>
                                                </span>
                                                    :
                                                    <span className="stat"> </span>
                                                }</th>
                                            :
                                            <th>
                                                <span className="left-value">$0.00</span><span className="stat"> </span>
                                            </th>
                                        }
                                        {detail.total_rev_120d > 1 ?
                                            <th>${detail.total_rev_120d.toFixed(2)}
                                            </th>
                                            :
                                            <th>-</th>
                                        }
                                        {detail.total_rev_120d > 1 && detail.spend > 1 ?
                                            <th>
                                                {('$' + ((detail.total_rev_120d - detail.spend).toFixed(2))).replace("$-", "-$")}
                                            </th>
                                            :
                                            <th>-</th>
                                        }
                                        <th>
                                        <span className="left-value">
                                        {PredictedGrossProfit !== "" ? PredictedGrossProfit : "-"}
                                        </span>
                                            {prevPredictedGrossProfit !== "" && prevPredictedGrossProfit !== "Infinity" ?
                                                <span
                                                    className={prevPredictedGrossProfit >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                <span className="prev">{prevPredictedGrossProfit}%</span>
                                                <span
                                                    className="prevValue">{("$" + ((prevdetail.total_rev_1d * predicted_k) - prevdetail.spend).toFixed(2)).replace("$-", "-$")}</span>
                                            </span>
                                                :
                                                <span className="stat"> </span>
                                            }
                                        </th>
                                        <th>
                                        <span className="left-value">
                                        {PredictedROAS !== "" ? PredictedROAS : "-"}
                                        </span>
                                            {prevPredictedROAS !== "" && prevPredictedROAS !== "Infinity" ?
                                                <span
                                                    className={prevPredictedROAS >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                <span className="prev">{prevPredictedROAS}%</span>
                                                <span
                                                    className="prevValue">{(((prevdetail.total_rev_1d * predicted_k) / prevdetail.spend) * 100).toFixed(2)}%</span>
                                            </span>
                                                :
                                                <span className="stat"> </span>
                                            }
                                        </th>
                                        <th>{RecommendCPI !== "" ? RecommendCPI : "-"}</th>
                                        <th>{
                                            detail.predicted_installs !== undefined ?
                                                detail.predicted_installs > 100 ?
                                                    <span className="stat stat-good">Good</span>
                                                    :
                                                    <span className="stat stat-normal">Normal</span>
                                                : "-"
                                        }</th>
                                        <th>{predictedType}</th>
                                        <th>
                                            {
                                                predicted_k !== undefined && predicted_k > 0 ?
                                                    predicted_k.toFixed(2)
                                                    : "-"
                                            }
                                        </th>
                                        <th>N-day: {nday}</th>
                                    </tr>

                                </>
                            )
                        })
                    }
                    </tbody>
                </table>
                <Charting data={originDate} detailsK={_this.props.detailsK} predictedK={predictedK} />
            </>
        )

    }
}


export default Details;
