const sortingArr = (arr, value, metrics = false, targetROAS = 150) => {
    if (value !== "") {
        let newArr = arr;
        if (metrics) {
            if (metrics) {
                switch (value) {
                    case "downloads":
                        newArr = arr.sort((a, b) => (a.metrics.tracked_installs > b.metrics.tracked_installs) ? 1 : ((b.metrics.tracked_installs > a.metrics.tracked_installs) ? -1 : 0));
                        break;
                    case "downloads-top":
                        newArr = arr.sort((a, b) => (a.metrics.tracked_installs < b.metrics.tracked_installs) ? 1 : ((b.metrics.tracked_installs < a.metrics.tracked_installs) ? -1 : 0));
                        break;
                    case "spend":
                        newArr = arr.sort((a, b) => (a.metrics.spend > b.metrics.spend) ? 1 : ((b.metrics.spend > a.metrics.spend) ? -1 : 0));
                        break;
                    case "spend-top":
                        newArr = arr.sort((a, b) => (a.metrics.spend < b.metrics.spend) ? 1 : ((b.metrics.spend < a.metrics.spend) ? -1 : 0));
                        break;
                    case "revenue":
                        newArr = arr.sort((a, b) => (a.metrics.total_rev_120d > b.metrics.total_rev_120d) ? 1 : ((b.metrics.total_rev_120d > a.metrics.total_rev_120d) ? -1 : 0));
                        break;
                    case "revenue-top":
                        newArr = arr.sort((a, b) => (a.metrics.total_rev_120d < b.metrics.total_rev_120d) ? 1 : ((b.metrics.total_rev_120d < a.metrics.total_rev_120d) ? -1 : 0));
                        break;

                    case "gross":
                        newArr = arr.sort((a, b) => {
                            let grossA = a.metrics.total_rev_120d - a.metrics.spend;
                            let grossB = b.metrics.total_rev_120d - b.metrics.spend;
                            if (grossA > grossB) {
                                return 1;
                            } else if (grossB > grossA) {
                                return -1;
                            } else {
                                return 0;
                            }
                        });
                        break;
                    case "gross-top":
                        newArr = arr.sort((a, b) => {
                            let grossA = a.metrics.total_rev_120d - a.metrics.spend;
                            let grossB = b.metrics.total_rev_120d - b.metrics.spend;
                            if (grossA < grossB) {
                                return 1;
                            } else if (grossB < grossA) {
                                return -1;
                            } else {
                                return 0;
                            }
                        });
                        break;
                    case "predgross":
                        newArr = arr.sort((a, b) => {
                            if (a.metrics.total_rev_1d !== null && a.metrics.predicted_k !== undefined && a.metrics.spend !== null
                                && b.metrics.total_rev_1d !== null && b.metrics.predicted_k !== undefined && b.metrics.spend !== null) {
                                let grossA = ((a.metrics.total_rev_1d * a.metrics.predicted_k) - a.metrics.spend);
                                let grossB = ((b.metrics.total_rev_1d * b.metrics.predicted_k) - b.metrics.spend);
                                if (grossA > grossB) {
                                    return 1;
                                } else if (grossB > grossA) {
                                    return -1;
                                } else {
                                    return 0;
                                }
                            } else {
                                return false;
                            }

                        });
                        break;
                    case "predgross-top":
                        newArr = arr.sort((a, b) => {
                            if (a.metrics.total_rev_1d !== null && a.metrics.predicted_k !== undefined && a.metrics.spend !== null
                                && b.metrics.total_rev_1d !== null && b.metrics.predicted_k !== undefined && b.metrics.spend !== null) {
                                let grossA = ((a.metrics.total_rev_1d * a.metrics.predicted_k) - a.metrics.spend);
                                let grossB = ((b.metrics.total_rev_1d * b.metrics.predicted_k) - b.metrics.spend);
                                if (grossA < grossB) {
                                    return 1;
                                } else if (grossB < grossA) {
                                    return -1;
                                } else {
                                    return 0;
                                }
                            } else {
                                return false;
                            }
                        });
                        break;


                    case "recomcpi":
                        newArr = arr.sort((a, b) => {
                            if (a.metrics.total_rev_1d !== null && a.metrics.predicted_k !== undefined && a.metrics.spend !== null
                                && b.metrics.total_rev_1d !== null && b.metrics.predicted_k !== undefined && b.metrics.spend !== null
                                && a.metrics.cpi !== null && b.metrics.cpi !== null) {
                                let grossA = ((a.metrics.total_rev_1d * a.metrics.predicted_k) - a.metrics.spend);
                                let grossB = ((b.metrics.total_rev_1d * b.metrics.predicted_k) - b.metrics.spend);
                                let RecommendCPIA = a.metrics.cpi * (1 + (grossA - targetROAS) / grossA);
                                let RecommendCPIB = b.metrics.cpi * (1 + (grossB - targetROAS) / grossB);
                                if (RecommendCPIA > RecommendCPIB) {
                                    return 1;
                                } else if (RecommendCPIB > RecommendCPIA) {
                                    return -1;
                                } else {
                                    return 0;
                                }
                            } else {
                                return false;
                            }

                        });
                        break;
                    case "recomcpi-top":
                        newArr = arr.sort((a, b) => {
                            if (a.metrics.total_rev_1d !== null && a.metrics.predicted_k !== undefined && a.metrics.spend !== null
                                && b.metrics.total_rev_1d !== null && b.metrics.predicted_k !== undefined && b.metrics.spend !== null
                                && a.metrics.cpi !== null && b.metrics.cpi !== null) {
                                let grossA = ((a.metrics.total_rev_1d * a.metrics.predicted_k) - a.metrics.spend);
                                let grossB = ((b.metrics.total_rev_1d * b.metrics.predicted_k) - b.metrics.spend);
                                let RecommendCPIA = a.metrics.cpi * (1 + (grossA - targetROAS) / grossA);
                                let RecommendCPIB = b.metrics.cpi * (1 + (grossB - targetROAS) / grossB);
                                if (RecommendCPIA < RecommendCPIB) {
                                    return 1;
                                } else if (RecommendCPIB < RecommendCPIA) {
                                    return -1;
                                } else {
                                    return 0;
                                }
                            } else {
                                return false;
                            }
                        });
                        break;


                    case "pred_k":
                        newArr = arr.sort((a, b) => {
                            if (a.metrics.predicted_k !== undefined && b.metrics.predicted_k !== undefined) {
                                if (a.metrics.predicted_k > b.metrics.predicted_k) {
                                    return 1;
                                } else if (b.metrics.predicted_k > a.metrics.predicted_k) {
                                    return -1;
                                } else {
                                    return 0;
                                }
                            } else {
                                return false;
                            }
                        });
                        break;
                    case "pred_k-top":
                        newArr = arr.sort((a, b) => {
                            if (a.metrics.predicted_k !== undefined && b.metrics.predicted_k !== undefined) {
                                if (a.metrics.predicted_k < b.metrics.predicted_k) {
                                    return 1;
                                } else if (b.metrics.predicted_k < a.metrics.predicted_k) {
                                    return -1;
                                } else {
                                    return 0;
                                }
                            } else {
                                return false;
                            }
                        });
                        break;


                    case "type":
                        newArr = arr.sort((a, b) => {
                            let typeA = (a.metrics.predicted_installs !== undefined && a.metrics.predicted_installs > 100) ? 1 : 0;
                            let typeB = (b.metrics.predicted_installs !== undefined && b.metrics.predicted_installs > 100) ? 1 : 0;

                            if (typeA > typeB) {
                                return 1;
                            } else if (typeB > typeA) {
                                return -1;
                            } else {
                                return 0;
                            }
                        });
                        break;
                    case "type-top":
                        newArr = arr.sort((a, b) => {
                            let typeA = (a.metrics.predicted_installs !== undefined && a.metrics.predicted_installs > 100) ? 1 : 0;
                            let typeB = (b.metrics.predicted_installs !== undefined && b.metrics.predicted_installs > 100) ? 1 : 0;

                            if (typeA < typeB) {
                                return 1;
                            } else if (typeB < typeA) {
                                return -1;
                            } else {
                                return 0;
                            }
                        });
                        break;


                    case "quality":
                        newArr = arr.sort((a, b) => {
                            let typeA = (a.metrics.predicted_installs !== undefined && a.metrics.predicted_installs > 100) ? 1 : 0;
                            let typeB = (b.metrics.predicted_installs !== undefined && b.metrics.predicted_installs > 100) ? 1 : 0;

                            if (typeA > typeB) {
                                return 1;
                            } else if (typeB > typeA) {
                                return -1;
                            } else {
                                return 0;
                            }
                        });
                        break;
                    case "quality-top":
                        newArr = arr.sort((a, b) => {
                            let typeA = (a.metrics.predicted_installs !== undefined && a.metrics.predicted_installs > 100) ? 1 : 0;
                            let typeB = (b.metrics.predicted_installs !== undefined && b.metrics.predicted_installs > 100) ? 1 : 0;

                            if (typeA < typeB) {
                                return 1;
                            } else if (typeB < typeA) {
                                return -1;
                            } else {
                                return 0;
                            }
                        });
                        break;

                    case "roas":
                        newArr = arr.sort((a, b) => {
                            if (a.metrics.total_rev_1d !== null && a.metrics.predicted_k !== undefined && a.metrics.spend !== null
                                && b.metrics.total_rev_1d !== null && b.metrics.predicted_k !== undefined && b.metrics.spend !== null) {
                                let grossA = ((a.metrics.total_rev_1d * a.metrics.predicted_k) / a.metrics.spend) * 100;
                                let grossB = ((b.metrics.total_rev_1d * b.metrics.predicted_k) / b.metrics.spend) * 100;
                                if (grossA > grossB) {
                                    return 1;
                                } else if (grossB > grossA) {
                                    return -1;
                                } else {
                                    return 0;
                                }
                            } else {
                                return false;
                            }

                        });
                        break;
                    case "roas-top":
                        newArr = arr.sort((a, b) => {
                            if (a.metrics.total_rev_1d !== null && a.metrics.predicted_k !== undefined && a.metrics.spend !== null
                                && b.metrics.total_rev_1d !== null && b.metrics.predicted_k !== undefined && b.metrics.spend !== null) {
                                let grossA = ((a.metrics.total_rev_1d * a.metrics.predicted_k) / a.metrics.spend) * 100;
                                let grossB = ((b.metrics.total_rev_1d * b.metrics.predicted_k) / b.metrics.spend) * 100;
                                if (grossA < grossB) {
                                    return 1;
                                } else if (grossB < grossA) {
                                    return -1;
                                } else {
                                    return 0;
                                }
                            } else {
                                return false;
                            }
                        });
                        break;

                    case "cpi":
                        newArr = arr.sort((a, b) => {
                            if (a !== null && b !== null
                                && a.metrics.cpi !== undefined && b.metrics.cpi !== undefined) {
                                if (a.metrics.cpi > b.metrics.cpi) {
                                    return 1
                                } else if (b.metrics.cpi > a.metrics.cpi) {
                                    return -1
                                } else {
                                    return 0
                                }
                            } else {
                                return false;
                            }
                        });
                        break;
                    default:
                        break;
                }
            }
        } else {
            switch (value) {
                case "downloads":
                    newArr = arr.sort((a, b) => (a.tracked_installs > b.tracked_installs) ? 1 : ((b.tracked_installs > a.tracked_installs) ? -1 : 0));
                    break;
                case "downloads-top":
                    newArr = arr.sort((a, b) => (a.tracked_installs < b.tracked_installs) ? 1 : ((b.tracked_installs < a.tracked_installs) ? -1 : 0));
                    break;
                case "spend":
                    newArr = arr.sort((a, b) => (a.spend > b.spend) ? 1 : ((b.spend > a.spend) ? -1 : 0));
                    break;
                case "spend-top":
                    newArr = arr.sort((a, b) => (a.spend < b.spend) ? 1 : ((b.spend < a.spend) ? -1 : 0));
                    break;
                case "revenue":
                    newArr = arr.sort((a, b) => (a.total_rev_120d > b.total_rev_120d) ? 1 : ((b.total_rev_120d > a.total_rev_120d) ? -1 : 0));
                    break;
                case "revenue-top":
                    newArr = arr.sort((a, b) => (a.total_rev_120d < b.total_rev_120d) ? 1 : ((b.total_rev_120d < a.total_rev_120d) ? -1 : 0));
                    break;

                case "gross":
                    newArr = arr.sort((a, b) => {
                        let grossA = a.total_rev_120d - a.spend;
                        let grossB = b.total_rev_120d - b.spend;
                        if (grossA > grossB) {
                            return 1;
                        } else if (grossB > grossA) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                    break;
                case "gross-top":
                    newArr = arr.sort((a, b) => {
                        let grossA = a.total_rev_120d - a.spend;
                        let grossB = b.total_rev_120d - b.spend;
                        if (grossA < grossB) {
                            return 1;
                        } else if (grossB < grossA) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                    break;

                case "predgross":
                    newArr = arr.sort((a, b) => {

                        if (a.total_rev_1d !== null && a.predicted_k !== undefined && a.spend !== null
                            && b.total_rev_1d !== null && b.predicted_k !== undefined && b.spend !== null) {
                            console.log(a.predicted_k)
                            console.log(b.predicted_k)
                            let grossA = ((a.total_rev_1d * a.predicted_k) - a.spend);
                            let grossB = ((b.total_rev_1d * b.predicted_k) - b.spend);
                            if (grossA > grossB) {
                                return 1;
                            } else if (grossB > grossA) {
                                return -1;
                            } else {
                                return 0;
                            }
                        } else {
                            return false;
                        }

                    });
                    break;
                case "predgross-top":
                    newArr = arr.sort((a, b) => {
                        if (a.total_rev_1d !== null && a.predicted_k !== undefined && a.spend !== null
                            && b.total_rev_1d !== null && b.predicted_k !== undefined && b.spend !== null) {
                            let grossA = ((a.total_rev_1d * a.predicted_k) - a.spend);
                            let grossB = ((b.total_rev_1d * b.predicted_k) - b.spend);
                            if (grossA < grossB) {
                                return 1;
                            } else if (grossB < grossA) {
                                return -1;
                            } else {
                                return 0;
                            }
                        } else {
                            return false;
                        }
                    });
                    break;


                case "recomcpi":
                    newArr = arr.sort((a, b) => {
                        if (a.total_rev_1d !== null && a.predicted_k !== undefined && a.spend !== null
                            && b.total_rev_1d !== null && b.predicted_k !== undefined && b.spend !== null
                            && a.cpi !== null && b.cpi !== null) {
                            let grossA = ((a.total_rev_1d * a.predicted_k) - a.spend);
                            let grossB = ((b.total_rev_1d * b.predicted_k) - b.spend);
                            let RecommendCPIA = a.cpi * (1 + (grossA - targetROAS) / grossA);
                            let RecommendCPIB = b.cpi * (1 + (grossB - targetROAS) / grossB);
                            if (RecommendCPIA > RecommendCPIB) {
                                return 1;
                            } else if (RecommendCPIB > RecommendCPIA) {
                                return -1;
                            } else {
                                return 0;
                            }
                        } else {
                            return false;
                        }

                    });
                    break;
                case "recomcpi-top":
                    newArr = arr.sort((a, b) => {
                        if (a.total_rev_1d !== null && a.predicted_k !== undefined && a.spend !== null
                            && b.total_rev_1d !== null && b.predicted_k !== undefined && b.spend !== null
                            && a.cpi !== null && b.cpi !== null) {
                            let grossA = ((a.total_rev_1d * a.predicted_k) - a.spend);
                            let grossB = ((b.total_rev_1d * b.predicted_k) - b.spend);
                            let RecommendCPIA = a.cpi * (1 + (grossA - targetROAS) / grossA);
                            let RecommendCPIB = b.cpi * (1 + (grossB - targetROAS) / grossB);
                            if (RecommendCPIA < RecommendCPIB) {
                                return 1;
                            } else if (RecommendCPIB < RecommendCPIA) {
                                return -1;
                            } else {
                                return 0;
                            }
                        } else {
                            return false;
                        }
                    });
                    break;


                case "pred_k":
                    newArr = arr.sort((a, b) => {
                        if (a.predicted_k !== undefined && b.predicted_k !== undefined) {
                            if (a.predicted_k > b.predicted_k) {
                                return 1;
                            } else if (b.predicted_k > a.predicted_k) {
                                return -1;
                            } else {
                                return 0;
                            }
                        } else {
                            return false;
                        }
                    });
                    break;
                case "pred_k-top":
                    newArr = arr.sort((a, b) => {
                        if (a.predicted_k !== undefined && b.predicted_k !== undefined) {
                            if (a.predicted_k < b.predicted_k) {
                                return 1;
                            } else if (b.predicted_k < a.predicted_k) {
                                return -1;
                            } else {
                                return 0;
                            }
                        } else {
                            return false;
                        }
                    });
                    break;


                case "type":
                    newArr = arr.sort((a, b) => {
                        let typeA = (a.predicted_installs !== undefined && a.predicted_installs > 100) ? 1 : 0;
                        let typeB = (b.predicted_installs !== undefined && b.predicted_installs > 100) ? 1 : 0;

                        if (typeA > typeB) {
                            return 1;
                        } else if (typeB > typeA) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                    break;
                case "type-top":
                    newArr = arr.sort((a, b) => {
                        let typeA = (a.predicted_installs !== undefined && a.predicted_installs > 100) ? 1 : 0;
                        let typeB = (b.predicted_installs !== undefined && b.predicted_installs > 100) ? 1 : 0;

                        if (typeA < typeB) {
                            return 1;
                        } else if (typeB < typeA) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                    break;


                case "quality":
                    newArr = arr.sort((a, b) => {
                        let typeA = (a.predicted_installs !== undefined && a.predicted_installs > 100) ? 1 : 0;
                        let typeB = (b.predicted_installs !== undefined && b.predicted_installs > 100) ? 1 : 0;

                        if (typeA > typeB) {
                            return 1;
                        } else if (typeB > typeA) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                    break;
                case "quality-top":
                    newArr = arr.sort((a, b) => {
                        let typeA = (a.predicted_installs !== undefined && a.predicted_installs > 100) ? 1 : 0;
                        let typeB = (b.predicted_installs !== undefined && b.predicted_installs > 100) ? 1 : 0;

                        if (typeA < typeB) {
                            return 1;
                        } else if (typeB < typeA) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                    break;

                case "roas":
                    newArr = arr.sort((a, b) => {
                        if (a.total_rev_1d !== null && a.predicted_k !== undefined && a.spend !== null
                            && b.total_rev_1d !== null && b.predicted_k !== undefined && b.spend !== null) {
                            let grossA = ((a.total_rev_1d * a.predicted_k) / a.spend) * 100;
                            let grossB = ((b.total_rev_1d * b.predicted_k) / b.spend) * 100;
                            if (grossA > grossB) {
                                return 1;
                            } else if (grossB > grossA) {
                                return -1;
                            } else {
                                return 0;
                            }
                        } else {
                            return false;
                        }

                    });
                    break;
                case "roas-top":
                    newArr = arr.sort((a, b) => {
                        if (a.total_rev_1d !== null && a.predicted_k !== undefined && a.spend !== null
                            && b.total_rev_1d !== null && b.predicted_k !== undefined && b.spend !== null) {
                            let grossA = ((a.total_rev_1d * a.predicted_k) / a.spend) * 100;
                            let grossB = ((b.total_rev_1d * b.predicted_k) / b.spend) * 100;
                            if (grossA < grossB) {
                                return 1;
                            } else if (grossB < grossA) {
                                return -1;
                            } else {
                                return 0;
                            }
                        } else {
                            return false;
                        }
                    });
                    break;

                case "cpi":
                    newArr = arr.sort((a, b) => {
                        if (a !== null && b !== null
                            && a.cpi !== undefined && b.cpi !== undefined) {
                            if (a.cpi > b.cpi) {
                                return 1
                            } else if (b.cpi > a.cpi) {
                                return -1
                            } else {
                                return 0
                            }
                        } else {
                            return false;
                        }
                    });
                    break;
                case "cpi-top":
                    newArr = arr.sort((a, b) => {
                        if (a !== undefined && a !== null
                            && b !== undefined && b !== null
                            && a.cpi !== undefined && b.cpi !== undefined) {
                            if (a.cpi < b.cpi) {
                                return 1
                            } else if (b.cpi < a.cpi) {
                                return -1
                            } else {
                                return 0
                            }
                        } else {
                            return false;
                        }
                    });
                    break;
                default:
                    break;
            }
        }

        return newArr;
    } else {
        return arr;
    }
};

export const getFormated = (value) => {
    if(isNaN(value)) {
        return 0;
    }
    let newVal = new Intl.NumberFormat('en-EN').format(parseFloat(value));
    if(newVal) {
        return newVal;
    }
    return 0;
}

export const sortingArrChannels = (arr, value, metrics = false, targetROAS = 150) => {
    if (value !== "") {
        let newArr = arr;
        if (metrics) {
            switch (value) {
                case "downloads":
                    newArr = arr.sort((a, b) => {
                        if (a.channelMetrics !== null && b.channelMetrics !== null) {
                            if (a.channelMetrics.metrics.tracked_installs > b.channelMetrics.metrics.tracked_installs) {
                                return 1
                            } else if (b.channelMetrics.metrics.tracked_installs > a.channelMetrics.metrics.tracked_installs) {
                                return -1
                            } else {
                                return 0
                            }
                        } else {
                            return false;
                        }
                    });
                    break;
                case "downloads-top":
                    newArr = arr.sort((a, b) => {
                        if (a.channelMetrics !== null && b.channelMetrics !== null) {
                            if (a.channelMetrics.metrics.tracked_installs < b.channelMetrics.metrics.tracked_installs) {
                                return 1
                            } else if (b.channelMetrics.metrics.tracked_installs < a.channelMetrics.metrics.tracked_installs) {
                                return -1
                            } else {
                                return 0
                            }
                        } else {
                            return false;
                        }
                    });
                    break;
                case "spend":
                    newArr = arr.sort((a, b) => {
                        if (a.channelMetrics.metrics !== null && b.channelMetrics.metrics !== null
                            && a.channelMetrics.metrics.spend !== undefined && b.channelMetrics.metrics.spend !== undefined) {
                            if (a.channelMetrics.metrics.spend > b.channelMetrics.metrics.spend) {
                                return 1
                            } else if (b.channelMetrics.metrics.spend > a.channelMetrics.metrics.spend) {
                                return -1
                            } else {
                                return 0
                            }
                        } else {
                            return false;
                        }
                    });
                    break;
                case "spend-top":
                    newArr = arr.sort((a, b) => {
                        if (a.channelMetrics.metrics !== undefined && a.channelMetrics.metrics !== null
                            && b.channelMetrics.metrics !== undefined && b.channelMetrics.metrics !== null
                            && a.channelMetrics.metrics.spend !== undefined && b.channelMetrics.metrics.spend !== undefined) {
                            if (a.channelMetrics.metrics.spend < b.channelMetrics.metrics.spend) {
                                return 1
                            } else if (b.channelMetrics.metrics.spend < a.channelMetrics.metrics.spend) {
                                return -1
                            } else {
                                return 0
                            }
                        } else {
                            return false;
                        }
                    });
                    break;
                case "cpi":
                    newArr = arr.sort((a, b) => {
                        if (a.channelMetrics.metrics !== null && b.channelMetrics.metrics !== null
                            && a.channelMetrics.metrics.cpi !== undefined && b.channelMetrics.metrics.cpi !== undefined) {
                            if (a.channelMetrics.metrics.cpi > b.channelMetrics.metrics.cpi) {
                                return 1
                            } else if (b.channelMetrics.metrics.cpi > a.channelMetrics.metrics.cpi) {
                                return -1
                            } else {
                                return 0
                            }
                        } else {
                            return false;
                        }
                    });
                    break;
                case "cpi-top":
                    newArr = arr.sort((a, b) => {
                        if (a.channelMetrics.metrics !== undefined && a.channelMetrics.metrics !== null
                            && b.channelMetrics.metrics !== undefined && b.channelMetrics.metrics !== null
                            && a.channelMetrics.metrics.cpi !== undefined && b.channelMetrics.metrics.cpi !== undefined) {
                            if (a.channelMetrics.metrics.cpi < b.channelMetrics.metrics.cpi) {
                                return 1
                            } else if (b.channelMetrics.metrics.cpi < a.channelMetrics.metrics.cpi) {
                                return -1
                            } else {
                                return 0
                            }
                        } else {
                            return false;
                        }
                    });
                    break;

                case "revenue":
                    newArr = arr.sort((a, b) => {
                        if (a.channelMetrics.metrics !== null && b.channelMetrics.metrics !== null
                            && a.channelMetrics.metrics.total_rev_120d !== undefined && b.channelMetrics.metrics.total_rev_120d !== undefined) {
                            if (a.channelMetrics.metrics.total_rev_120d > b.channelMetrics.metrics.total_rev_120d) {
                                return 1
                            } else if (b.channelMetrics.metrics.total_rev_120d > a.channelMetrics.metrics.total_rev_120d) {
                                return -1
                            } else {
                                return 0
                            }
                        } else {
                            return false;
                        }
                    });
                    break;
                case "revenue-top":
                    newArr = arr.sort((a, b) => {
                        if (a.channelMetrics.metrics !== undefined && a.channelMetrics.metrics !== null
                            && b.channelMetrics.metrics !== undefined && b.channelMetrics.metrics !== null
                            && a.channelMetrics.metrics.total_rev_120d !== undefined && b.channelMetrics.metrics.total_rev_120d !== undefined) {
                            if (a.channelMetrics.metrics.total_rev_120d < b.channelMetrics.metrics.total_rev_120d) {
                                return 1
                            } else if (b.channelMetrics.metrics.total_rev_120d < a.channelMetrics.metrics.total_rev_120d) {
                                return -1
                            } else {
                                return 0
                            }
                        } else {
                            return false;
                        }
                    });
                    break;

                case "gross":
                    newArr = arr.sort((a, b) => {
                        if (a.channelMetrics.metrics !== null && b.channelMetrics.metrics !== null
                            && a.channelMetrics.metrics.total_rev_120d !== undefined && a.channelMetrics.metrics.spend !== undefined
                            && b.channelMetrics.metrics.total_rev_120d !== undefined && b.channelMetrics.metrics.spend !== undefined) {
                            let grossA = a.channelMetrics.metrics.total_rev_120d - a.channelMetrics.metrics.spend;
                            let grossB = b.channelMetrics.metrics.total_rev_120d - b.channelMetrics.metrics.spend;
                            if (grossA > grossB) {
                                return 1
                            } else if (grossB > grossA) {
                                return -1
                            } else {
                                return 0
                            }
                        } else {
                            return false;
                        }
                    });
                    break;
                case "gross-top":
                    newArr = arr.sort((a, b) => {
                        if (a.channelMetrics.metrics !== undefined && a.channelMetrics.metrics !== null
                            && b.channelMetrics.metrics !== undefined && b.channelMetrics.metrics !== null
                            && a.channelMetrics.metrics.total_rev_120d !== undefined && a.channelMetrics.metrics.spend !== undefined
                            && b.channelMetrics.metrics.total_rev_120d !== undefined && b.channelMetrics.metrics.spend !== undefined) {

                            let grossA = a.channelMetrics.metrics.total_rev_120d - a.channelMetrics.metrics.spend;
                            let grossB = b.channelMetrics.metrics.total_rev_120d - b.channelMetrics.metrics.spend;
                            if (grossA < grossB) {
                                return 1
                            } else if (grossB < grossA) {
                                return -1
                            } else {
                                return 0
                            }
                        } else {
                            return false;
                        }
                    });
                    break;

                case "predgross":
                    newArr = arr.sort((a, b) => {
                            let aD1 = a.channelMetrics.metrics.total_rev_1d === null ? 0 : a.channelMetrics.metrics.total_rev_1d;
                            let bD1 = b.channelMetrics.metrics.total_rev_1d === null ? 0 : b.channelMetrics.metrics.total_rev_1d;

                            let aSpend = a.channelMetrics.metrics.spend === null ? 0 : a.channelMetrics.metrics.spend;
                            let bSpend = b.channelMetrics.metrics.spend === null ? 0 : b.channelMetrics.metrics.spend;


                            let grossA = ((aD1 * a.channelMetrics.metrics.predicted_k) - aSpend);
                            let grossB = ((bD1 * b.channelMetrics.metrics.predicted_k) - bSpend);
                            if (grossA > grossB) {
                                return 1;
                            } else if (grossB > grossA) {
                                return -1;
                            } else {
                                return 0;
                            }


                    });
                    break;
                case "predgross-top":
                    newArr = arr.sort((a, b) => {
                        let aD1 = a.channelMetrics.metrics.total_rev_1d === null ? 0 : a.channelMetrics.metrics.total_rev_1d;
                        let bD1 = b.channelMetrics.metrics.total_rev_1d === null ? 0 : b.channelMetrics.metrics.total_rev_1d;

                        let aSpend = a.channelMetrics.metrics.spend === null ? 0 : a.channelMetrics.metrics.spend;
                        let bSpend = b.channelMetrics.metrics.spend === null ? 0 : b.channelMetrics.metrics.spend;


                        let grossA = ((aD1 * a.channelMetrics.metrics.predicted_k) - aSpend);
                        let grossB = ((bD1 * b.channelMetrics.metrics.predicted_k) - bSpend);
                        if (grossA < grossB) {
                            return 1;
                        } else if (grossB < grossA) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                    break;


                case "recomcpi":
                    newArr = arr.sort((a, b) => {
                        if (a.channelMetrics.metrics.total_rev_1d !== null && a.channelMetrics.predicted_k !== undefined && a.channelMetrics.metrics.spend !== null
                            && b.channelMetrics.metrics.total_rev_1d !== null && b.channelMetrics.predicted_k !== undefined && b.channelMetrics.metrics.spend !== null
                            && a.channelMetrics.metrics.cpi !== null && b.channelMetrics.metrics.cpi !== null) {
                            let grossA = ((a.channelMetrics.metrics.total_rev_1d * a.channelMetrics.predicted_k) - a.channelMetrics.metrics.spend);
                            let grossB = ((b.channelMetrics.metrics.total_rev_1d * b.channelMetrics.predicted_k) - b.channelMetrics.metrics.spend);
                            let RecommendCPIA = a.channelMetrics.metrics.cpi * (1 + (grossA - targetROAS) / grossA);
                            let RecommendCPIB = b.channelMetrics.metrics.cpi * (1 + (grossB - targetROAS) / grossB);
                            if (RecommendCPIA > RecommendCPIB) {
                                return 1;
                            } else if (RecommendCPIB > RecommendCPIA) {
                                return -1;
                            } else {
                                return 0;
                            }
                        } else {
                            return false;
                        }

                    });
                    break;
                case "recomcpi-top":
                    newArr = arr.sort((a, b) => {
                        if (a.channelMetrics.metrics.total_rev_1d !== null && a.channelMetrics.predicted_k !== undefined && a.channelMetrics.metrics.spend !== null
                            && b.channelMetrics.metrics.total_rev_1d !== null && b.channelMetrics.predicted_k !== undefined && b.channelMetrics.metrics.spend !== null
                            && a.channelMetrics.metrics.cpi !== null && b.channelMetrics.metrics.cpi !== null) {
                            let grossA = ((a.channelMetrics.metrics.total_rev_1d * a.channelMetrics.predicted_k) - a.channelMetrics.metrics.spend);
                            let grossB = ((b.channelMetrics.metrics.total_rev_1d * b.channelMetrics.predicted_k) - b.channelMetrics.metrics.spend);
                            let RecommendCPIA = a.channelMetrics.metrics.cpi * (1 + (grossA - targetROAS) / grossA);
                            let RecommendCPIB = b.channelMetrics.metrics.cpi * (1 + (grossB - targetROAS) / grossB);
                            if (RecommendCPIA < RecommendCPIB) {
                                return 1;
                            } else if (RecommendCPIB < RecommendCPIA) {
                                return -1;
                            } else {
                                return 0;
                            }
                        } else {
                            return false;
                        }
                    });
                    break;


                case "pred_k":
                    newArr = arr.sort((a, b) => {
                        if ((a.channelMetrics.predicted !== undefined && b.channelMetrics.predicted !== undefined)
                            || (a.channelMetrics.predicted_k !== undefined && b.channelMetrics.predicted_k !== undefined)) {
                            let k = 0;
                            let kB = 0;

                            if (a.channelMetrics.predicted === undefined || a.channelMetrics.predicted.tracked_installs < 100) {
                                k = a.channelMetrics.metrics.predicted_k;
                            } else {
                                k = a.channelMetrics.predicted.total_rev_30d / a.channelMetrics.predicted.total_rev_1d;
                                if (isNaN(k)) {
                                    k = 0;
                                }
                            }
                            if (b.channelMetrics.predicted === undefined || b.channelMetrics.predicted.tracked_installs < 100) {
                                kB = b.channelMetrics.metrics.predicted_k;
                            } else {
                                kB = b.channelMetrics.predicted.total_rev_30d / b.channelMetrics.predicted.total_rev_1d;
                                if (isNaN(kB)) {
                                    kB = 0;
                                }
                            }
                            if (k > kB) {
                                return 1;
                            } else if (kB > k) {
                                return -1;
                            } else {
                                return 0;
                            }
                        } else {
                            return -1;
                        }
                    });
                    break;
                case "pred_k-top":
                    newArr = arr.sort((a, b) => {
                        if ((a.channelMetrics.predicted !== undefined && b.channelMetrics.predicted !== undefined)
                            || (a.channelMetrics.predicted_k !== undefined && b.channelMetrics.predicted_k !== undefined)) {
                            let k = 0;
                            let kB = 0;

                            if (a.channelMetrics.predicted === undefined || a.channelMetrics.predicted.tracked_installs < 100) {
                                k = a.channelMetrics.metrics.predicted_k;
                            } else {
                                k = a.channelMetrics.predicted.total_rev_30d / a.channelMetrics.predicted.total_rev_1d;
                                if (isNaN(k)) {
                                    k = 0;
                                }
                            }
                            if (b.channelMetrics.predicted === undefined || b.channelMetrics.predicted.tracked_installs < 100) {
                                kB = b.channelMetrics.metrics.predicted_k;
                            } else {
                                kB = b.channelMetrics.predicted.total_rev_30d / b.channelMetrics.predicted.total_rev_1d;
                                if (isNaN(kB)) {
                                    kB = 0;
                                }
                            }
                            if (k < kB) {
                                return 1;
                            } else if (kB < k) {
                                return -1;
                            } else {
                                return 0;
                            }
                        } else {
                            return -1;
                        }
                    });
                    break;


                case "type":
                    newArr = arr.sort((a, b) => {
                        let typeA = (a.channelMetrics.metrics.predicted_installs !== undefined && a.channelMetrics.metrics.predicted_installs > 100) ? 1 : 0;
                        let typeB = (b.channelMetrics.metrics.predicted_installs !== undefined && b.channelMetrics.metrics.predicted_installs > 100) ? 1 : 0;

                        if (typeA > typeB) {
                            return 1;
                        } else if (typeB > typeA) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                    break;
                case "type-top":
                    newArr = arr.sort((a, b) => {
                        let typeA = (a.channelMetrics.metrics.predicted_installs !== undefined && a.channelMetrics.metrics.predicted_installs > 100) ? 1 : 0;
                        let typeB = (b.channelMetrics.metrics.predicted_installs !== undefined && b.channelMetrics.metrics.predicted_installs > 100) ? 1 : 0;

                        if (typeA < typeB) {
                            return 1;
                        } else if (typeB < typeA) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                    break;


                case "quality":
                    newArr = arr.sort((a, b) => {
                        let typeA = (a.channelMetrics.metrics.predicted_installs !== undefined && a.channelMetrics.metrics.predicted_installs > 100) ? 1 : 0;
                        let typeB = (b.channelMetrics.metrics.predicted_installs !== undefined && b.channelMetrics.metrics.predicted_installs > 100) ? 1 : 0;

                        if (typeA > typeB) {
                            return 1;
                        } else if (typeB > typeA) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                    break;
                case "quality-top":
                    newArr = arr.sort((a, b) => {
                        let typeA = (a.channelMetrics.metrics.predicted_installs !== undefined && a.channelMetrics.metrics.predicted_installs > 100) ? 1 : 0;
                        let typeB = (b.channelMetrics.metrics.predicted_installs !== undefined && b.channelMetrics.metrics.predicted_installs > 100) ? 1 : 0;

                        if (typeA < typeB) {
                            return 1;
                        } else if (typeB < typeA) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                    break;

                case "roas":
                    newArr = arr.sort((a, b) => {
                        console.log(a);
                        console.log(b);
                        let aD1 = a.channelMetrics.metrics.total_rev_1d === null ? 0 : a.channelMetrics.metrics.total_rev_1d;
                        let bD1 = b.channelMetrics.metrics.total_rev_1d === null ? 0 : b.channelMetrics.metrics.total_rev_1d;

                        let aSpend = a.channelMetrics.metrics.spend === null ? 0 : a.channelMetrics.metrics.spend;
                        let bSpend = b.channelMetrics.metrics.spend === null ? 0 : b.channelMetrics.metrics.spend;

                            let grossA = ((aD1 * a.channelMetrics.metrics.predicted_k) / aSpend) * 100;
                            let grossB = ((bD1 * b.channelMetrics.metrics.predicted_k) / bSpend) * 100;

                        if(aSpend === 0) {
                            grossA = 0;
                        }

                        if(bSpend === 0) {
                            grossB = 0;
                        }

                            if (grossA > grossB) {
                                return 1;
                            } else if (grossB > grossA) {
                                return -1;
                            } else {
                                return 0;
                            }
                    });
                    break;
                case "roas-top":
                    newArr = arr.sort((a, b) => {
                        let aD1 = a.channelMetrics.metrics.total_rev_1d === null ? 0 : a.channelMetrics.metrics.total_rev_1d;
                        let bD1 = b.channelMetrics.metrics.total_rev_1d === null ? 0 : b.channelMetrics.metrics.total_rev_1d;

                        let aSpend = a.channelMetrics.metrics.spend === null ? 0 : a.channelMetrics.metrics.spend;
                        let bSpend = b.channelMetrics.metrics.spend === null ? 0 : b.channelMetrics.metrics.spend;

                        let grossA = ((aD1 * a.channelMetrics.metrics.predicted_k) / aSpend) * 100;
                        let grossB = ((bD1 * b.channelMetrics.metrics.predicted_k) / bSpend) * 100;

                        if(aSpend === 0) {
                            grossA = 0;
                        }

                        if(bSpend === 0) {
                            grossB = 0;
                        }

                        if (grossA < grossB) {
                            return 1;
                        } else if (grossB < grossA) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                    break;

                default:
                    break;
            }
        }

        return newArr;
    } else {
        return arr;
    }
};


export const sortingDetails = (arr, value, targetROAS = 150) => {
    if (value) {
        let newArr = arr;
        switch (value) {
            case "downloads":
                newArr = arr.sort((a, b) => (a.tracked_installs > b.tracked_installs) ? 1 : ((b.tracked_installs > a.tracked_installs) ? -1 : 0));
                break;
            case "downloads-top":
                newArr = arr.sort((a, b) => (a.tracked_installs < b.tracked_installs) ? 1 : ((b.tracked_installs < a.tracked_installs) ? -1 : 0));
                break;
            case "spend":
                newArr = arr.sort((a, b) => (a.spend > b.spend) ? 1 : ((b.spend > a.spend) ? -1 : 0));
                break;
            case "spend-top":
                newArr = arr.sort((a, b) => (a.spend < b.spend) ? 1 : ((b.spend < a.spend) ? -1 : 0));
                break;
            case "revenue":
                newArr = arr.sort((a, b) => (a.total_rev_120d > b.total_rev_120d) ? 1 : ((b.total_rev_120d > a.total_rev_120d) ? -1 : 0));
                break;
            case "revenue-top":
                newArr = arr.sort((a, b) => (a.total_rev_120d < b.total_rev_120d) ? 1 : ((b.total_rev_120d < a.total_rev_120d) ? -1 : 0));
                break;

            case "gross":
                newArr = arr.sort((a, b) => {
                    let grossA = a.total_rev_120d - a.spend;
                    let grossB = b.total_rev_120d - b.spend;
                    if (grossA > grossB) {
                        return 1;
                    } else if (grossB > grossA) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
                break;
            case "gross-top":
                newArr = arr.sort((a, b) => {
                    let grossA = a.total_rev_120d - a.spend;
                    let grossB = b.total_rev_120d - b.spend;
                    if (grossA < grossB) {
                        return 1;
                    } else if (grossB < grossA) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
                break;

            case "predgross":
                newArr = arr.sort((a, b) => {

                    if (a.PredictedGrossProfit !== null && a.predicted_k !== undefined
                        && b.predicted_k !== undefined && b.PredictedGrossProfit !== null) {
                        let grossA = a.PredictedGrossProfit;
                        let grossB = b.PredictedGrossProfit;
                        if (grossA > grossB) {
                            return 1;
                        } else if (grossB > grossA) {
                            return -1;
                        } else {
                            return 0;
                        }
                    } else {
                        return 0;
                    }

                });
                break;
            case "predgross-top":
                newArr = arr.sort((a, b) => {
                    if (a.PredictedGrossProfit !== null && a.predicted_k !== undefined
                        && b.predicted_k !== undefined && b.PredictedGrossProfit !== null) {
                        let grossA = a.PredictedGrossProfit;
                        let grossB = b.PredictedGrossProfit;
                        if (grossA < grossB) {
                            return 1;
                        } else if (grossB < grossA) {
                            return -1;
                        } else {
                            return 0;
                        }
                    } else {
                        return 0;
                    }
                });
                break;


            case "recomcpi":
                newArr = arr.sort((a, b) => {
                    if (a.RecommendCPI !== undefined && b.RecommendCPI !== undefined) {
                        let RecommendCPIA = a.RecommendCPI;
                        let RecommendCPIB = b.RecommendCPI;
                        if (RecommendCPIA > RecommendCPIB) {
                            return 1;
                        } else if (RecommendCPIB > RecommendCPIA) {
                            return -1;
                        } else {
                            return 0;
                        }
                    } else {
                        return 0;
                    }

                });
                break;
            case "recomcpi-top":
                newArr = arr.sort((a, b) => {
                    if (a.RecommendCPI !== undefined && b.RecommendCPI !== undefined) {
                        let RecommendCPIA = a.RecommendCPI;
                        let RecommendCPIB = b.RecommendCPI;
                        if (RecommendCPIA < RecommendCPIB) {
                            return 1;
                        } else if (RecommendCPIB < RecommendCPIA) {
                            return -1;
                        } else {
                            return 0;
                        }
                    } else {
                        return 0;
                    }
                });
                break;


            case "pred_k":
                newArr = arr.sort((a, b) => {
                    if (a.predicted_k !== undefined && b.predicted_k !== undefined) {
                        if (a.predicted_k > b.predicted_k) {
                            return 1;
                        } else if (b.predicted_k > a.predicted_k) {
                            return -1;
                        } else {
                            return 0;
                        }
                    } else {
                        return false;
                    }
                });
                break;
            case "pred_k-top":
                newArr = arr.sort((a, b) => {
                    if (a.predicted_k !== undefined && b.predicted_k !== undefined) {
                        if (a.predicted_k < b.predicted_k) {
                            return 1;
                        } else if (b.predicted_k < a.predicted_k) {
                            return -1;
                        } else {
                            return 0;
                        }
                    } else {
                        return false;
                    }
                });
                break;

            case "roas":
                newArr = arr.sort((a, b) => {
                    if (a.PredictedROAS !== null && a.predicted_k !== undefined
                        && b.total_rev_1d !== null && b.predicted_k !== undefined && b.PredictedROAS !== null) {
                        let grossA = a.PredictedROAS;
                        let grossB = b.PredictedROAS;
                        if (grossA > grossB) {
                            return 1;
                        } else if (grossB > grossA) {
                            return -1;
                        } else {
                            return 0;
                        }
                    } else {
                        return false;
                    }

                });
                break;
            case "roas-top":
                newArr = arr.sort((a, b) => {
                    if (a.PredictedROAS !== null && a.predicted_k !== undefined
                        && b.total_rev_1d !== null && b.predicted_k !== undefined && b.PredictedROAS !== null) {
                        let grossA = a.PredictedROAS;
                        let grossB = b.PredictedROAS;
                        if (grossA < grossB) {
                            return 1;
                        } else if (grossB < grossA) {
                            return -1;
                        } else {
                            return 0;
                        }
                    } else {
                        return false;
                    }
                });
                break;

            case "cpi":
                newArr = arr.sort((a, b) => {
                    if (a !== null && b !== null
                        && a.cpi !== undefined && b.cpi !== undefined) {
                        if (a.cpi > b.cpi) {
                            return 1
                        } else if (b.cpi > a.cpi) {
                            return -1
                        } else {
                            return 0
                        }
                    } else {
                        return false;
                    }
                });
                break;
            case "cpi-top":
                newArr = arr.sort((a, b) => {
                    if (a !== undefined && a !== null
                        && b !== undefined && b !== null
                        && a.cpi !== undefined && b.cpi !== undefined) {
                        if (a.cpi < b.cpi) {
                            return 1
                        } else if (b.cpi < a.cpi) {
                            return -1
                        } else {
                            return 0
                        }
                    } else {
                        return false;
                    }
                });
                break;
            default:
                break;
        }
        return newArr;
    } else {
        return arr;
    }
};

export default sortingArr;
