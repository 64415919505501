import React, {Component} from 'react';
import './controls.css';
import moment from "moment";

import SwitchSelector from "react-switch-selector";

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from '@fortawesome/free-solid-svg-icons'

class Controls extends Component {
    state = {
        dateStart: "",
        dateEnd: "",
        preset: "7days",
        targetROAS: 0,
        timer: 0,
        again: false,
        lenght: 0,
        dateEndHistory: "",
        dateStartHistory: "",
    };

    startTimer() {
        if(this.state.timer === 0) {
            if(this.state.again) {
                this.setState(() => {
                    return {
                        timer: 10,
                    }
                });
            }   else {
                this.setState(() => {
                    return {
                        timer: 60,
                        again: true,
                    }
                });
            }

        }
        let _this = this;
        let time = setInterval(function () {
            if(_this.state.timer >= 1) {
                _this.setState(() => {
                    return {
                        timer: _this.state.timer-=1,
                    }
                });
            }   else {
                clearInterval(time);
            }

        }, 1000)
    }

    componentDidMount() {
        this.setState(() => {
            return {
                targetROAS: this.props.targetROAS,
                lenght: this.props.lengths,
            }
        });
        this.resetDate("7days");
    }

    componentDidUpdate() {
        if(this.props.lengths !== this.state.lenght) {
            this.setState(() => {
                return {
                    timer: 0,
                    lenght: this.props.lengths,
                    targetROAS: this.props.targetROAS,
                }
            });
        }
    }

    toggleROAS = (e) => {
        if(e.target !== null) {
            let value = e.target.value;
            if (value.length > 3) {
                value = value.slice(0,3);
            }

            if(value >= 0) {
                this.setState(() => {
                    return {
                        targetROAS: value,
                    }
                });
                this.props.toggleROAS(value)
            }

        }
    };

    resetDate(preset) {
        let dateEnd = moment().format('YYYY-MM-DD');
        let dateStart = undefined;
        if(preset !== "custom") {
            if(preset === "30days") {
                dateStart = moment(dateEnd).subtract(30,'days').format('YYYY-MM-DD');
            }   else if(preset === "90days") {
                dateStart = moment(dateEnd).subtract(90,'days').format('YYYY-MM-DD');
            }   else if(preset === "7days") {
                dateStart = moment(dateEnd).subtract(7,'days').format('YYYY-MM-DD');
            }
            if(moment().diff(moment(dateEnd), 'days') === 0) {
                dateStart = moment(dateStart).subtract(2, 'days').format('YYYY-MM-DD');
                dateEnd = moment(dateEnd).subtract(2, 'days').format('YYYY-MM-DD');
            }   else if(moment().diff(moment(dateEnd), 'days') === 1) {
                dateStart = moment(dateStart).subtract(1, 'days').format('YYYY-MM-DD');
                dateEnd = moment(dateEnd).subtract(1, 'days').format('YYYY-MM-DD');
            }
        }
        if(dateStart !== undefined) {
            this.setState({
                dateStart: dateStart,
                dateEnd: dateEnd,
            });
            this.props.changeDate(dateStart,dateEnd);
        }
    }

    toggleEmptyCountry = (e) => {
        // console.log(e.target.checked);
        this.props.toggleShowEmpty();
    };

    getCountries = (e) => {
        // console.log(e.target.checked);
        this.props.getCountries();
        this.startTimer();
    };


    setView1 = (e) => {
        console.log(e.target.value);
        this.props.toggleView(1, e.target.value);
    };

    setView2 = (e) => {
        console.log(e.target.value);
        this.props.toggleView(2, e.target.value);
    };

    toggleTop5 = (e) => {
        // console.log(e.target.checked);
        this.props.toggleTop5();
    };

    changeDates = (start, end) => {
        this.setState(() => {
            return {
                dateEnd: moment(end).format('YYYY-MM-DD'),
                dateStart: moment(start).format('YYYY-MM-DD'),
            }
        }, () => {
            if(this.state.dateStart.length > 0 && this.state.dateEnd.length > 0) {
                this.props.changeDate(this.state.dateStart,this.state.dateEnd);
                this.setState({
                    preset: "custom",
                })
            }
        });
    };

    changeDatesHistory = (start, end) => {
        this.setState(() => {
            return {
                dateEndHistory: moment(end).format('YYYY-MM-DD'),
                dateStartHistory: moment(start).format('YYYY-MM-DD'),
            }
        }, () => {
            if(this.state.dateStart.length > 0 && this.state.dateEnd.length > 0) {
                this.props.changeDateHistory(this.state.dateStartHistory,this.state.dateEndHistory);
                this.setState({
                    preset: "custom",
                })
            }
        });
    };

    changeDate = (data, e) => {
        e.preventDefault();
        if(data === "end") {
            let dateEnd = moment(e.target.value).format('YYYY-MM-DD');
            this.setState({
                dateEnd: dateEnd,
            })
        }   else if(data === "start") {
            let dateStart = moment(e.target.value).format('YYYY-MM-DD');
            this.setState({
                dateStart: dateStart,
            })
        }
    };

    datePreset = (e) => {
        e.preventDefault();
        this.setState({
            preset: e.target.dataset.type,
        });
        this.resetDate(e.target.dataset.type);
    };

    onSubmit = (e) => {
        e.preventDefault();
        if(this.state.dateStart.length > 0 && this.state.dateEnd.length > 0) {
            this.props.changeDate(this.state.dateStart,this.state.dateEnd);
            this.setState({
                preset: "custom",
            })
        }

    };
  render() {

      const options = [
          {
              label: "Daily",
              value: 1,
              selectedBackgroundColor: "#6B7FE3",
          },
          {
              label: "Weekly",
              value: 2,
              selectedBackgroundColor: "#6B7FE3",
          }
      ];

      const onChange = (newValue) => {
          this.props.setHistory(newValue);
      };


      return (
         <div className="controls">
             <div className="controls">
                 {this.props.loadingData ?
                     <form className="input-group-dates">
                         <FontAwesomeIcon icon={faSpinner} spin size="lg"/>
                     </form>
                  :
                     <form className="input-group mb-3 date"
                           onSubmit={this.onSubmit} >
                         <input type="date"
                                className="form-control"
                                placeholder="Start date"
                                value={this.props.dateStart ? this.props.dateStart : this.state.dateStart}
                                readOnly={true}
                                onChange={this.changeDate.bind(this, "start")}/>
                         <input type="date"
                                className="form-control"
                                placeholder="End date"
                                value={this.props.dateEnd ? this.props.dateEnd : this.state.dateEnd}
                                readOnly={true}
                                onChange={this.changeDate.bind(this, "end")}/>
                         <div className="input-group">
                             {this.state.preset === "custom" ?
                                 <DateRangePicker onCallback={this.changeDates}>
                                     <button type="button" className="date-set date-set__button active">
                                         Select range
                                     </button>
                                 </DateRangePicker> :
                                 <DateRangePicker onCallback={this.changeDates}>
                                     <button type="button" className="date-set date-set__button">
                                         Select range
                                     </button>
                                 </DateRangePicker>
                             }
                         </div>
                         <div className="presets">
                             {this.state.preset === "7days" ?  <button
                                     data-type="7days"
                                     className="date-set active"
                                     onClick={this.datePreset}
                                 >7 days</button> :
                                 <button
                                     data-type="7days"
                                     className="date-set"
                                     onClick={this.datePreset}
                                 >7 days</button>
                             }

                             {this.state.preset === "30days" ?  <button
                                     data-type="30days"
                                     className="date-set active"
                                     onClick={this.datePreset}
                                 >30 days</button> :
                                 <button
                                     data-type="30days"
                                     className="date-set"
                                     onClick={this.datePreset}
                                 >30 days</button>
                             }


                             {this.state.preset === "90days" ?  <button
                                     data-type="90days"
                                     className="date-set active"
                                     onClick={this.datePreset}
                                 >90 days</button> :
                                 <button
                                     data-type="90days"
                                     className="date-set"
                                     onClick={this.datePreset}
                                 >90 days</button>
                             }
                         </div>
                     </form>
                 }


                 <div className="input-group mb-3">
                     <h4>Apps</h4>
                     <select
                         onChange={this.setView1}
                         defaultValue="1">
                         <option value="1">Apps>Channel</option>
                         <option value="2">Channels>Apps</option>
                     </select>
                 </div>
                 <div className="input-group mb-3">
                     <h4>Campaign</h4>
                     <select
                         onChange={this.setView2}
                         defaultValue="1">
                         <option value="1">Campaign>Country</option>
                         <option value="2">Country>Campaign</option>
                     </select>
                 </div>

             </div>
             <div className="controls flex-start">
                 <img src="" alt=""/>
                 <div className="input-group mb-3">
                     <h4>Target ROAS: </h4>
                     <input type="number"
                            className="target-roas"
                            placeholder={this.state.targetROAS.length > 0 ? "" : 0}
                            value={this.state.targetROAS}
                            max="999"
                            onChange={this.toggleROAS}
                     />
                     %
                 </div>
                 <div className="input-group mb-3">
                     <h4>Show empty countries</h4>
                     <input type="checkbox"
                            defaultChecked={false}
                            value="check"
                            onChange={this.toggleEmptyCountry}
                            id="toggleShowCountriesCheckbox"
                     />
                     <label  id="toggleShowCountries" htmlFor="toggleShowCountriesCheckbox">
                     </label>
                 </div>
                 <div className="input-group mb-3">
                     <h4>TOP5 countries</h4>
                     <input type="checkbox"
                            defaultChecked={false}
                            value="check"
                            onChange={this.toggleTop5}
                            id="toggleTop5Checkbox"
                     />
                     <label  id="toggleTop5" htmlFor="toggleTop5Checkbox">
                     </label>
                 </div>
                 {!this.props.showDetails && false ?
                 <div className="input-group mb-3" >
                     <h4>History: </h4>
                     <input type="date"
                            className="form-control"
                            placeholder="Start date"
                            value={this.props.dateStartHistory ? this.props.dateStartHistory : this.state.dateStartHistory}
                            readOnly={true}
                            onChange={this.changeDate.bind(this, "start")}/>
                     <input type="date"
                            className="form-control"
                            placeholder="End date"
                            value={this.props.dateEndHistory ? this.props.dateEndHistory : this.state.dateEndHistory}
                            readOnly={true}
                            onChange={this.changeDate.bind(this, "end")}/>
                     <div className="input-group">
                             <DateRangePicker onCallback={this.changeDatesHistory}>
                                 <button type="button" className="date-set date-set__button">
                                     Select range
                                 </button>
                             </DateRangePicker>
                     </div>
                     {false ?
                         <div className="your-required-wrapper" style={{width: 150, height: 31}}>
                             <SwitchSelector
                                 onChange={onChange}
                                 options={options}
                                 backgroundColor={"#353b48"}
                                 fontColor={"#f5f6fa"}
                                 initialSelectedIndex={this.props.history-1}
                             />
                         </div>
                     : ""}
                 </div>
                 : ""}
                 {this.props.lengths > 0
                     ?   <div className="input-group mb-3">
                         {this.state.timer > 0
                             ? <span className="geos-timer">Wait geos.{
                                 this.state.timer % 2 === 0 ? ".  "
                                     : ".."
                             } {this.state.timer}</span>
                             :
                             <button className="presets date-set active" onClick={this.getCountries}>
                                 {!this.state.again ? "Get geos" : "Check new geos" }
                             </button>
                         }
                     </div>
                     : ""
                 }


             </div>
         </div>
      );
  }
};

export default Controls;
