import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleDown, faAngleUp, faSpinner} from '@fortawesome/free-solid-svg-icons'
import TableCampaigns from "../table-campaigns";
import TableCountry from "../table-country";

import './table-list-channels.css';
import sortingArr, {getFormated} from "../functions/functions";

class TableChannels extends Component {
    state = {
        searchText: "",
    };

    search = (e) => {
        let value = e.target.value;
        this.props.setSearch(value);
        this.setState(() => {
            return {
                searchText: value,
            }
        });
    };


    getDetails = (data, e) => {
        e.preventDefault();
        this.props.getDetails(data)
    };

    getCampaignData = (data, e) => {
        e.preventDefault();
        this.props.setCampaignsData(data.app_id, data.channel_id);
    };

    showCampaignsInCountry = (data, e) => {
        e.preventDefault();
        console.log(data);
        this.props.showCampaignsInCountry(data.key, data.country);
    };

    showApps = (data, e) => {
        e.preventDefault();
        this.props.showApps(data, e);
    };


    sorting = (data, e) => {
        e.preventDefault();
        this.props.sorting(data);
    };


    render() {
        let {sort, targetROAS, top5, channels, viewLevel2, Countries} = this.props;
        const _this = this;


        let newChannels = [];
        Object.keys(channels).map(function (objectKey, i) {
            if(channels[objectKey] !== undefined) {
                let newChannel = channels[objectKey];
                newChannel.metrics = channels[objectKey];
                newChannels.push(channels[objectKey]);
            }
            return null;
        });

        if (newChannels.length > 0) {
            if (sort !== "") {
                newChannels = sortingArr(newChannels, sort, false,targetROAS);
            }
        }

        return (
            <>

                <table className="table-list-channels" data-toggle="table">
                    <tbody>

                    <tr className="first-tr">
                        <th>Channels:</th>
                        <th colSpan="5">Input data</th>
                        <th colSpan="7">Calculation results</th>
                    </tr>
                    <tr className="second-tr">
                        <th width="512px" align="left">
                        </th>
                        <th>
                            <a href="#/" onClick={_this.sorting.bind(this, "downloads")}>
                                Downloads
                            </a>
                            {sort === 'downloads'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'downloads-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }

                        </th>
                        <th>
                            <a href="#/" onClick={_this.sorting.bind(this, "cpi")}>
                                Current CPI
                            </a>
                            {sort === 'cpi'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'cpi-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }
                        </th>
                        <th><a href="#/"
                               onClick={_this.sorting.bind(this, "spend")}
                        >
                            Spend
                        </a>
                            {sort === 'spend'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'spend-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }
                        </th>
                        <th>
                            <a href="#/" onClick={_this.sorting.bind(this, "revenue")}>
                                Revenue (120LTV)
                            </a>
                            {sort === 'revenue'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'revenue-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }
                        </th>
                        <th>
                            <a href="#/" onClick={_this.sorting.bind(this, "gross")}>
                                Gross Profit
                            </a>
                            {sort === 'gross'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'gross-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }
                        </th>
                        <th>
                            <a href="#/" onClick={_this.sorting.bind(this, "predgross")}>
                                Predicted Gross Profit
                            </a>
                            {sort === 'predgross'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'predgross-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }
                        </th>
                        <th>
                            <a href="#/" onClick={_this.sorting.bind(this, "roas")}>
                                Predicted ROAS
                            </a>
                            {sort === 'roas'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'roas-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }
                        </th>
                        <th>
                            <a href="#/" onClick={_this.sorting.bind(this, "recomcpi")}>
                                Rec. CPI
                            </a>
                            {sort === 'recomcpi'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'recomcpi-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }
                        </th>
                        <th>
                            <a href="#/" onClick={_this.sorting.bind(this, "quality")}>
                                Quality
                            </a>
                            {sort === 'quality'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'quality-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }
                        </th>
                        <th>
                            <a href="#/" onClick={_this.sorting.bind(this, "type")}>
                                Type
                            </a>
                            {sort === 'type'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'type-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }
                        </th>
                        <th>
                            <a href="#/" onClick={_this.sorting.bind(this, "pred_k")}>
                                k
                            </a>
                            {sort === 'pred_k'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'pred_k-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }
                        </th>
                        <th>History</th>
                    </tr>
                    {Object.keys(newChannels).map(function (objectKey, i) {
                        let item = newChannels[objectKey];
                        let channel = item;
                        let newCampaigns = [];
                        let name = item.ad_network_name;
                        let apps = item.apps !== undefined ? item.apps : null;
                        let showApps = item.showApps;
                        let prevMetrics = item.prevMetrics !== undefined ? item.prevMetrics : null;
                        item = item.metrics;
                        if (item === undefined || item === null) {
                            return null;
                        } else {
                            item.spend = item.spend === null ? 0 : item.spend;
                            item.total_rev_120d = item.total_rev_120d === null ? 0 : item.total_rev_120d;
                        }

                        showApps && apps !== null && Object.keys(apps).map(function (objectKey, b) {
                            let app = null;
                            for (let k in _this.props.AppsFull) {
                                if (_this.props.AppsFull[k].app_id === apps[objectKey]) {
                                    app = _this.props.AppsFull[k];
                                    break;
                                }
                            }

                            let key = channel.ad_network_id + '' + app.app_id;
                            let channelMetrics = _this.props.dataChannels[key];


                            // Пустые каналы скрываем
                            if (channelMetrics === undefined || channelMetrics.empty === true) {
                                return null;
                            } else {
                                newCampaigns.push(channelMetrics);
                            }
                            return null;
                        });
                        if (newCampaigns.length > 0) {
                            if (sort !== "") {
                                newCampaigns = sortingArr(newCampaigns, sort, true,targetROAS);
                                console.log(newCampaigns);
                            }
                        }

                        let PredictedGrossProfit = "";
                        let PredictedROAS = "";
                        let predictedType = "Direct";
                        let predicted = null;

                        if(item.predicted !== undefined) {
                            item.predicted_installs = item.predicted.tracked_installs;
                            let k = item.predicted.total_rev_30d / item.predicted.total_rev_1d;
                            if (isNaN(k)) {
                                k = 0;
                            }
                            item.predicted_k = k;
                            predicted = item.predicted;
                        }

                        if (item.predicted_installs === undefined || item.predicted_installs < 100) {
                            predictedType = "Indirect";
                            if(predicted !== null) {
                                predicted.predictedType = "Indirect";
                            }
                        }

                        if (item.predicted_k !== undefined && item.predicted_k > 0 && item.total_rev_1d > 0 && item.spend > 1) {
                            PredictedGrossProfit = ((item.total_rev_1d * item.predicted_k) - item.spend);
                            PredictedROAS = ((item.total_rev_1d * item.predicted_k) / item.spend) * 100;
                            PredictedROAS = (PredictedROAS).toFixed(2) + "%";

                            PredictedGrossProfit = "$" + (PredictedGrossProfit).toFixed(2);
                            PredictedGrossProfit = PredictedGrossProfit.replace("$-", "-$");

                        }


                        let prevDownloads = "";
                        let prevSpend = "";
                        let prevPredictedGrossProfit = "";
                        let prevPredictedROAS = "";

                        // 100 * (b - a) / a
                        if (prevMetrics !== null && item.tracked_installs !== null && prevMetrics.tracked_installs !== null) {
                            if (item.predicted_k !== undefined && item.predicted_k > 0 && prevMetrics.total_rev_1d > 0 && item.spend > 1) {
                                let predictedGrossProfit = ((item.total_rev_1d * item.predicted_k) - item.spend);
                                let predictedRoas = ((item.total_rev_1d * item.predicted_k) / item.spend) * 100;
                                prevPredictedGrossProfit = ((prevMetrics.total_rev_1d * item.predicted_k) - prevMetrics.spend);
                                prevPredictedROAS = ((prevMetrics.total_rev_1d * item.predicted_k) / prevMetrics.spend) * 100;

                                prevPredictedGrossProfit = ((predictedGrossProfit - prevPredictedGrossProfit) / prevPredictedGrossProfit * 100).toFixed(2);
                                prevPredictedROAS = ((predictedRoas - prevPredictedROAS) / prevPredictedROAS * 100).toFixed(2);
                            }
                            prevDownloads = ((item.tracked_installs - prevMetrics.tracked_installs) / prevMetrics.tracked_installs * 100).toFixed(2);

                            prevSpend = ((item.spend - prevMetrics.spend) / prevMetrics.spend * 100).toFixed(2);

                        }

                        return (
                            <>
                                <tr key={i}>
                                    <th className="app-name" width="512px">

                                        <a href="#/"
                                           onClick={_this.showApps.bind(this, channel.ad_network_id)}
                                        >
                                            {showApps
                                                ? <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                                : ""
                                            }
                                            <img srcSet={channel.ad_network_icon_url} alt="icon"/>
                                            {name}
                                        </a>

                                        {/*<img src={item.icon_url} alt="icon"/>*/}
                                    </th>

                                    <th>
                                        <span className="total_installs">{getFormated(item.tracked_installs)}</span>
                                        {prevDownloads !== "" && !isNaN(prevDownloads) && prevDownloads !== "Infinity" ?
                                            <span
                                                className={prevDownloads >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                <span className="prev">{prevDownloads}%</span>
                                                <span className="prevValue">{
                                                    prevMetrics.tracked_installs !== undefined
                                                    ? getFormated((prevMetrics.tracked_installs).toFixed(2))
                                                        : 0
                                                }</span>
                                            </span>
                                            :
                                            <span className="stat"> </span>
                                        }
                                    </th>
                                    <th>-</th>
                                    <th>
                                        <span className="left-value">${item.spend.toFixed(2)}</span>
                                        {prevSpend !== "" && !isNaN(prevSpend) && prevSpend !== "Infinity" ?
                                            <span
                                                className={prevSpend >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                <span className="prev">{prevSpend}%</span>
                                                <span className="prevValue">${
                                                    prevMetrics.spend !== undefined ?
                                                        (prevMetrics.spend).toFixed(2)
                                                    : 0
                                                }</span>
                                            </span>
                                            :
                                            <span className="stat"> </span>
                                        }
                                    </th>
                                    <th>
                                        ${item.total_rev_120d.toFixed(2)}
                                    </th>
                                    <th>
                                        {('$' + ((item.total_rev_120d - item.spend).toFixed(2))).replace("$-", "-$")}
                                    </th>
                                    <th>
                                        <span className="left-value">
                                        {PredictedGrossProfit !== "" ? PredictedGrossProfit : "-"}
                                        </span>
                                        {prevPredictedGrossProfit !== "" && prevPredictedGrossProfit !== "Infinity" ?
                                            <span
                                                className={prevPredictedGrossProfit >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                <span className="prev">{prevPredictedGrossProfit}%</span>
                                                <span
                                                    className="prevValue">{("$" + ((prevMetrics.total_rev_1d * item.predicted_k) - prevMetrics.spend).toFixed(2)).replace("$-", "-$")}</span>
                                            </span>
                                            :
                                            <span className="stat"> </span>
                                        }
                                    </th>
                                    <th>
                                        <span className="left-value">
                                        {PredictedROAS !== "" ? PredictedROAS : "-"}
                                        </span>
                                        {prevPredictedROAS !== "" && prevPredictedROAS !== "Infinity" ?
                                            <span
                                                className={prevPredictedROAS >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                <span className="prev">{prevPredictedROAS}%</span>
                                                <span
                                                    className="prevValue">{(((prevMetrics.total_rev_1d * item.predicted_k) / prevMetrics.spend) * 100).toFixed(2)}%</span>
                                            </span>
                                            :
                                            <span className="stat"> </span>
                                        }
                                    </th>
                                    {/*<th>${(item.cpi * (1 + ( ((item.total_rev_30d-item.spend)/item.spend)- 150) / ((item.total_rev_30d-item.spend)/item.spend))).toFixed(2)}</th>*/}
                                    <th>-</th>
                                    <th>{
                                        item.predicted_installs !== undefined ?
                                            item.predicted_installs > 100 ? <span className="stat stat-good">Good</span>
                                                :
                                                <span className="stat stat-normal">Normal</span>
                                            : "-"
                                    }</th>
                                    <th>{predictedType}</th>
                                    <th>{item.predicted_k !== undefined && item.predicted_k > 0 ? (item.predicted_k).toFixed(2) : "-"}</th>
                                    <th>
                                        {item.predicted_k !== undefined && !_this.props.showDetails ?
                                            <a href="#/"
                                               onClick={_this.getDetails.bind(this,  {
                                                   predicted: predicted,
                                                   channel_id: channel.ad_network_id,
                                               })}
                                            >
                                                History
                                            </a>
                                            : <FontAwesomeIcon icon={faSpinner} spin size="lg"/>
                                        }

                                    </th>
                                </tr>
                                {showApps && newCampaigns.length > 0 && Object.keys(newCampaigns).map(function (objectKey, b) {
                                    let channelMetrics = newCampaigns[objectKey];
                                    let channel = newCampaigns[objectKey];


                                    let key = channel.metrics.ad_network_id + "" + channel.metrics.app_id;
                                    let campaigns =  _this.props.campaigns[key];

                                    // Пустые каналы скрываем
                                    if (channelMetrics.empty === true) {
                                        return null;
                                    }

                                    let AppPredicted = null;

                                    if (channelMetrics !== undefined && channelMetrics.metrics !== null) {
                                        let showCampaigns = false;
                                        if(campaigns !== undefined && campaigns.showCampaigns !== undefined && campaigns.showCampaigns) {
                                            showCampaigns = true;
                                        }


                                        let prevMetrics = channelMetrics.prevMetrics !== undefined ? channelMetrics.prevMetrics : null;
                                        let predicted_k = channelMetrics.predicted_k;
                                        let predicted_installs = channelMetrics.predicted_installs;

                                        if(channelMetrics.predicted !== undefined) {
                                            predicted_installs = channelMetrics.predicted.tracked_installs;
                                            let k = channelMetrics.predicted.total_rev_30d / channelMetrics.predicted.total_rev_1d;
                                            if (isNaN(k)) {
                                                k = 0;
                                            }
                                            predicted_k = k;
                                            if(predicted_installs >= 100) {
                                                AppPredicted = channelMetrics.predicted;
                                            }   else {
                                                AppPredicted = predicted;
                                            }
                                        }   else {
                                            AppPredicted = predicted;
                                        }

                                        channelMetrics = channelMetrics.metrics;

                                        let prevDownloads = "";
                                        let prevSpend = "";
                                        let PredictedGrossProfit = "";
                                        let PredictedROAS = "";
                                        let prevPredictedGrossProfit = "";
                                        let prevPredictedROAS = "";
                                        let predictedType = "Direct";


                                        if (predicted_installs === undefined || predicted_installs < 100) {
                                            predictedType = "Indirect";
                                            predicted_k = item.predicted_k;
                                        }

                                        if (predicted_k !== undefined && predicted_k > 0 && channelMetrics.total_rev_1d > 0 && channelMetrics.spend > 1) {
                                            PredictedGrossProfit = ((channelMetrics.total_rev_1d * predicted_k) - channelMetrics.spend);
                                            PredictedROAS = ((channelMetrics.total_rev_1d * predicted_k) / channelMetrics.spend) * 100;
                                            PredictedROAS = (PredictedROAS).toFixed(2) + "%";

                                            PredictedGrossProfit = "$" + (PredictedGrossProfit).toFixed(2);
                                            PredictedGrossProfit = PredictedGrossProfit.replace("$-", "-$");
                                        }

                                        // 100 * (b - a) / a

                                        if (prevMetrics !== null) {
                                            if (channelMetrics.predicted_k !== undefined && channelMetrics.predicted_k > 0 && prevMetrics.total_rev_1d > 0 && channelMetrics.spend > 1) {
                                                let predictedGrossProfit = ((channelMetrics.total_rev_1d * channelMetrics.predicted_k) - channelMetrics.spend);
                                                let predictedRoas = ((channelMetrics.total_rev_1d * channelMetrics.predicted_k) / channelMetrics.spend) * 100;
                                                prevPredictedGrossProfit = ((prevMetrics.total_rev_1d * channelMetrics.predicted_k) - prevMetrics.spend);
                                                prevPredictedROAS = ((prevMetrics.total_rev_1d * channelMetrics.predicted_k) / prevMetrics.spend) * 100;

                                                prevPredictedGrossProfit = ((predictedGrossProfit - prevPredictedGrossProfit) / prevPredictedGrossProfit * 100).toFixed(2);
                                                prevPredictedROAS = ((predictedRoas - prevPredictedROAS) / prevPredictedROAS * 100).toFixed(2);
                                            }

                                            if (channelMetrics.tracked_installs !== null && (prevMetrics.tracked_installs !== null && prevMetrics.tracked_installs > 0)) {
                                                prevDownloads = ((channelMetrics.tracked_installs - prevMetrics.tracked_installs) / prevMetrics.tracked_installs * 100).toFixed(2);
                                            }
                                            if (channelMetrics.spend !== null) {
                                                prevSpend = ((channelMetrics.spend - prevMetrics.spend) / prevMetrics.spend * 100).toFixed(2);
                                            }


                                        }

                                        return (
                                            <>
                                                <tr key={b}>
                                                    <th className="channel-name">
                                                        <a href="#/"
                                                           onClick={_this.getCampaignData.bind(this, {
                                                               app_id: channel.metrics.app_id,
                                                               channel_id: channel.metrics.ad_network_id,
                                                           })}
                                                        >
                                                            {showCampaigns
                                                                ? <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                                                : ""
                                                            }
                                                            <img srcSet={channel.metrics.app_icon_url} alt="icon"/>
                                                            {channel.metrics.app_name} ({channel.metrics.platform})
                                                        </a>
                                                        {campaigns !== undefined && campaigns.loading !== undefined && campaigns.loading ?
                                                            <FontAwesomeIcon icon={faSpinner} spin size="lg"/>
                                                            : ""
                                                        }


                                                        {/*<img src={item.icon_url} alt="icon"/>*/}
                                                    </th>
                                                    <th><span
                                                        className="left-value">{getFormated(channelMetrics.tracked_installs)}</span>
                                                        {prevDownloads !== "" && !isNaN(prevDownloads) && prevDownloads !== "Infinity" ?
                                                            <span
                                                                className={prevDownloads >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                                 <span className="prev">{prevDownloads}%</span>
                                                                 <span
                                                                     className="prevValue">{
                                                                     prevMetrics.tracked_installs !== undefined
                                                                     ? getFormated((prevMetrics.tracked_installs).toFixed(2))
                                                                         : 0
                                                                 }</span>
                                                            </span>
                                                            :
                                                            <span className="stat"> </span>
                                                        }
                                                    </th>
                                                    <th>-</th>
                                                    {channelMetrics.spend > 1 ?
                                                        <th>
                                                            <span
                                                                className="left-value">${channelMetrics.spend.toFixed(2)}</span>
                                                            {prevSpend !== "" && !isNaN(prevSpend) && prevSpend !== "Infinity" ?
                                                                <span
                                                                    className={prevSpend >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                                    <span className="prev">{prevSpend}%</span>
                                                                    <span
                                                                        className="prevValue">${
                                                                        prevMetrics.spend !== undefined
                                                                        ? (prevMetrics.spend).toFixed(2)
                                                                            : 0
                                                                    }</span>
                                                                </span>
                                                                :
                                                                <span className="stat"> </span>
                                                            }
                                                        </th>
                                                        :
                                                        <th><span className="left-value">$0.00</span><span
                                                            className="stat"> </span></th>
                                                    }

                                                    {channelMetrics.total_rev_120d > 1 ?
                                                        <th>
                                                            ${channelMetrics.total_rev_120d.toFixed(2)}
                                                        </th>
                                                        :
                                                        <th>-</th>
                                                    }
                                                    {channelMetrics.total_rev_120d > 1 && channelMetrics.spend > 1 ?
                                                        <th>
                                                            {('$' + ((channelMetrics.total_rev_120d - channelMetrics.spend).toFixed(2))).replace("$-", "-$")}
                                                        </th>
                                                        :
                                                        <th>-</th>
                                                    }

                                                    <th>
                                        <span className="left-value">
                                        {PredictedGrossProfit !== "" ? PredictedGrossProfit : "-"}
                                        </span>
                                                        {prevPredictedGrossProfit !== "" && prevPredictedGrossProfit !== "Infinity" ?
                                                            <span
                                                                className={prevPredictedGrossProfit >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                <span className="prev">{prevPredictedGrossProfit}%</span>
                                                <span
                                                    className="prevValue">{("$" + ((prevMetrics.total_rev_1d * channelMetrics.predicted_k) - prevMetrics.spend).toFixed(2)).replace("$-", "-$")}</span>
                                            </span>
                                                            :
                                                            <span className="stat"> </span>
                                                        }
                                                    </th>
                                                    <th>
                                                        <span className="left-value">
                                                             {PredictedROAS !== "" ? PredictedROAS : "-"}
                                                        </span>
                                                        {prevPredictedROAS !== "" && prevPredictedROAS !== "Infinity" ?
                                                            <span
                                                                className={prevPredictedROAS >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                                <span className="prev">{prevPredictedROAS}%</span>
                                                                <span
                                                    className="prevValue">{(((prevMetrics.total_rev_1d * channelMetrics.predicted_k) / prevMetrics.spend) * 100).toFixed(2)}%</span>
                                                             </span>
                                                            :
                                                            <span className="stat"> </span>
                                                        }
                                                    </th>
                                                    {/*<th>${(item.cpi * (1 + ( ((item.total_rev_30d-item.spend)/item.spend)- 150) / ((item.total_rev_30d-item.spend)/item.spend))).toFixed(2)}</th>*/}
                                                    <th>-</th>
                                                    <th>{
                                                        predicted_installs !== undefined ?
                                                            predicted_installs > 100 ?
                                                                <span className="stat stat-good">Good</span>
                                                                :
                                                                <span className="stat stat-normal">Normal</span>
                                                            : "-"
                                                    }</th>
                                                    <th>{predictedType}</th>
                                                    <th>{predicted_k !== undefined && predicted_k > 0 ? (predicted_k).toFixed(2) : "-"}</th>
                                                    <th>
                                                        {predicted_k !== undefined && !_this.props.showDetails ?
                                                            <a href="#/"
                                                               onClick={_this.getDetails.bind(this,  {
                                                                       predicted: AppPredicted,
                                                                       app_id: channel.metrics.app_id,
                                                                       channel_id:channel.metrics.ad_network_id
                                                                   }
                                                               )}
                                                            >
                                                                History
                                                            </a>
                                                            : <FontAwesomeIcon icon={faSpinner} spin size="lg"/>
                                                        }
                                                    </th>
                                                </tr>
                                                {showCampaigns ?
                                                    viewLevel2 === "1" ?
                                                        <TableCampaigns
                                                            viewLevel2={viewLevel2}
                                                            getDetails={_this.getDetails}
                                                            Countries={Countries[channel.metrics.ad_network_id + "" + channel.metrics.app_id]}
                                                            top5={top5}
                                                            predicted={AppPredicted}
                                                            targetROAS={targetROAS}
                                                            campaigns={campaigns}
                                                            app_id={channel.metrics.app_id}
                                                            channel_id={channel.metrics.ad_network_id}
                                                            sorting={sort}
                                                            showEmptyCountries={_this.props.showEmptyCountries}
                                                        />
                                                        :
                                                        <TableCountry
                                                            getDetails={_this.getDetails}
                                                            showDetails={_this.props.showDetails}
                                                            showCampaignsInCountry={_this.showCampaignsInCountry}
                                                            viewLevel2={viewLevel2}
                                                            Countries={Countries[channel.metrics.ad_network_id + "" + channel.metrics.app_id]}
                                                            top5={top5}
                                                            predicted={AppPredicted}
                                                            targetROAS={targetROAS}
                                                            campaigns={campaigns}
                                                            app_id={channel.metrics.app_id}
                                                            channel_id={channel.metrics.ad_network_id}
                                                            sorting={sort}
                                                            showEmptyCountries={_this.props.showEmptyCountries}
                                                        />

                                                    : ""
                                                }

                                            </>
                                        )
                                    } else {
                                        return (
                                            <tr key={b}>
                                                <th className="channel-name">
                                                    {newCampaigns[objectKey].app_name}
                                                    &nbsp;
                                                    <FontAwesomeIcon icon={faSpinner} spin size="lg"/>
                                                    {/*<img src={item.icon_url} alt="icon"/>*/}
                                                </th>
                                                <th>-</th>
                                                <th>-</th>
                                                <th>-</th>
                                                <th>-</th>
                                                <th>-</th>

                                                <th>-</th>
                                                <th>-</th>
                                                <th>-</th>
                                                <th>-</th>
                                                <th>-</th>
                                            </tr>
                                        )
                                    }


                                })}
                            </>
                        )
                    })}
                    </tbody>
                </table>
            </>
        );
    }
}


export default TableChannels;
