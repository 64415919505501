import React, {PureComponent} from 'react';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';


import './charting.css';
import moment from "moment";

class Charting extends PureComponent {
    state = {
        cpi: false,
        predLTVuser: false,
        spend: true,
        predictedGrossProfit: true,
        predictedLTV: false,
        predictedRoas: false,
    };

    changeParam = (param) => {
        console.log(param);
        if (param === "cpi") {
            this.setState({
                cpi: !this.state.cpi,
            })
        } else if (param === "predLTVuser") {
            this.setState({
                predLTVuser: !this.state.predLTVuser,
            })
        } else if (param === "spend") {
            this.setState({
                spend: !this.state.spend,
            })
        } else if (param === "predictedGrossProfit") {
            this.setState({
                predictedGrossProfit: !this.state.predictedGrossProfit,
            })
        } else if (param === "predictedLTV") {
            this.setState({
                predictedLTV: !this.state.predictedLTV,
            })
        } else if (param === "predictedRoas") {
            this.setState({
                predictedRoas: !this.state.predictedRoas,
            })
        }
    }


    render() {
        let NewDate = [];
        let {data, detailsK, predictedK} = this.props;
        const _this = this;

        if (Object.keys(data).length > 0) {
            Object.keys(data).map(function (objectKey, i) {
                let predicted_k = 2;
                let PredictedGrossProfit = 0;
                let PredictedLTVuser = 0;
                let PredictedLTV = 0;
                let PredictedRoas = 0;
                let obj = data[i];
                let object = {};


                if(detailsK === undefined || detailsK.tracked_installs === undefined  || detailsK.tracked_installs < 100) {
                    predicted_k = 2;
                    PredictedGrossProfit = ((obj.total_rev_1d * predicted_k) - obj.spend);
                    PredictedRoas = ((obj.total_rev_1d * predicted_k) / obj.spend) * 100;
                }   else {
                    predicted_k = detailsK.total_rev_30d / detailsK.total_rev_1d;


                    const offset = moment().diff(moment(obj.date), 'days');
                    if (offset > 14) {
                        predicted_k = detailsK.total_rev_30d / detailsK.total_rev_14d;
                        PredictedGrossProfit = ((obj.total_rev_14d * predicted_k) - obj.spend);
                        PredictedRoas = ((obj.total_rev_14d * predicted_k) / obj.spend) * 100;
                    } else if (offset > 7) {
                        predicted_k = detailsK.total_rev_30d / detailsK.total_rev_7d;
                        PredictedGrossProfit = ((obj.total_rev_7d * predicted_k) - obj.spend);
                        PredictedRoas = ((obj.total_rev_7d * predicted_k) / obj.spend) * 100;
                    } else if (offset > 3) {
                        predicted_k = detailsK.total_rev_30d / detailsK.total_rev_3d;
                        PredictedGrossProfit = ((obj.total_rev_3d * predicted_k) - obj.spend);
                        PredictedRoas = ((obj.total_rev_3d * predicted_k) / obj.spend) * 100;
                    } else {
                        PredictedGrossProfit = ((obj.total_rev_1d * predicted_k) - obj.spend);
                        PredictedRoas = ((obj.total_rev_1d * predicted_k) / obj.spend) * 100;
                    }


                    console.log(predicted_k);
                    console.log("WW HERE");
                }




                if (obj.spend !== null) {
                    PredictedLTVuser = ((PredictedGrossProfit + obj.spend) / obj.tracked_installs);
                    PredictedLTV = (PredictedGrossProfit + obj.spend);
                }

                object.name = obj.date;
                object.spend = obj.spend !== null ? parseFloat(obj.spend.toFixed(2)) : 0;
                object.cpi = obj.cpi !== null ? parseFloat(obj.cpi.toFixed(2)) : 0;
                object.predictedGrossProfit = !isNaN(PredictedGrossProfit) ? parseFloat(PredictedGrossProfit.toFixed(2)) : 0;
                object.PredictedLTVuser = !isNaN(PredictedLTVuser) ? parseFloat(PredictedLTVuser.toFixed(2)) : 0;
                object.PredictedLTV = !isNaN(PredictedLTV) ? parseFloat(PredictedLTV.toFixed(2)) : 0;
                object.PredictedRoas = !isNaN(PredictedRoas) ? parseFloat(PredictedRoas.toFixed(2)) : 0;
                NewDate.push(object);


            });
        } else {
            return null;
        }

        return (
            <>
                {NewDate.length > 0 ?
                    <>
                        <div className="controls">
                            <div className="input-group mb-3">
                                <h4>CPI</h4>
                                <input type="checkbox"
                                       defaultChecked={this.state.cpi}
                                       value="check"
                                       onChange={this.changeParam.bind(this, "cpi")}
                                       id="toggleCPI"
                                />
                                <label id="toggleCPILabel" htmlFor="toggleCPI">
                                </label>
                            </div>

                            <div className="input-group mb-3">
                                <h4>Predicted LTV / user</h4>
                                <input type="checkbox"
                                       defaultChecked={this.state.predLTVuser}
                                       value="check"
                                       onChange={this.changeParam.bind(this, "predLTVuser")}
                                       id="togglePredictedLTVuser"
                                />
                                <label id="togglePredictedLTVuserLabel" htmlFor="togglePredictedLTVuser">
                                </label>
                            </div>

                            <div className="input-group mb-3">
                                <h4>Predicted LTV</h4>
                                <input type="checkbox"
                                       defaultChecked={this.state.predictedLTV}
                                       value="check"
                                       onChange={this.changeParam.bind(this, "predictedLTV")}
                                       id="togglePredictedLTV"
                                />
                                <label id="togglePredictedLTVLabel" htmlFor="togglePredictedLTV">
                                </label>
                            </div>

                            <div className="input-group mb-3">
                                <h4>Spend</h4>
                                <input type="checkbox"
                                       defaultChecked={this.state.spend}
                                       value="check"
                                       onChange={this.changeParam.bind(this, "spend")}
                                       id="toggleSpend"
                                />
                                <label id="toggleSpendLabel" htmlFor="toggleSpend">
                                </label>
                            </div>

                            <div className="input-group mb-3">
                                <h4>Predicted Gross Profit</h4>
                                <input type="checkbox"
                                       defaultChecked={this.state.predictedGrossProfit}
                                       value="check"
                                       onChange={this.changeParam.bind(this, "predictedGrossProfit")}
                                       id="togglePredictedGrossProfit"
                                />
                                <label id="togglePredictedGrossProfitLabel" htmlFor="togglePredictedGrossProfit">
                                </label>
                            </div>

                            <div className="input-group mb-3">
                                <h4>Predicted ROAS</h4>
                                <input type="checkbox"
                                       defaultChecked={this.state.predictedRoas}
                                       value="check"
                                       onChange={this.changeParam.bind(this, "predictedRoas")}
                                       id="togglePredictedRoas"
                                />
                                <label id="togglePredictedRoasLabel" htmlFor="togglePredictedRoas">
                                </label>
                            </div>
                        </div>
                        <ResponsiveContainer minHeight={300}>
                            <LineChart
                                height={300}
                                data={NewDate}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey="name"/>
                                <YAxis/>
                                <Tooltip/>
                                <Legend/>
                                {this.state.cpi ?
                                    <Line type="monotone" dataKey="cpi" stroke="#84d98c"/> : ""}
                                {this.state.predLTVuser ?
                                    <Line type="monotone" dataKey="PredictedLTVuser" stroke="#d3d984"/> : ""}

                                {this.state.predictedGrossProfit ?
                                    <Line type="monotone" dataKey="predictedGrossProfit" stroke="#8884d8"/> : ""}

                                {this.state.predictedLTV ?
                                    <Line type="monotone" dataKey="PredictedLTV" stroke="#82ddae"/> : ""}


                                {this.state.predictedRoas ?
                                    <Line type="monotone" dataKey="PredictedRoas" stroke="#4477de"/> : ""}


                                {this.state.spend ?
                                    <Line type="monotone" dataKey="spend" stroke="#d98487"/> : ""}

                            </LineChart>
                        </ResponsiveContainer>
                    </>
                    : ""}
            </>
        )

    }
}


export default Charting;
