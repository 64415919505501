export default class TenjinService {

  _apiBase = 'https://api.tenjin.com/v2';
  _token = '';

  async setToken(token) {
    this._token = token;
  }

  async getResource(url) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer eyJhbGciOiJFUzUxMiJ9.eyJhY2Nlc3NfdG9rZW5faWQiOiIzOGFjNTcyMy1kMjY4LTRlZTctOTVkMS02NWRhMjFjMjc3N2UiLCJvcmdhbml6YXRpb25fdXNlcl9pZCI6Ijg4MmU3OTVhLTAwZGEtNDVkNC1hOGExLWFjMmZmZDJlODRhOSIsInVwZGF0ZWRfYXQiOjE2MTMwMjIzMTYsImlhdCI6MTYxMzAyMjMxNiwiaXNzIjoidGVuamluOnRyYWNrZXIifQ.AMTSVBlxEnEweJ5uGO6QqTkcEAHNcWXjRFJn2_59ZlYZ5oVwbfLJ99849ZsRYhAQzfUdPCyHvoFNtTQ-DJdNUYpkAfs96u5Zusu4H0kzk3WXz8LpTn0MHLibnrqHd2EP_NWlijTsgge49TIhJil5Kyvv3u6pnEmiO_LfXKSyMSsmeWIJ");

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
      mode: 'no-cors',
    };

    fetch("https://api.tenjin.com/v2/apps", requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
  }

  async getAllApps() {
    const res = await this.getResource(`/apps`);
    return res.results;
  }

  async getAllPeople() {
    const res = await this.getResource(`/people/`);
    return res.results.map(this._transformPerson);
  }

  async getPerson(id) {
    const person = await this.getResource(`/people/${id}/`)
    return this._transformPerson(person);
  }

  async getAllPlanets() {
    const res = await this.getResource(`/planets/`);
    return res.results.map(this._transformPlanet);
  }

  async getPlanet(id) {
    const planet = await this.getResource(`/planets/${id}/`)
    return this._transformPlanet(planet);
  }

  async getAllStarships() {
    const res = await this.getResource(`/starships/`);
    return res.results.map(this._transformStarship);
  }

  async getStarship(id) {
    const starship = await this.getResource(`/starships/${id}/`)
    return this._transformStarship(starship);
  }

  _extractId(item) {
    const idRegEx = /\/(\d{1,3})\/$/;
    return item.url.match(idRegEx)[1];
  }

  _transformPlanet(planet) {
    return {
      id: this._extractId(planet),
      name: planet.name,
      img: planet.img,
      population: planet.population,
      rotationPeriod: planet.rotation_period,
      diameter: planet.diameter,
    }
  }

  _transformStarship(starship) {
    return {
      id: this._extractId(starship),
      name: starship.name,
      model: starship.model,
      manufacturer: starship.manufacturer,
      constInCredits: starship.constInCredits,
      length: starship.length,
      crew: starship.crew,
      passengers: starship.passengers,
      cargoCapacity: starship.cargoCapacity,
    }
  }

  _transformPerson(person) {
    return {
      id: this._extractId(person),
      name: person.name,
      gender: person.gender,
      birthYear: person.birthYear,
      eyeColor: person.eyeColor,
    }
  }
}
