import React, {Component} from 'react';
import './table.css';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleDown, faAngleUp, faSpinner, faStar} from '@fortawesome/free-solid-svg-icons'
import {faStar as regularStar} from '@fortawesome/free-regular-svg-icons'
import TableCampaigns from "../table-campaigns";
import TableCountry from "../table-country";

import sortingArr, {sortingArrChannels, getFormated} from "../functions/functions";

class Table extends Component {
    state = {
        searchText: "",
        appOrganic: [],
        getOrganic: false,
    };

    setValues = (app_id, organicK = 0, organic1LTV = 0, organicInstalls = 0) => {
        let newValues = {};
            if(organicK !== this.state.organicK && organicK !== 0) {
                newValues.organicK = organicK;
            }
            if(organic1LTV !== this.state.organic1LTV && organic1LTV !== 0) {
                newValues.organic1LTV = organic1LTV;
            }
            if(organicInstalls !== this.state.organicInstalls && organicInstalls !== 0) {
                newValues.organicInstalls = organicInstalls;
            }
            if(Object.keys(newValues).length > 0 && this.state.appOrganic[app_id] === undefined) {
                let appOrganic = this.state.appOrganic;
                appOrganic[app_id] = newValues;
                this.setState(() => {
                    return {
                        appOrganic: appOrganic,
                    }
                }, () => {
                    console.log(this.state.appOrganic);
                });
            }

    };

    search = (e) => {
        let value = e.target.value;
        this.props.setSearch(value);
        this.setState(() => {
            return {
                searchText: value,
            }
        });
    };

    getCampaign = (id, e) => {
        e.preventDefault();
        console.log(id);
        this.props.getCampaigns(id)
    };


    getDetails = (data, e) => {
        e.preventDefault();
        this.props.getDetails(data)
    };

    setFavorite = (data, e) => {
        e.preventDefault();
        console.log(data);
        this.props.setFavorite(data.app_id);
    };

    getCampaignData = (data, e) => {
        e.preventDefault();
        this.props.setCampaignsData(data.app_id, data.channel_id);
    };

    showCampaignsInCountry = (data, e) => {
        e.preventDefault();
        this.props.showCampaignsInCountry(data.key, data.country);
    };

    ShowCountries = (data, e) => {
        e.preventDefault();
        this.props.changeShowCountries(data.app_id, data.channel_id, data.campaign_id);
    };
    sorting = (data, e) => {
        e.preventDefault();
        this.props.sorting(data);
    };


    render() {
        let {data, sort, targetROAS, top5, viewLevel2, Countries} = this.props;

        let organK = 0;
        let organ1LTV = 0;
        let organInstalls = 0;

        const _this = this;
        let newData = [];
        if (sort !== "") {
            for (let i in data) {
                newData.push(data[i]);
            }
            data = sortingArr(newData, sort, false, targetROAS);
        } else {
            newData = data;
        }


        return (
            <>
                {newData && newData.length < 1 && this.state.searchText !== "" ?
                    <div className="alert alert-dark" role="alert">
                        По запросу: "{this.state.searchText}" ничего не найдено...
                    </div>
                    : ""
                }
                <table className="table-list2" data-toggle="table">
                    <tbody>
                    <tr className="first-tr">
                        <th></th>
                        <th colSpan="5">Input data</th>
                        <th colSpan="7">Calculation results</th>
                    </tr>
                    <tr className="second-tr">
                        <th width="512px" align="left">
                            {data.length > 0 || this.state.searchText !== "" ?
                                <input type="text"
                                       placeholder="Search on app name"
                                       onChange={this.search}
                                       value={this.state.searchText}
                                />
                                :
                                ""
                            }
                        </th>
                        <th>
                            <a href="#/" onClick={_this.sorting.bind(this, "downloads")}>
                                Downloads
                            </a>
                            {sort === 'downloads'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'downloads-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }

                        </th>
                        <th>
                            <a href="#/" onClick={_this.sorting.bind(this, "cpi")}>
                                Current CPI
                            </a>
                            {sort === 'cpi'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'cpi-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }
                        </th>
                        <th><a href="#/"
                               onClick={_this.sorting.bind(this, "spend")}
                        >
                            Spend
                        </a>
                            {sort === 'spend'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'spend-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }
                        </th>
                        <th>
                            <a href="#/" onClick={_this.sorting.bind(this, "revenue")}>
                                Revenue (120LTV)
                            </a>
                            {sort === 'revenue'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'revenue-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }
                        </th>
                        <th>
                            <a href="#/" onClick={_this.sorting.bind(this, "gross")}>
                                Gross Profit
                            </a>
                            {sort === 'gross'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'gross-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }
                        </th>
                        <th>
                            <a href="#/" onClick={_this.sorting.bind(this, "predgross")}>
                                Predicted Gross Profit
                            </a>
                            {sort === 'predgross'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'predgross-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }
                        </th>
                        <th>
                            <a href="#/" onClick={_this.sorting.bind(this, "roas")}>
                                Predicted ROAS
                            </a>
                            {sort === 'roas'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'roas-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }
                        </th>
                        <th>
                            <a href="#/" onClick={_this.sorting.bind(this, "recomcpi")}>
                                Rec. CPI
                            </a>
                            {sort === 'recomcpi'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'recomcpi-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }
                        </th>
                        <th>
                            <a href="#/" onClick={_this.sorting.bind(this, "quality")}>
                                Quality
                            </a>
                            {sort === 'quality'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'quality-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }
                        </th>
                        <th>
                            <a href="#/" onClick={_this.sorting.bind(this, "type")}>
                                Type
                            </a>
                            {sort === 'type'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'type-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }
                        </th>
                        <th>
                            <a href="#/" onClick={_this.sorting.bind(this, "pred_k")}>
                                k
                            </a>
                            {sort === 'pred_k'
                                ? <FontAwesomeIcon icon={faAngleUp} size="lg"/>
                                : sort === 'pred_k-top' ?
                                    <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                    : ""
                            }
                        </th>
                        <th>History</th>
                    </tr>
                    {Object.keys(newData).map(function (objectKey, i) {
                        let item = newData[objectKey];
                        let newCampaigns = [];


                        item.showChannels && item.channels !== null && Object.keys(item.channels).map(function (objectKey, b) {
                            let channel = item.channels[objectKey];


                            let key = channel + '' + item.app_id;
                            let channelMetrics = _this.props.dataChannels[key];


                            let campaigns = _this.props.campaigns[key] !== undefined ? _this.props.campaigns[key] : [];

                            // Пустые каналы скрываем

                            let thisChannels = {};
                            thisChannels.id = b;
                            thisChannels.channel_id = channelMetrics.ad_network_id;
                            thisChannels.channelMetrics = channelMetrics;
                            thisChannels.metrics = channelMetrics.metrics;
                            thisChannels.campaigns = campaigns;
                            thisChannels.name = channelMetrics.ad_network_name;
                            thisChannels.icon = channelMetrics.ad_network_icon_url;
                            newCampaigns.push(thisChannels);

                            return null;
                        });
                        if (newCampaigns.length > 0) {
                            if (sort !== "") {
                                console.log(sort);
                                newCampaigns = sortingArrChannels(newCampaigns, sort, true, targetROAS);
                            }
                        }

                        if (item.predicted !== undefined) {
                            item.predicted_installs = item.predicted.tracked_installs;
                            let k = item.predicted.total_rev_30d / item.predicted.total_rev_1d;
                            if (isNaN(k)) {
                                k = 0;
                            }
                            item.predicted_k = k;
                        }


                        let PredictedGrossProfit = "";
                        let prevPredictedGrossProfit = "";
                        let prevPredictedROAS = "";
                        let PredictedROAS = "";
                        let predictedType = "Direct";
                        let predicted = item.predicted !== undefined ? item.predicted : {};

                        if (item.predicted_installs === undefined || item.predicted_installs < 100) {
                            predictedType = "Indirect";
                            item.predicted_k = 2;
                            predicted.predictedType = "Indirect";
                        }

                        if (item.predicted_k !== undefined && item.predicted_k > 0 && item.total_rev_1d > 0 && item.spend > 1) {
                            PredictedGrossProfit = ((item.total_rev_1d * item.predicted_k) - item.spend);
                            PredictedROAS = ((item.total_rev_1d * item.predicted_k) / item.spend) * 100;
                            PredictedROAS = (PredictedROAS).toFixed(2) + "%";

                            PredictedGrossProfit = "$" + (PredictedGrossProfit).toFixed(2);
                            PredictedGrossProfit = PredictedGrossProfit.replace("$-", "-$");

                        }

                        if (!item.spend > 0) {
                            // console.error("Empty spend", item);
                            return null;
                        }

                        let prevDownloads = "";
                        let prevSpend = "";


                        // 100 * (b - a) / a
                        if (item.prevMetrics !== undefined) {
                            if (item.predicted_k !== undefined && item.predicted_k > 0 && item.prevMetrics.total_rev_1d > 0 && item.spend > 1 && item.prevMetrics.spend > 0) {
                                let predictedGrossProfit = ((item.total_rev_1d * item.predicted_k) - item.spend);
                                let predictedRoas = ((item.total_rev_1d * item.predicted_k) / item.spend) * 100;
                                prevPredictedGrossProfit = ((item.prevMetrics.total_rev_1d * item.predicted_k) - item.prevMetrics.spend);
                                prevPredictedROAS = ((item.prevMetrics.total_rev_1d * item.predicted_k) / item.prevMetrics.spend) * 100;

                                prevPredictedGrossProfit = ((predictedGrossProfit - prevPredictedGrossProfit) / prevPredictedGrossProfit * 100).toFixed(2);
                                prevPredictedROAS = ((predictedRoas - prevPredictedROAS) / prevPredictedROAS * 100).toFixed(2);
                            }

                            prevDownloads = ((item.tracked_installs - item.prevMetrics.tracked_installs) / item.prevMetrics.tracked_installs * 100).toFixed(2);

                            //TODO delete
                            // console.log("current: ",item.spend);
                            // console.log("prev: ", item.prevMetrics.spend);
                            prevSpend = ((item.spend - item.prevMetrics.spend) / item.prevMetrics.spend * 100).toFixed(2);


                        }


                        return (
                            <>
                                <tr key={i}>
                                    <th className="app-name" width="512px">

                                        <a href="#/"
                                           onClick={_this.getCampaign.bind(this, item.app_id)}
                                        >
                                            {item.showChannels
                                                ? <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                                : ""
                                            }
                                            <img srcSet={item.app_icon_url} alt="icon"/>
                                            {item.app_name} ({item.platform})
                                        </a>

                                        {/*<img src={item.icon_url} alt="icon"/>*/}
                                    </th>

                                    <th>
                                        <span className="total_installs">{getFormated(item.tracked_installs)}</span>
                                        {prevDownloads !== "" && !isNaN(prevDownloads) && prevDownloads !== "Infinity" ?
                                            <span
                                                className={prevDownloads >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                <span className="prev">{prevDownloads}%</span>
                                                <span className="prevValue">{
                                                    item.prevMetrics.tracked_installs !== undefined && item.prevMetrics.tracked_installs > 0 ?
                                                        getFormated((item.prevMetrics.tracked_installs).toFixed(2))
                                                        : 0
                                                }</span>
                                            </span>
                                            :
                                            <span className="stat"> </span>
                                        }
                                    </th>
                                    <th>-</th>
                                    <th>
                                        <span className="left-value">${item.spend.toFixed(2)}</span>
                                        {prevSpend !== "" && !isNaN(prevSpend) && prevSpend !== "Infinity" ?
                                            <span
                                                className={prevSpend >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                <span className="prev">{prevSpend}%</span>
                                                <span
                                                    className="prevValue">${item.prevMetrics.spend !== undefined && item.prevMetrics.spend > 0 ?
                                                    (item.prevMetrics.spend).toFixed(2)
                                                    : 0
                                                }</span>
                                            </span>
                                            :
                                            <span className="stat"> </span>
                                        }
                                    </th>
                                    <th>${item.total_rev_120d.toFixed(2)}</th>
                                    <th>
                                        {('$' + ((item.total_rev_120d - item.spend).toFixed(2))).replace("$-", "-$")}
                                    </th>
                                    <th>
                                        <span className="left-value">
                                        {PredictedGrossProfit !== "" ? PredictedGrossProfit : "-"}
                                        </span>
                                        {prevPredictedGrossProfit !== "" && prevPredictedGrossProfit !== "Infinity" ?
                                            <span
                                                className={prevPredictedGrossProfit >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                <span className="prev">{prevPredictedGrossProfit}%</span>
                                                <span
                                                    className="prevValue">{("$" + ((item.prevMetrics.total_rev_1d * item.predicted_k) - item.prevMetrics.spend).toFixed(2)).replace("$-", "-$")}</span>
                                            </span>
                                            :
                                            <span className="stat"> </span>
                                        }
                                    </th>
                                    <th>
                                        <span className="left-value">
                                        {PredictedROAS !== "" ? PredictedROAS : "-"}
                                        </span>
                                        {prevPredictedROAS !== "" && prevPredictedROAS !== "Infinity" ?
                                            <span
                                                className={prevPredictedROAS >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                <span className="prev">{prevPredictedROAS}%</span>
                                                <span
                                                    className="prevValue">{(((item.prevMetrics.total_rev_1d * item.predicted_k) / item.prevMetrics.spend) * 100).toFixed(2)}%</span>
                                            </span>
                                            :
                                            <span className="stat"> </span>
                                        }
                                    </th>
                                    {/*<th>${(item.cpi * (1 + ( ((item.total_rev_30d-item.spend)/item.spend)- 150) / ((item.total_rev_30d-item.spend)/item.spend))).toFixed(2)}</th>*/}
                                    <th>-</th>
                                    <th>{
                                        item.predicted_installs !== undefined ?
                                            item.predicted_installs > 100 ? <span className="stat stat-good">Good</span>
                                                :
                                                <span className="stat stat-normal">Normal</span>
                                            : "-"
                                    }</th>
                                    <th>{predictedType}</th>
                                    <th>{item.predicted_k !== undefined && item.predicted_k > 0 ? (item.predicted_k).toFixed(2) : "-"}</th>
                                    <th>
                                        {item.predicted_k !== undefined && !_this.props.showDetails ?
                                            <a href="#/"
                                               onClick={_this.getDetails.bind(this, {
                                                   predicted: predicted,
                                                   app_id: item.app_id
                                               })}
                                            >
                                                History
                                            </a>
                                            : <FontAwesomeIcon icon={faSpinner} spin size="sm"/>
                                        }


                                    </th>
                                </tr>
                                {item.showChannels && newCampaigns.length > 0 && Object.keys(newCampaigns).map(function (objectKey, b) {
                                    let channelMetrics = newCampaigns[objectKey].channelMetrics;
                                    let campaigns = newCampaigns[objectKey].campaigns;


                                    if (channelMetrics !== undefined && channelMetrics.metrics !== null) {
                                        let prevMetrics = channelMetrics.prevMetrics !== undefined ? channelMetrics.prevMetrics : null;
                                        let predicted_k = channelMetrics.predicted_k;
                                        let predicted_installs = channelMetrics.predicted_installs;
                                        let predictedChannel = null;

                                        if (channelMetrics.predicted !== undefined) {
                                            predicted_installs = channelMetrics.predicted.tracked_installs;
                                            let k = channelMetrics.predicted.total_rev_30d / channelMetrics.predicted.total_rev_1d;
                                            if (isNaN(k)) {
                                                k = 0;
                                            }
                                            predicted_k = k;
                                            if (predicted_installs >= 100) {
                                                predictedChannel = channelMetrics.predicted;
                                            } else {
                                                predictedChannel = predicted;
                                            }

                                        } else {
                                            predictedChannel = predicted;
                                        }

                                        channelMetrics = channelMetrics.metrics;

                                        let prevDownloads = "";
                                        let prevSpend = "";
                                        let PredictedROAS = "";
                                        let prevPredictedGrossProfit = "";
                                        let prevPredictedROAS = "";
                                        let predictedType = "Direct";
                                        if (predicted_installs === undefined || predicted_installs < 100) {
                                            predictedType = "Indirect";
                                            predicted_k = item.predicted_k;
                                            if (predictedChannel !== null) {
                                            }   else {
                                                predictedChannel = {};
                                            }
                                            if(predictedChannel.predicted !== undefined && predictedChannel.predicted !== null) {
                                            }   else {
                                                predictedChannel.predicted = {};
                                            }
                                            predictedChannel.predicted.predictedType = "Indirect";

                                        }

                                        if (predicted_k !== undefined && predicted_k > 0 && channelMetrics.total_rev_1d > 0) {

                                            if(channelMetrics.spend !== null && channelMetrics.spend > 0) {
                                                PredictedGrossProfit = ((channelMetrics.total_rev_1d * predicted_k) - channelMetrics.spend);
                                                PredictedROAS = ((channelMetrics.total_rev_1d * predicted_k) / channelMetrics.spend) * 100;
                                                PredictedROAS = (PredictedROAS).toFixed(2) + "%";
                                            }   else {
                                                PredictedGrossProfit = ((channelMetrics.total_rev_1d * predicted_k));
                                                PredictedROAS = 0;
                                                PredictedROAS = (PredictedROAS).toFixed(2) + "%";
                                            }

                                        }   else {
                                            PredictedGrossProfit = 0;
                                        }
                                        PredictedGrossProfit = "$" + (PredictedGrossProfit).toFixed(2);
                                        PredictedGrossProfit = PredictedGrossProfit.replace("$-", "-$");

                                        // 100 * (b - a) / a

                                        if (prevMetrics !== null) {
                                            if (predicted_k !== undefined && predicted_k > 0 && prevMetrics.total_rev_1d > 0 && channelMetrics.spend > 1) {
                                                let predictedGrossProfit = ((channelMetrics.total_rev_1d * predicted_k) - channelMetrics.spend);
                                                prevPredictedGrossProfit = ((prevMetrics.total_rev_1d * predicted_k) - channelMetrics.spend);

                                                let predictedRoas = ((channelMetrics.total_rev_1d * predicted_k) / channelMetrics.spend) * 100;
                                                prevPredictedROAS = ((prevMetrics.total_rev_1d * predicted_k) / channelMetrics.spend) * 100;

                                                prevPredictedGrossProfit = ((predictedGrossProfit - prevPredictedGrossProfit) / prevPredictedGrossProfit * 100).toFixed(2);
                                                prevPredictedROAS = ((predictedRoas - prevPredictedROAS) / prevPredictedROAS * 100).toFixed(2);

                                            }

                                            if (channelMetrics.tracked_installs !== null && (prevMetrics.tracked_installs !== null && prevMetrics.tracked_installs > 0)) {
                                                prevDownloads = ((channelMetrics.tracked_installs - prevMetrics.tracked_installs) / prevMetrics.tracked_installs * 100).toFixed(2);
                                            }
                                            if (channelMetrics.spend !== null) {
                                                prevSpend = ((channelMetrics.spend - prevMetrics.spend) / prevMetrics.spend * 100).toFixed(2);
                                            }

                                        }
                                        channelMetrics.predicted_k = predicted_k;
                                        channelMetrics.predicted_installs = predicted_installs;

                                        if(channelMetrics.ad_network_id === 0
                                            && channelMetrics.predicted_k
                                            && channelMetrics.total_rev_1d !== undefined
                                        ) {
                                            _this.setValues(item.app_id,channelMetrics.predicted_k, channelMetrics.total_rev_1d, channelMetrics.tracked_installs);
                                        }




                                        return (
                                            <>
                                                <tr key={b}>
                                                    <th className="channel-name">
                                                        <a href="#/"
                                                           onClick={_this.getCampaignData.bind(this, {
                                                               app_id: item.app_id,
                                                               channel_id: channelMetrics.ad_network_id,
                                                           })}
                                                        >
                                                            {campaigns.showCampaigns
                                                                ? <FontAwesomeIcon icon={faAngleDown} size="lg"/>
                                                                : ""
                                                            }
                                                            {channelMetrics.ad_network_icon_url !== undefined
                                                                ? <img alt="icon"
                                                                       srcSet={channelMetrics.ad_network_icon_url}/>
                                                                : ""
                                                            }
                                                            {channelMetrics.ad_network_name}
                                                        </a>
                                                        {campaigns.loading !== undefined && campaigns.loading ?
                                                            <FontAwesomeIcon icon={faSpinner} spin size="lg"/>
                                                            : ""
                                                        }


                                                        {/*<img src={item.icon_url} alt="icon"/>*/}
                                                    </th>
                                                    <th><span
                                                        className="left-value">{getFormated(channelMetrics.tracked_installs)}</span>
                                                        {prevDownloads !== "" && !isNaN(prevDownloads) && prevDownloads !== "Infinity" ?
                                                            <span
                                                                className={prevDownloads >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                                <span className="prev">{prevDownloads}%</span>
                                                                <span
                                                                    className="prevValue">{
                                                                    prevMetrics.tracked_installs !== undefined && prevMetrics.tracked_installs > 0 ?
                                                                        getFormated((prevMetrics.tracked_installs).toFixed(2))
                                                                        : 0
                                                                }</span>
                                                            </span>
                                                            :
                                                            <span className="stat"> </span>
                                                        }
                                                    </th>
                                                    <th>-</th>
                                                    {channelMetrics.spend > 1 ?
                                                        <th>
                                                            <span
                                                                className="left-value">${channelMetrics.spend.toFixed(2)}</span>
                                                            {prevSpend !== "" && !isNaN(prevSpend) && prevSpend !== "Infinity" ?
                                                                <span
                                                                    className={prevSpend >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                                    <span className="prev">{prevSpend}%</span>
                                                                    <span className="prevValue">${
                                                                        prevMetrics.spend !== undefined && prevMetrics.spend > 0
                                                                            ? (prevMetrics.spend).toFixed(2)
                                                                            : 0
                                                                    }</span>
                                                                </span>
                                                                :
                                                                <span className="stat"> </span>
                                                            }
                                                        </th>
                                                        :
                                                        <th><span className="left-value">$0.00</span><span
                                                            className="stat"> </span></th>
                                                    }

                                                    {channelMetrics.total_rev_120d > 1 ?
                                                        <th>
                                                            ${channelMetrics.total_rev_120d.toFixed(2)}
                                                        </th>

                                                        :
                                                        <th>-</th>
                                                    }
                                                    {channelMetrics.total_rev_120d > 1 && channelMetrics.spend > 1 ?
                                                        <th>
                                                            {('$' + ((channelMetrics.total_rev_120d - channelMetrics.spend).toFixed(2))).replace("$-", "-$")}
                                                        </th>
                                                        :
                                                        <th>-</th>
                                                    }

                                                    <th>
                                                        <span className="left-value">
                                                            {PredictedGrossProfit !== "" ? PredictedGrossProfit : "-"}
                                                        </span>

                                                        {prevPredictedGrossProfit !== "" && prevPredictedGrossProfit !== "Infinity" ?
                                                            <span
                                                                className={prevPredictedGrossProfit >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                                <span
                                                                    className="prev">{prevPredictedGrossProfit}%</span>
                                                                <span
                                                                    className="prevValue">{("$" + ((prevMetrics.total_rev_1d * predicted_k) - channelMetrics.spend).toFixed(2)).replace("$-", "-$")}</span>
                                                            </span>
                                                            :
                                                            <span className="stat"> </span>
                                                        }
                                                    </th>
                                                    <th>
                                                        <span className="left-value">
                                                            {PredictedROAS !== "" ? PredictedROAS : "-"}
                                                         </span>
                                                        {prevPredictedROAS !== "" && prevPredictedROAS !== "Infinity" && !isNaN(prevPredictedROAS) ?
                                                            <span
                                                                className={prevPredictedROAS >= 0 ? "stat stat-good" : "stat stat-bad"}>
                                                                <span className="prev">{prevPredictedROAS}%</span>
                                                                <span
                                                                    className="prevValue">{(((prevMetrics.total_rev_1d * predicted_k) / channelMetrics.spend) * 100).toFixed(2)}%</span>
                                                            </span>
                                                            :
                                                            <span className="stat"> </span>
                                                        }
                                                    </th>
                                                    {/*<th>${(item.cpi * (1 + ( ((item.total_rev_30d-item.spend)/item.spend)- 150) / ((item.total_rev_30d-item.spend)/item.spend))).toFixed(2)}</th>*/}
                                                    <th>-</th>
                                                    <th>{
                                                        predicted_installs !== undefined ?
                                                            predicted_installs > 100 ?
                                                                <span className="stat stat-good">Good</span>
                                                                :
                                                                <span className="stat stat-normal">Normal</span>
                                                            : "-"
                                                    }</th>
                                                    <th>{predictedType}</th>
                                                    <th>{predicted_k !== undefined && predicted_k > 0 ? (predicted_k).toFixed(2) : "-"}</th>
                                                    <th>
                                                        {predicted_k !== undefined && !_this.props.showDetails ?
                                                            <a href="#/"
                                                               onClick={_this.getDetails.bind(this, {
                                                                       predicted: predictedChannel,
                                                                       app_id: item.app_id,
                                                                       channel_id: channelMetrics.ad_network_id
                                                                   }
                                                               )}
                                                            >
                                                                History
                                                            </a>
                                                            : <FontAwesomeIcon icon={faSpinner} spin size="lg"/>
                                                        }
                                                    </th>
                                                </tr>
                                                {campaigns.showCampaigns ?
                                                    viewLevel2 === "1" ?
                                                        <TableCampaigns
                                                            getDetails={_this.getDetails}
                                                            viewLevel2={viewLevel2}
                                                            Countries={Countries[channelMetrics.ad_network_id + "" + item.app_id]}
                                                            top5={top5}
                                                            predicted={predictedChannel}
                                                            targetROAS={targetROAS}
                                                            campaigns={campaigns}
                                                            app_id={item.app_id}
                                                            organicK={
                                                                _this.state.appOrganic[item.app_id].organicK !== undefined ?
                                                                    _this.state.appOrganic[item.app_id].organicK
                                                            : 0 }
                                                            organic1LTV={
                                                                _this.state.appOrganic[item.app_id].organic1LTV !== undefined ?
                                                                    _this.state.appOrganic[item.app_id].organic1LTV
                                                                    : 0 }
                                                            organicInstalls={
                                                                _this.state.appOrganic[item.app_id].organicInstalls !== undefined ?
                                                                    _this.state.appOrganic[item.app_id].organicInstalls
                                                                    : 0
                                                            }
                                                            showDetails={_this.props.showDetails}
                                                            channel_id={channelMetrics.ad_network_id}
                                                            sorting={sort}
                                                            showEmptyCountries={_this.props.showEmptyCountries}
                                                        />
                                                        :
                                                        <TableCountry
                                                            getDetails={_this.getDetails}
                                                            showDetails={_this.props.showDetails}
                                                            viewLevel2={viewLevel2}
                                                            predicted={predictedChannel}
                                                            organicK={
                                                                _this.state.appOrganic[item.app_id].organicK !== undefined ?
                                                                    _this.state.appOrganic[item.app_id].organicK
                                                                    : 0 }
                                                            organic1LTV={
                                                                _this.state.appOrganic[item.app_id].organic1LTV !== undefined ?
                                                                    _this.state.appOrganic[item.app_id].organic1LTV
                                                                    : 0 }
                                                            organicInstalls={
                                                                _this.state.appOrganic[item.app_id].organicInstalls !== undefined ?
                                                                    _this.state.appOrganic[item.app_id].organicInstalls
                                                                    : 0
                                                            }
                                                            showCampaignsInCountry={_this.showCampaignsInCountry}
                                                            Countries={Countries[channelMetrics.ad_network_id + "" + item.app_id]}
                                                            top5={top5}
                                                            targetROAS={targetROAS}
                                                            campaigns={campaigns}
                                                            app_id={item.app_id}
                                                            channel_id={channelMetrics.ad_network_id}
                                                            sorting={sort}
                                                            showEmptyCountries={_this.props.showEmptyCountries}
                                                        />
                                                    : ""
                                                }

                                            </>
                                        )
                                    } else {
                                        return (
                                            <tr key={b}>
                                                <th className="channel-name">
                                                    {channelMetrics.name}
                                                    &nbsp;
                                                    <FontAwesomeIcon icon={faSpinner} spin size="lg"/>
                                                    {/*<img src={item.icon_url} alt="icon"/>*/}
                                                </th>
                                                <th>-</th>
                                                <th>-</th>
                                                <th>-</th>
                                                <th>-</th>
                                                <th>-</th>

                                                <th>-</th>
                                                <th>-</th>
                                                <th>-</th>
                                                <th>-</th>
                                                <th>-</th>
                                                <th>-</th>
                                            </tr>
                                        )
                                    }


                                })}

                            </>
                        )
                    })}
                    </tbody>
                </table>
            </>
        );
    }
}


export default Table;
