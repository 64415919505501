import React, {Component} from 'react';

import Table from '../table';
import TableChannels from '../table-channels';
import './app.css';
import moment from "moment";
import Controls from "../controls";
import Details from "../details";
import TenjinService from '../../services/tenjin-service';
import TableGeos from "../table-geos";
import Cookies from 'universal-cookie';

class App extends Component {
    state = {
        Data: [],
        token: "eyJhbGciOiJFUzUxMiJ9.eyJhY2Nlc3NfdG9rZW5faWQiOiIzOGFjNTcyMy1kMjY4LTRlZTctOTVkMS02NWRhMjFjMjc3N2UiLCJvcmdhbml6YXRpb25fdXNlcl9pZCI6Ijg4MmU3OTVhLTAwZGEtNDVkNC1hOGExLWFjMmZmZDJlODRhOSIsInVwZGF0ZWRfYXQiOjE2MTMwMjIzMTYsImlhdCI6MTYxMzAyMjMxNiwiaXNzIjoidGVuamluOnRyYWNrZXIifQ.AMTSVBlxEnEweJ5uGO6QqTkcEAHNcWXjRFJn2_59ZlYZ5oVwbfLJ99849ZsRYhAQzfUdPCyHvoFNtTQ-DJdNUYpkAfs96u5Zusu4H0kzk3WXz8LpTn0MHLibnrqHd2EP_NWlijTsgge49TIhJil5Kyvv3u6pnEmiO_LfXKSyMSsmeWIJ",
        token2: "eyJ0eXAiOiJKV1QiLCJhbGciOiJFUzUxMiJ9.eyJhY2Nlc3NfdG9rZW5faWQiOiI3NTRlZjA5Mi04MWQ1LTRhMzItYWM0MC1mYWQyYTlhNmZmNzIiLCJvcmdhbml6YXRpb25fdXNlcl9pZCI6IjNjNDM1Y2VjLWZlMTgtNDhiZC1hNzFiLWRkZWRjYTAwOGE4NCIsInVwZGF0ZWRfYXQiOjE1ODMyMjY4ODIsImlhdCI6MTU4MzIyNjg4MiwiaXNzIjoidGVuamluOnRyYWNrZXIifQ.ABrgYsyaf6Yv7-Nur75h3L2k9rvQHd-gbggyK3X_BOaYcAAaOUpw1yGNXyaU53HpL9aGhP6-E35BijGbRmkv32GOAKlGJJhRcAXfEgz9gX-duzVL2LxCCMqK5RDeRaDOEddX64wzg1HG4cizHhPUGQHak47GdUd5iY5xLsMkgdkiTSaI",
        Apps: [],
        AppsFull: [],
        Countries: [],
        searchText: "",
        dateStart: "",
        dateEnd: "",
        loading: false,
        message: "",
        error: false,
        showEmptyCountries: false,
        Channels: [],
        dataChannels: [],
        Campaigns: [],
        detailData: null,
        viewLevel1: "1",
        viewLevel2: "1",
        sorting: "spend-top",
        targetROAS: 0,
        top5: false,
        stopAsync: false,
        showDetails: false,
        showGeos: false,
        detailsData: [],
        geos: null,
        detailsK: 0,
        loadingData: false,
        history: 1,
        favorites: {},
        controller: new AbortController(),
        requests: 0,
        dateStartHistory: "",
        dateEndHistory: "",
    };
    
    reqs = 0;

    setHistory = async (data, retry = false) => {
        this.setState(() => {
                return {
                    history: Number(data),
                }
            }, () => {
            if(retry) {
                this.getDetails();
            }
        })
    };

    getDetails = async (data = null) => {
        if(data === null && this.state.detailData === null) {
            this.setState(() => {
                return {
                    loading: false,
                    error: true,
                    message: "Empty history requests"
                }
            });
            return false;
        }   else if(data === null) {
            data = this.state.detailData;
        }
        if(false && moment().diff(moment(this.state.dateEndHistory), 'days') <= 1) {
            this.setState(() => {
                return {
                    loading: false,
                    error: true,
                    message: "Set history dates"
                }
            });
            return false;
        }
        if(this.state.dateStartHistory === "" || this.state.dateEndHistory === "") {
            this.setState(() => {
                return {
                    loading: false,
                    error: true,
                    message: "Set history dates"
                }
            });
            return false;
        }
        this.setState(() => {
            return {
                loading: true,
                error: false,
                detailsData: [],
                detailData: data,
                showDetails: true,
                detailsK: data.predicted,
            }
        });
        let granularity = "daily";
        if(this.state.history === 2) {
            granularity = "weekly";
        }
        const days30 = this.state.dateStartHistory;
        const today = this.state.dateEndHistory;

        if (this.state.stopAsync) {
            console.log("stop");
            return null;
        }

        let url = "";
        let url2 = "";
        let url3 = "";
        let url4 = "";
        let url5 = "";
        if (data.app_id !== undefined && data.channel_id === undefined) {
            url = 'https://app-test-best.herokuapp.com/spend?start_date=' + days30 + "&end_date=" + today +
                '&group=app_id' +
                '&app_ids=' + data.app_id +
                '&metrics=spend,installs,cpi,total_rev_1d,total_rev_3d,total_rev_7d,total_rev_14d,total_rev_120d,total_rev_30d,tracked_installs&granularity='+granularity+
                '&per_page=999';
        } else if (data.app_id !== undefined && data.channel_id !== undefined && data.campaign_id !== undefined && data.country !== undefined) {
            url = 'https://app-test-best.herokuapp.com/spend?start_date=' + days30 + "&end_date=" + today +
                '&group_by=campaign,country' +
                '&app_ids=' + data.app_id +
                '&channel_ids=' + data.channel_id +
                '&metrics=spend,installs,cpi,total_rev_1d,total_rev_3d,total_rev_7d,total_rev_14d,total_rev_120d,total_rev_30d,tracked_installs&granularity='+granularity+
                '&per_page=1000';
            url2 = 'https://app-test-best.herokuapp.com/spend?start_date=' + days30 + "&end_date=" + today +
                '&group_by=campaign,country' +
                '&app_ids=' + data.app_id +
                '&channel_ids=' + data.channel_id +
                '&metrics=spend,installs,cpi,total_rev_1d,total_rev_3d,total_rev_7d,total_rev_14d,total_rev_120d,total_rev_30d,tracked_installs&granularity='+granularity+
                '&per_page=1000&page=2';
            url3 = 'https://app-test-best.herokuapp.com/spend?start_date=' + days30 + "&end_date=" + today +
                '&group_by=campaign,country' +
                '&app_ids=' + data.app_id +
                '&channel_ids=' + data.channel_id +
                '&metrics=spend,installs,cpi,total_rev_1d,total_rev_3d,total_rev_7d,total_rev_14d,total_rev_120d,total_rev_30d,tracked_installs&granularity='+granularity+
                '&per_page=1000&page=3';
            url4 = 'https://app-test-best.herokuapp.com/spend?start_date=' + days30 + "&end_date=" + today +
                '&group_by=campaign,country' +
                '&app_ids=' + data.app_id +
                '&channel_ids=' + data.channel_id +
                '&metrics=spend,installs,cpi,total_rev_1d,total_rev_3d,total_rev_7d,total_rev_14d,total_rev_120d,total_rev_30d,tracked_installs&granularity='+granularity+
                '&per_page=1000&page=4';
            url5 = 'https://app-test-best.herokuapp.com/spend?start_date=' + days30 + "&end_date=" + today +
                '&group_by=campaign,country' +
                '&app_ids=' + data.app_id +
                '&channel_ids=' + data.channel_id +
                '&metrics=spend,installs,cpi,total_rev_1d,total_rev_3d,total_rev_7d,total_rev_14d,total_rev_120d,total_rev_30d,tracked_installs&granularity='+granularity+
                '&per_page=1000&page=5';
        } else if (data.app_id !== undefined && data.channel_id !== undefined && data.campaign_id === undefined && data.country !== undefined) {
            url = 'https://app-test-best.herokuapp.com/spend?start_date=' + days30 + "&end_date=" + today +
                '&group_by=campaign,country' +
                '&app_ids=' + data.app_id +
                '&channel_ids=' + data.channel_id +
                '&metrics=spend,installs,cpi,total_rev_1d,total_rev_3d,total_rev_7d,total_rev_14d,total_rev_120d,total_rev_30d,tracked_installs&granularity='+granularity+
                '&per_page=999';
        } else if (data.app_id !== undefined && data.channel_id !== undefined && data.campaign_id !== undefined) {
            url = 'https://app-test-best.herokuapp.com/spend?start_date=' + days30 + "&end_date=" + today +
                '&group_by=campaign' +
                '&app_ids=' + data.app_id +
                '&channel_ids=' + data.channel_id +
                '&metrics=spend,installs,cpi,total_rev_1d,total_rev_3d,total_rev_7d,total_rev_14d,total_rev_120d,total_rev_30d,tracked_installs&granularity='+granularity+
                '&per_page=999';
        } else if (data.app_id !== undefined && data.channel_id !== undefined) {
            url = 'https://app-test-best.herokuapp.com/spend?start_date=' + days30 + "&end_date=" + today +
                '&group_by=channel,app' +
                '&app_ids=' + data.app_id +
                '&channel_ids=' + data.channel_id +
                '&metrics=spend,installs,cpi,total_rev_1d,total_rev_3d,total_rev_7d,total_rev_14d,total_rev_120d,total_rev_30d,tracked_installs&granularity='+granularity+
                '&per_page=999';
        } else if (data.app_id === undefined && data.channel_id !== undefined) {
            url = 'https://app-test-best.herokuapp.com/spend?start_date=' + days30 + "&end_date=" + today +
                '&group_by=channel' +
                '&channel_ids=' + data.channel_id +
                '&metrics=spend,installs,cpi,total_rev_1d,total_rev_3d,total_rev_7d,total_rev_14d,total_rev_120d,total_rev_30d,tracked_installs&granularity='+granularity+
                '&per_page=999';
        } else {
            this.setState(() => {
                return {
                    loading: false,
                    showDetails: false,
                }
            });
            return false;
        }
        try {
            if (this.state.stopAsync) {
                return false;
            }

            let json2 = null;
            let json3 = null;
            let json4 = null;
            let json5 = null;
            const response = await fetch(url, { signal: this.state.controller.signal,
                method: 'GET', // или 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token2,
                }
            });

            console.log("we herer");
           this.reqs++;


            const json = await response.json();

            if (url2 !== "" && url3 !== "" && url4 !== undefined && url5 !== undefined) {
                const response2 = await fetch(url2, {
                    method: 'GET', // или 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': this.state.token2,
                    }
                });
                this.reqs++;
                json2 = await response2.json();

                const response3 = await fetch(url3, {
                    method: 'GET', // или 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': this.state.token2,
                    }
                });
                this.reqs++;
                json3 = await response3.json();

                const response4 = await fetch(url4, {
                    method: 'GET', // или 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': this.state.token2,
                    }
                });
                this.reqs++;
                json4 = await response4.json();

                const response5 = await fetch(url5, {
                    method: 'GET', // или 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': this.state.token2,
                    }
                });
                this.reqs++;
                json5 = await response5.json();
            }


            this.setState((Data) => {
                let date = json.data;
                let newDetails = [];

                if (this.state.stopAsync) {
                    return null;
                }
                if (data.app_id !== undefined && data.channel_id !== undefined && data.campaign_id !== undefined && data.country !== undefined) {
                    let date2 = json2.data;
                    let date3 = json3.data;
                    let date4 = json4.data;
                    let date5 = json5.data;

                    if (this.state.stopAsync) {
                       return false;
                    }

                    for (let key in date) {
                        if (this.state.stopAsync) {
                            break;
                        }
                        if (date[key].attributes.campaign_id === data.campaign_id && date[key].attributes.country === data.country) {
                            newDetails.push(date[key]);
                        }
                    }
                    for (let key in date2) {
                        if (this.state.stopAsync) {
                            break;
                        }
                        if (date2[key] !== undefined && date2[key].attributes !== undefined && date2[key].attributes.country === data.country) {
                            newDetails.push(date2[key]);
                        }
                    }
                    for (let key in date3) {
                        if (this.state.stopAsync) {
                            break;
                        }
                        if (date3[key] !== undefined && date3[key].attributes !== undefined && date3[key].attributes.country === data.country) {
                            newDetails.push(date3[key]);
                        }
                    }
                    for (let key in date4) {
                        if (this.state.stopAsync) {
                            break;
                        }
                        if (date4[key] !== undefined && date4[key].attributes !== undefined && date4[key].attributes.country === data.country) {
                            newDetails.push(date4[key]);
                        }
                    }
                    for (let key in date5) {
                        if (this.state.stopAsync) {
                            break;
                        }
                        if (date5[key] !== undefined && date5[key].attributes !== undefined && date5[key].attributes.country === data.country) {
                            newDetails.push(date5[key]);
                        }
                    }

                    return {
                        loading: false,
                        detailsData: newDetails,
                    }
                } else if (data.app_id !== undefined && data.channel_id !== undefined && data.campaign_id === undefined && data.country !== undefined) {
                    for (let key in date) {
                        if (date[key].attributes.country === data.country) {
                            newDetails.push(date[key]);
                        }
                    }
                    return {
                        loading: false,
                        detailsData: newDetails,
                    }
                } else if (data.app_id !== undefined && data.channel_id !== undefined && data.campaign_id !== undefined) {
                    for (let key in date) {
                        if (date[key].attributes.campaign_id === data.campaign_id) {
                            date[key].attributes.campaign_name = data.campaign_name;
                            newDetails.push(date[key]);
                        }
                    }
                    return {
                        loading: false,
                        detailsData: newDetails,
                    }
                } else {
                    return {
                        loading: false,
                        detailsData: date,
                    }
                }

            }, () => {

            });

        } catch (error) {
            console.error('Ошибка:', error);
            console.error('Ошибка:', error.name);
            if (error.name === 'AbortError') { // обработать ошибку от вызова abort()

            }   else {
                this.setState(() => {
                    return {
                        error: true,
                        message: "Ошибка получения данных, возможно сервер недоступен, попробуйте позже #319",
                    }
                });
            }
        }
    };

    toggleROAS = (value) => {
        if (value.length <= 0 || value < 0) {
            value = 0;
        }
        this.setState(() => {
            return {
                targetROAS: value,
            }
        }, () => {
            const cookies = new Cookies();
            cookies.set('target-roas', value, { path: '/' });
        });
    };

    showApps = (e) => {
        let channels = this.state.Channels;
        channels[e].showApps = !channels[e].showApps;
        this.setState(() => {
            return {
                Channels: channels,
            }
        })
    };

    setSearchText = (value = "") => {
        let apps = this.state.AppsFull;
        let newApps = [];
        if (value !== "") {
            for (let i in apps) {
                if (apps[i].app_name.toLowerCase().includes(value.toLowerCase())) {
                    newApps[i] = apps[i];
                }
            }
        } else {
            newApps = apps;
        }


        this.setState(() => {
            return {
                Apps: newApps,
                searchText: value,
            }
        }, () => {
        });
    };

    sorting = (value) => {
        let sort = this.state.sorting;
        let Apps = this.state.Apps;
        let newSort = "";
        if (value === "downloads") {
            if (sort === "downloads") {
                newSort = "downloads-top"
            } else if (sort === "downloads-top") {
                newSort = "downloads"
            } else {
                newSort = "downloads"
            }
        }
        if (value === "spend") {
            if (sort === "spend") {
                newSort = "spend-top"
            } else if (sort === "spend-top") {
                newSort = "spend"
            } else {
                newSort = "spend"
            }
        }

        if (value === "cpi") {
            if (sort === "cpi") {
                newSort = "cpi-top"
            } else if (sort === "cpi-top") {
                newSort = "cpi"
            } else {
                newSort = "cpi"
            }
        }

        if (value === "revenue") {
            if (sort === "revenue") {
                newSort = "revenue-top"
            } else if (sort === "revenue-top") {
                newSort = "revenue"
            } else {
                newSort = "revenue"
            }
        }

        if (value === "gross") {
            if (sort === "gross") {
                newSort = "gross-top"
            } else if (sort === "gross-top") {
                newSort = "gross"
            } else {
                newSort = "gross"
            }
        }

        if (value === "predgross") {
            if (sort === "predgross") {
                newSort = "predgross-top"
            } else if (sort === "predgross-top") {
                newSort = "predgross"
            } else {
                newSort = "predgross"
            }
        }

        if (value === "recomcpi") {
            if (sort === "recomcpi") {
                newSort = "recomcpi-top"
            } else if (sort === "recomcpi-top") {
                newSort = "recomcpi"
            } else {
                newSort = "recomcpi"
            }
        }

        if (value === "quality") {
            if (sort === "quality") {
                newSort = "quality-top"
            } else if (sort === "quality-top") {
                newSort = "quality"
            } else {
                newSort = "quality"
            }
        }

        if (value === "type") {
            if (sort === "type") {
                newSort = "type-top"
            } else if (sort === "type-top") {
                newSort = "type"
            } else {
                newSort = "type"
            }
        }

        if (value === "pred_k") {
            if (sort === "pred_k") {
                newSort = "pred_k-top"
            } else if (sort === "pred_k-top") {
                newSort = "pred_k"
            } else {
                newSort = "pred_k"
            }
        }


        if (value === "roas") {
            if (sort === "roas") {
                newSort = "roas-top"
            } else if (sort === "roas-top") {
                newSort = "roas"
            } else {
                newSort = "roas"
            }
        }

        let newApps = this.sortingArr(Apps, newSort);
        this.setState(() => {
            return {
                sorting: newSort,
                Apps: newApps,
            }
        });
    };

    toggleShowEmpty = () => {
        this.setState(() => {
            return {
                showEmptyCountries: !this.state.showEmptyCountries
            }
        })
    };

    toggleView = (level, view) => {
        this.setState(() => {
            if (level === 1) {
                return {
                    viewLevel1: view,
                }
            } else if (level === 2) {
                return {
                    viewLevel2: view,
                }
            }

        })
    };

    showDetails = () => {
        this.setState(() => {
            return {
                showDetails: !this.state.showDetails,
            }
        })
    };

    showGeos = () => {
        this.setState(() => {
            return {
                showGeos: !this.state.showGeos,
            }
        })
    };

    toggleTop5 = () => {
        this.setState(() => {
            return {
                top5: !this.state.top5
            }
        })
    };

    sortingArr = (arr, value) => {
        if (value !== "") {
            let newArr = arr;
            switch (value) {
                case "downloads":
                    newArr = arr.sort((a, b) => (a.tracked_installs > b.tracked_installs) ? 1 : ((b.tracked_installs > a.tracked_installs) ? -1 : 0));
                    break;
                case "downloads-top":
                    newArr = arr.sort((a, b) => (a.tracked_installs < b.tracked_installs) ? 1 : ((b.tracked_installs < a.tracked_installs) ? -1 : 0));
                    break;
                case "spend":
                    newArr = arr.sort((a, b) => (a.spend > b.spend) ? 1 : ((b.spend > a.spend) ? -1 : 0));
                    break;
                case "spend-top":
                    newArr = arr.sort((a, b) => (a.spend < b.spend) ? 1 : ((b.spend < a.spend) ? -1 : 0));
                    break;
                case "revenue":
                    newArr = arr.sort((a, b) => (a.total_rev_120d > b.total_rev_120d) ? 1 : ((b.total_rev_120d > a.total_rev_120d) ? -1 : 0));
                    break;
                case "revenue-top":
                    newArr = arr.sort((a, b) => (a.total_rev_120d < b.total_rev_120d) ? 1 : ((b.total_rev_120d < a.total_rev_120d) ? -1 : 0));
                    break;

                case "gross":
                    newArr = arr.sort((a, b) => {
                        let grossA = a.total_rev_120d - a.spend;
                        let grossB = b.total_rev_120d - b.spend;
                        if (grossA > grossB) {
                            return 1;
                        } else if (grossB > grossA) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                    break;
                case "gross-top":
                    newArr = arr.sort((a, b) => {
                        let grossA = a.total_rev_120d - a.spend;
                        let grossB = b.total_rev_120d - b.spend;
                        if (grossA < grossB) {
                            return 1;
                        } else if (grossB < grossA) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                    break;
                default:
                    break;
            }
            return newArr;
        } else {
            return arr;
        }
    };

    tenjin = new TenjinService();

    componentDidMount() {
        const cookies = new Cookies();
        if(cookies.get('target-roas') !== undefined && parseInt(cookies.get('target-roas')) > 0) {
            this.setState(() => {
                return {
                    targetROAS: parseInt(cookies.get('target-roas')),
                }
            });
        }   else {
            cookies.set('target-roas', 150, { path: '/' });
            this.setState(() => {
                return {
                    targetROAS: 150,
                }
            });
        };
        this.tenjin.setToken(this.state.token);

        let favorites = JSON.parse(localStorage.getItem('favorites'));
        if(favorites === undefined || favorites === null) {
            favorites = {};
        }
        this.setState(() => {
            return {
                favorites: favorites,
            }
        });

        // this.getChannels();
        // getScroll(); // Get scroll from top
    }

    OnChangeDateHistory = (start, end) => {
        this.setState(() => {
            return {
                dateStartHistory: start,
                dateEndHistory: end,
            }
        });
    };


    OnChangeDate = (start, end) => {
        if(moment().diff(moment(end), 'days') < 0) {
            this.setState(() => {
                return {
                    loading: false,
                    error: true,
                    message: "Set date to <= today"
                }
            });
            return false;
        }

        this.state.controller.abort();
        this.componentDidMount();

        let startDate = start;
        let endDate = end;


        console.log("start date: ", startDate);
        console.log("end date: ", endDate);

        this.setState(() => {
            return {
                Countries: [],
                dateStart: startDate,
                dateEnd: endDate,
                dateStartHistory: startDate,
                dateEndHistory: endDate,
                Apps: [],
                detailsData: [],
                AppsFull: [],
                error: false,
                geos: null,
                searchText: "",
                loading: true,
                controller: new AbortController(),
                dataChannels: [],
                Campaigns: [],
                showDetails: false,
                Channels: [],
                showGeos: false,
                stopAsync: true,
                loadingData: true,
            }
        }, () => {
            this.getDataNew();
        });
    };

    setChannelMetricsPrev = async (app_id, channel_id) => {
        const offset = moment(this.state.dateEnd).diff(moment(this.state.dateStart), 'days');
        const prevEnd = moment(this.state.dateStart).subtract(1, 'days').format('YYYY-MM-DD');
        const prevStart = moment(prevEnd).subtract(offset, 'days').format('YYYY-MM-DD');

        const url = 'https://app-test-best.herokuapp.com/spend?start_date=' + prevStart + "&end_date=" + prevEnd +
            '&group=channel,app,country&metrics=spend,installs,cpi,total_rev_1d,total_rev_120d,total_rev_30d,tracked_installs' +
            '&granularity=totals-daily&app_ids=' + app_id + '&channel_ids=' + channel_id + '&per_page=999';
        try {
            if (this.state.stopAsync) {
                return false;
            }
            const response = await fetch(url, {
                method: 'GET', // или 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token2,
                },
                signal: this.state.controller.signal,
            });
            this.reqs++;
            const json = await response.json();
            let campanykey = channel_id + '' + app_id;
            let ThisChannels = this.state.Channels;
            // console.log(json);
            this.setState(() => {
                let dataChannels = this.state.dataChannels;
                if (!this.state.stopAsync && json !== undefined && json.data !== undefined && json.data[0] !== undefined && json.data.length >= 1
                    && dataChannels[campanykey] !== undefined
                    && dataChannels[campanykey].empty === false) {
                    if (ThisChannels[channel_id].prevMetrics === undefined) {
                        ThisChannels[channel_id].prevMetrics = {};
                        ThisChannels[channel_id].prevMetrics.spend = 0;
                        ThisChannels[channel_id].prevMetrics.total_rev_1d = 0;
                        ThisChannels[channel_id].prevMetrics.total_rev_30d = 0;
                        ThisChannels[channel_id].prevMetrics.total_rev_120d = 0;
                        ThisChannels[channel_id].prevMetrics.tracked_installs = 0;
                    }
                    ThisChannels[channel_id].prevMetrics.spend += json.data[0].attributes.spend;
                    ThisChannels[channel_id].prevMetrics.total_rev_1d += json.data[0].attributes.total_rev_1d;
                    ThisChannels[channel_id].prevMetrics.total_rev_30d += json.data[0].attributes.total_rev_30d;
                    ThisChannels[channel_id].prevMetrics.total_rev_120d += json.data[0].attributes.total_rev_120d;
                    ThisChannels[channel_id].prevMetrics.tracked_installs += json.data[0].attributes.tracked_installs;

                    dataChannels[campanykey].prevMetrics = json.data[0].attributes;
                } else {
                    if (dataChannels[campanykey] === undefined) {
                        dataChannels[campanykey] = {};
                    }
                    dataChannels[campanykey].empty = true;
                }
                return {
                    Channels: ThisChannels,
                    dataChannels: dataChannels,
                    error: false,
                    loading: false,
                }
            });

        } catch (error) {
            console.error('Ошибка:', error);
            console.error('Ошибка:', error.name);
            if (error.name === 'AbortError') { // обработать ошибку от вызова abort()

            }   else {
                this.setState(() => {
                    return {
                        error: true,
                        message: "Ошибка получения данных, возможно сервер недоступен, попробуйте позже #639",
                    }
                });
            }
        }
    };


    // Получаем и устанавливаем метрику на каналы
    setChannelMetrics = async (app_id, channel_id) => {
        this.setState(() => {
            return {
                loading: true,
            }
        });

        const url = 'https://app-test-best.herokuapp.com/spend?start_date=' + this.state.dateStart + "&end_date=" + this.state.dateEnd +
            '&group=channel,app,country&metrics=spend,installs,cpi,total_rev_120d,total_rev_30d,total_rev_1d,tracked_installs' +
            '&granularity=totals-daily&app_ids=' + app_id + '&channel_ids=' + channel_id + '&per_page=999';
        try {
            if (this.state.stopAsync) {
                return false;
            }
            const response = await fetch(url, {
                method: 'GET', // или 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token2,
                },
                signal: this.state.controller.signal,
            });
            this.reqs++;
            const json = await response.json();
            let campanykey = channel_id + '' + app_id;
            let ThisChannels = this.state.Channels;
            // console.log(json);
            this.setState(() => {
                let dataChannels = this.state.dataChannels;
                if (!this.state.stopAsync && json !== undefined && json.data !== undefined && json.data[0] !== undefined && json.data.length >= 1) {
                    if (dataChannels[campanykey] !== undefined) {
                        if (dataChannels[campanykey].metrics === null) {
                            if (ThisChannels[channel_id] !== undefined) {
                                if (ThisChannels[channel_id].metrics === undefined) {
                                    ThisChannels[channel_id].metrics = {};
                                    ThisChannels[channel_id].metrics.spend = 0;
                                    ThisChannels[channel_id].metrics.total_rev_1d = 0;
                                    ThisChannels[channel_id].metrics.total_rev_30d = 0;
                                    ThisChannels[channel_id].metrics.total_rev_120d = 0;
                                    ThisChannels[channel_id].metrics.tracked_installs = 0;
                                }
                                ThisChannels[channel_id].metrics.spend += json.data[0].attributes.spend;
                                ThisChannels[channel_id].metrics.total_rev_1d += json.data[0].attributes.total_rev_1d;
                                ThisChannels[channel_id].metrics.total_rev_30d += json.data[0].attributes.total_rev_30d;
                                ThisChannels[channel_id].metrics.total_rev_120d += json.data[0].attributes.total_rev_120d;
                                ThisChannels[channel_id].metrics.tracked_installs += json.data[0].attributes.tracked_installs;
                            }
                            dataChannels[campanykey].metrics = json.data[0].attributes;
                            this.setChannelMetricsPrev(app_id, channel_id);
                        } else {
                            dataChannels[campanykey].empty = true;
                        }
                    } else {
                        if (dataChannels[campanykey] === undefined) {
                            dataChannels[campanykey] = {};
                        }
                        dataChannels[campanykey].empty = true;
                    }
                } else {
                    if (dataChannels[campanykey] === undefined) {
                        dataChannels[campanykey] = {};
                    }
                    dataChannels[campanykey].empty = true;
                }

                return {
                    Channels: ThisChannels,
                    dataChannels: dataChannels,
                    error: false,
                    loading: false,
                }
            });

        } catch (error) {
            console.error('Ошибка:', error);
            console.error('Ошибка:', error.name);
            if (error.name === 'AbortError') { // обработать ошибку от вызова abort()

            }   else {
                this.setState(() => {
                    return {
                        error: true,
                        message: "Ошибка получения данных, возможно сервер недоступен, попробуйте позже #728",
                    }
                });
            }
        }
    };

    // Получаем K
    setChannelMetricsPredicted = async (app_id, channel_id) => {
        let prevStart = moment(this.state.dateStart).subtract(60, "days").format('YYYY-MM-DD');
        let prevEnd = moment(this.state.dateStart).subtract(30, "days").format('YYYY-MM-DD');

        if(moment().diff(moment(this.state.dateStart), 'days') > 30) {
            prevStart = this.state.dateStart;
            prevEnd = moment(this.state.dateStart).subtract(30, "days").format('YYYY-MM-DD');
        }   else {
            let nowSubFrom = moment().diff(moment(this.state.dateStart), 'days');
            let days30SubNowSub = 30-nowSubFrom;
            prevEnd = moment(this.state.dateStart).subtract(days30SubNowSub, "days").format('YYYY-MM-DD');
            prevStart = moment(prevEnd).subtract(30, "days").format('YYYY-MM-DD');
        }

        const url = 'https://app-test-best.herokuapp.com/spend?start_date=' + prevStart + "&end_date=" + prevEnd +
            '&group=channel,app,country&metrics=total_rev_120d,total_rev_30d,total_rev_1d,total_rev_3d,total_rev_7d,total_rev_14d,tracked_installs' +
            '&granularity=totals-daily&app_ids=' + app_id + '&channel_ids=' + channel_id + '&per_page=999';
        try {
            const response = await fetch(url, {
                method: 'GET', // или 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token2,
                },
                signal: this.state.controller.signal,
            });
            this.reqs++;
            const json = await response.json();
            let campanykey = channel_id + '' + app_id;
            // console.log(json);
            this.setState(() => {
                let dataChannels = this.state.dataChannels;
                if (this.state.stopAsync !== true && json !== undefined && json.data !== undefined && json.data[0] !== undefined && json.data.length >= 1) {

                    if (dataChannels[campanykey] !== undefined) {
                        let item = json.data[0].attributes;
                        dataChannels[campanykey].predicted = item;
                    }
                }
                return {
                    dataChannels: dataChannels,
                }
            });

        } catch (error) {
            console.error('Ошибка:', error);
            console.error('Ошибка:', error.name);
            if (error.name === 'AbortError') { // обработать ошибку от вызова abort()

            }   else {
                this.setState(() => {
                    return {
                        error: true,
                        message: "Ошибка получения данных, возможно сервер недоступен, попробуйте позже #787",
                    }
                });
            }
        }
    };


    // Получаем все каналы, записываем по ID
    getChannels = async () => {
        this.setState(() => {
            return {
                loading: true,
            }
        });

        const url = 'https://app-test-best.herokuapp.com/channels?per_page=999';
        try {
            const response = await fetch(url, {
                method: 'GET', // или 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token2,
                },
                signal: this.state.controller.signal,
            });
            this.reqs++;
            const json = await response.json();
            this.setState(() => {
                let channels = json.data;
                let newChannels = [];

                for (let key in channels) {
                    let channel = channels[key];
                    switch (channel.attributes.name) {
                        case "AdColony":
                            channel.attributes.icon = "adcolony";
                            break;
                        case "Apple Search Ads":
                            channel.attributes.icon = "apple";
                            break;
                        case "Applovin":
                            channel.attributes.icon = "applovin";
                            break;
                        case "Facebook":
                            channel.attributes.icon = "facebook";
                            break;
                        case "Google AdMob":
                            channel.attributes.icon = "admob";
                            break;
                        case "Google Ads":
                            channel.attributes.icon = "google";
                            break;
                        case "Google Search":
                            channel.attributes.icon = "google-search";
                            break;
                        case "ironSource-Publisher":
                            channel.attributes.icon = "iconsource";
                            break;
                        case "Organic":
                            channel.attributes.icon = "organic";
                            break;
                        case "Unity Ads":
                            channel.attributes.icon = "unity";
                            break;
                        case "Vungle":
                            channel.attributes.icon = "vungle";
                            break;
                        default:
                            channel.attributes.icon = "";
                            break;
                    }
                    channel.empty = true;
                    channel.showApps = false;
                    newChannels[channels[key].id] = channel;
                }
                return {
                    Channels: newChannels,
                    error: false,
                    loading: false,
                }
            });
        } catch (error) {
            console.error('Ошибка:', error);
            console.error('Ошибка:', error.name);
            if (error.name === 'AbortError') { // обработать ошибку от вызова abort()

            }   else {
                this.setState(() => {
                    return {
                        error: true,
                        message: "Ошибка получения данных, возможно сервер недоступен, попробуйте позже #876",
                    }
                });
            }
        }


    };
    changeShowCountries = async (app_id, channel_id, campaign_id) => {
        let thisCampaigns = this.state.Campaigns;
        let campanykey = channel_id + '' + app_id;
        thisCampaigns[campanykey][campaign_id].showCounties = !thisCampaigns[campanykey][campaign_id].showCounties;
        this.setState(() => {
            return {
                Campaigns: thisCampaigns,
            }
        });
    };

    setFavorite = (app_id, channel_id = 0, campaign_id = 0, country = "") => {
        if(!app_id) {
            return false;
        }
        let favorites = this.state.favorites;
        if(app_id && channel_id === 0) {
            if(favorites.apps !== undefined) {
                if(!favorites.apps.includes(app_id)) {
                    favorites.apps.push(app_id);
                }   else {
                    let i = favorites.apps.indexOf(app_id);
                    if(i >= 0) {
                        favorites.apps.splice(i,1);
                    }
                }
            }   else {
                favorites.apps = [];
                favorites.apps.push(app_id);
            }
        }
        this.setState(() => {
            return {
                favorites: favorites,
            }
        }, () => {
            localStorage.setItem('favorites', JSON.stringify(favorites));
        });
    }


    showCampaignsInCountry = async (campanykey, country = "") => {
        if (country === "") {
            return false;
        }
        let thisCountries = this.state.Countries;
        if (thisCountries[campanykey] !== undefined && thisCountries[campanykey][country] !== undefined) {
            thisCountries[campanykey][country].showCampaigns = !thisCountries[campanykey][country].showCampaigns;
            this.setState(() => {
                return {
                    Countries: thisCountries,
                }
            });
        }

    };

    setCountriesPredicted = async (app_id, channel_id) => {
        let prevStart = moment(this.state.dateStart).subtract(60, "days").format('YYYY-MM-DD');
        let prevEnd = moment(this.state.dateStart).subtract(30, "days").format('YYYY-MM-DD');

        if(moment().diff(moment(this.state.dateStart), 'days') > 30) {
            prevStart = this.state.dateStart;
            prevEnd = moment(this.state.dateStart).subtract(30, "days").format('YYYY-MM-DD');
        }   else {
            let nowSubFrom = moment().diff(moment(this.state.dateStart), 'days');
            let days30SubNowSub = 30-nowSubFrom;
            prevEnd = moment(this.state.dateStart).subtract(days30SubNowSub, "days").format('YYYY-MM-DD');
            prevStart = moment(prevEnd).subtract(30, "days").format('YYYY-MM-DD');
        }

        let thisCampaigns = this.state.Campaigns;
        let campanykey = channel_id + '' + app_id;

        // В ином случае загружаем данные &&  Ставим статус - загружается

        const url = 'https://app-test-best.herokuapp.com/spend?start_date=' + prevStart + "&end_date=" + prevEnd +
            '&group_by=campaign,country' +
            '&metrics=total_rev_1d,total_rev_3d,total_rev_7d,total_rev_14d,total_rev_30d,tracked_installs' +
            '&per_page=999' +
            '&granularity=totals-daily' +
            '&app_ids=' + app_id + '&channel_ids=' + channel_id;

        try {
            const response = await fetch(url, {
                method: 'GET', // или 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token2,
                },
                signal: this.state.controller.signal,
            });
            this.reqs++;
            const json = await response.json();
            let thisCountries = this.state.Countries;


            // console.log('Успех:', JSON.stringify(json));
            this.setState(() => {
                let campaigns = json.data;

                for (let key in campaigns) {
                    if (this.state.stopAsync === true) {
                        break;
                    }
                    let campaignId = campaigns[key].attributes.campaign_id;
                    if (thisCampaigns[campanykey][campaignId] === undefined) {
                        thisCampaigns[campanykey][campaignId] = campaigns[key];
                    }
                    if (thisCampaigns[campanykey][campaignId].countries === undefined) {
                        thisCampaigns[campanykey][campaignId].countries = [];
                    }

                    if (thisCampaigns[campanykey][campaignId].countries !== undefined && thisCampaigns[campanykey][campaignId].countries[campaigns[key].attributes.country] !== undefined) {

                        thisCampaigns[campanykey][campaignId].countries[campaigns[key].attributes.country].predicted = campaigns[key].attributes;

                        if ( thisCountries[campanykey] !== undefined && thisCountries[campanykey][campaigns[key].attributes.country] !== undefined) {
                            if (thisCountries[campanykey][campaigns[key].attributes.country].predicted === undefined) {
                                thisCountries[campanykey][campaigns[key].attributes.country].predicted = {};
                            }

                            if (thisCountries[campanykey][campaigns[key].attributes.country].predicted.tracked_installs === null) {
                                thisCountries[campanykey][campaigns[key].attributes.country].predicted.tracked_installs = 0;
                            }
                            if (thisCountries[campanykey][campaigns[key].attributes.country].predicted.total_rev_1d === null) {
                                thisCountries[campanykey][campaigns[key].attributes.country].predicted.total_rev_1d = 0;
                            }
                            if (thisCountries[campanykey][campaigns[key].attributes.country].predicted.total_rev_3d === null) {
                                thisCountries[campanykey][campaigns[key].attributes.country].predicted.total_rev_3d = 0;
                            }
                            if (thisCountries[campanykey][campaigns[key].attributes.country].predicted.total_rev_7d === null) {
                                thisCountries[campanykey][campaigns[key].attributes.country].predicted.total_rev_7d = 0;
                            }
                            if (thisCountries[campanykey][campaigns[key].attributes.country].predicted.total_rev_14d === null) {
                                thisCountries[campanykey][campaigns[key].attributes.country].predicted.total_rev_14d = 0;
                            }
                            if (thisCountries[campanykey][campaigns[key].attributes.country].predicted.total_rev_30d === null) {
                                thisCountries[campanykey][campaigns[key].attributes.country].predicted.total_rev_30d = 0;
                            }


                            thisCountries[campanykey][campaigns[key].attributes.country].predicted.tracked_installs += campaigns[key].attributes.tracked_installs !== null ? campaigns[key].attributes.tracked_installs : 0;
                            thisCountries[campanykey][campaigns[key].attributes.country].predicted.total_rev_1d += campaigns[key].attributes.total_rev_1d !== null ? campaigns[key].attributes.total_rev_1d : 0;
                            thisCountries[campanykey][campaigns[key].attributes.country].predicted.total_rev_3d += campaigns[key].attributes.total_rev_3d !== null ? campaigns[key].attributes.total_rev_3d : 0;
                            thisCountries[campanykey][campaigns[key].attributes.country].predicted.total_rev_7d += campaigns[key].attributes.total_rev_7d !== null ? campaigns[key].attributes.total_rev_7d : 0;
                            thisCountries[campanykey][campaigns[key].attributes.country].predicted.total_rev_14d += campaigns[key].attributes.total_rev_14d !== null ? campaigns[key].attributes.total_rev_14d : 0;
                            thisCountries[campanykey][campaigns[key].attributes.country].predicted.total_rev_30d += campaigns[key].attributes.total_rev_30d !== null ? campaigns[key].attributes.total_rev_30d : 0;
                        }


                        thisCampaigns[campanykey][campaignId].countries[campaigns[key].attributes.country].loaded = false;
                        // get and set countries predicted
                    }
                }

                return {
                    Campaigns: thisCampaigns,
                    Countries: thisCountries,
                }

            }, () => {
                // console.log(this.state.Campaigns);
            });

        } catch (error) {
            console.error('Ошибка:', error);
            console.error('Ошибка:', error.name);
            if (error.name === 'AbortError') { // обработать ошибку от вызова abort()

            }   else {
                this.setState(() => {
                    return {
                        error: true,
                        message: "Ошибка получения данных, возможно сервер недоступен, попробуйте позже #1027",
                    }
                });
            }
        }
    }

    // Получаем предыдущую метрику по компаниям и странам и записываем её
    setPrevCampaignsData = async (app_id, channel_id) => {
        let thisCampaigns = this.state.Campaigns;
        let campanykey = channel_id + '' + app_id;

        const offset = moment(this.state.dateEnd).diff(moment(this.state.dateStart), 'days');
        const prevEnd = moment(this.state.dateStart).subtract(1, 'days').format('YYYY-MM-DD');
        const prevStart = moment(prevEnd).subtract(offset, 'days').format('YYYY-MM-DD');

        const url = 'https://app-test-best.herokuapp.com/spend?start_date=' + prevStart + "&end_date=" + prevEnd +
            '&group_by=campaign,country' +
            '&metrics=spend,installs,cpi,total_rev_120d,total_rev_30d,total_rev_1d,tracked_installs' +
            '&per_page=999' +
            '&granularity=totals-daily' +
            '&app_ids=' + app_id + '&channel_ids=' + channel_id;

        try {
            const response = await fetch(url, {
                method: 'GET', // или 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token2,
                },
                signal: this.state.controller.signal,
            });
            this.reqs++;
            const json = await response.json();
            let thisCountries = this.state.Countries;


            // console.log('Успех:', JSON.stringify(json));
            this.setState(() => {
                let campaigns = json.data;

                for (let key in campaigns) {
                    if (this.state.stopAsync === true) {
                        break;
                    }
                    let campaignId = campaigns[key].attributes.campaign_id;
                    if (thisCampaigns[campanykey][campaignId] === undefined) {
                        thisCampaigns[campanykey][campaignId] = {};
                    }
                    if (thisCampaigns[campanykey][campaignId].countries !== undefined && thisCampaigns[campanykey][campaignId].countries[campaigns[key].attributes.country] !== undefined) {
                        if (thisCampaigns[campanykey][campaignId].countries[campaigns[key].attributes.country].prevMetrics === undefined) {
                            thisCampaigns[campanykey][campaignId].countries[campaigns[key].attributes.country].prevMetrics = campaigns[key].attributes;
                            // get and set countries predicted
                        }
                    }

                    if (thisCampaigns[campanykey][campaignId].prevMetrics === undefined) {
                        thisCampaigns[campanykey][campaignId].prevMetrics = [];
                        thisCampaigns[campanykey][campaignId].prevMetrics['spend'] = 0;
                        thisCampaigns[campanykey][campaignId].prevMetrics['installs'] = 0;
                        thisCampaigns[campanykey][campaignId].prevMetrics['tracked_installs'] = 0;
                        thisCampaigns[campanykey][campaignId].prevMetrics['total_rev_1d'] = 0;
                        thisCampaigns[campanykey][campaignId].prevMetrics['total_rev_120d'] = 0;
                        thisCampaigns[campanykey][campaignId].prevMetrics['total_rev_30d'] = 0;
                    }

                    if (thisCountries[campanykey] !== undefined) {
                        if (thisCountries[campanykey][campaigns[key].attributes.country] === undefined) {
                            thisCountries[campanykey][campaigns[key].attributes.country] = [];
                        }
                        if (thisCountries[campanykey][campaigns[key].attributes.country].prevMetrics === undefined) {
                            thisCountries[campanykey][campaigns[key].attributes.country].prevMetrics = [];
                            thisCountries[campanykey][campaigns[key].attributes.country].prevMetrics['spend'] = 0;
                            thisCountries[campanykey][campaigns[key].attributes.country].prevMetrics['installs'] = 0;
                            thisCountries[campanykey][campaigns[key].attributes.country].prevMetrics['tracked_installs'] = 0;
                            thisCountries[campanykey][campaigns[key].attributes.country].prevMetrics['total_rev_120d'] = 0;
                            thisCountries[campanykey][campaigns[key].attributes.country].prevMetrics['total_rev_1d'] = 0;
                            thisCountries[campanykey][campaigns[key].attributes.country].prevMetrics['total_rev_30d'] = 0;
                            thisCountries[campanykey][campaigns[key].attributes.country].prevMetrics['cpi'] = 0;
                        }

                        thisCountries[campanykey][campaigns[key].attributes.country].prevMetrics['spend'] += campaigns[key].attributes.spend !== null ? campaigns[key].attributes.spend : 0;
                        thisCountries[campanykey][campaigns[key].attributes.country].prevMetrics['installs'] += campaigns[key].attributes.installs !== null ? campaigns[key].attributes.installs : 0;
                        thisCountries[campanykey][campaigns[key].attributes.country].prevMetrics['tracked_installs'] += campaigns[key].attributes.tracked_installs !== null ? campaigns[key].attributes.tracked_installs : 0;
                        thisCountries[campanykey][campaigns[key].attributes.country].prevMetrics['total_rev_120d'] += campaigns[key].attributes.total_rev_120d !== null ? campaigns[key].attributes.total_rev_120d : 0;
                        thisCountries[campanykey][campaigns[key].attributes.country].prevMetrics['total_rev_1d'] += campaigns[key].attributes.total_rev_1d !== null ? campaigns[key].attributes.total_rev_1d : 0;
                        thisCountries[campanykey][campaigns[key].attributes.country].prevMetrics['total_rev_30d'] += campaigns[key].attributes.total_rev_30d !== null ? campaigns[key].attributes.total_rev_30d : 0;
                        thisCountries[campanykey][campaigns[key].attributes.country].prevMetrics['cpi'] += campaigns[key].attributes.cpi !== null ? campaigns[key].attributes.cpi : 0;

                    }


                    thisCampaigns[campanykey][campaignId].prevMetrics['spend'] += campaigns[key].attributes.spend !== null ? campaigns[key].attributes.spend : 0;
                    thisCampaigns[campanykey][campaignId].prevMetrics['total_rev_1d'] += campaigns[key].attributes.total_rev_1d !== null ? campaigns[key].attributes.total_rev_1d : 0;
                    thisCampaigns[campanykey][campaignId].prevMetrics['total_rev_30d'] += campaigns[key].attributes.total_rev_30d !== null ? campaigns[key].attributes.total_rev_30d : 0;
                    thisCampaigns[campanykey][campaignId].prevMetrics['total_rev_120d'] += campaigns[key].attributes.total_rev_120d !== null ? campaigns[key].attributes.total_rev_120d : 0;
                    thisCampaigns[campanykey][campaignId].prevMetrics['installs'] += campaigns[key].attributes.installs !== null ? campaigns[key].attributes.installs : 0;
                    thisCampaigns[campanykey][campaignId].prevMetrics['tracked_installs'] += campaigns[key].attributes.tracked_installs !== null ? campaigns[key].attributes.tracked_installs : 0;

                }

                return {
                    Campaigns: thisCampaigns,
                    Countries: thisCountries,
                    error: false,
                    loading: false,
                }
            }, () => {

            });

        } catch (error) {
            console.error('Ошибка:', error);
            console.error('Ошибка:', error.name);
            if (error.name === 'AbortError') { // обработать ошибку от вызова abort()

            }   else {
                this.setState(() => {
                    return {
                        error: true,
                        message: "Ошибка получения данных, возможно сервер недоступен, попробуйте позже #1145",
                    }
                });
            }
        }
    };

    setCampaignsDataPredicted = async (app_id, channel_id) => {
        let thisCampaigns = this.state.Campaigns;
        let campanykey = channel_id + '' + app_id;

        let prevStart = moment(this.state.dateStart).subtract(60, "days").format('YYYY-MM-DD');
        let prevEnd = moment(this.state.dateStart).subtract(30, "days").format('YYYY-MM-DD');

        if(moment().diff(moment(this.state.dateStart), 'days') > 30) {
            prevStart = this.state.dateStart;
            prevEnd = moment(this.state.dateStart).subtract(30, "days").format('YYYY-MM-DD');
        }   else {
            let nowSubFrom = moment().diff(moment(this.state.dateStart), 'days');
            let days30SubNowSub = 30-nowSubFrom;
            prevEnd = moment(this.state.dateStart).subtract(days30SubNowSub, "days").format('YYYY-MM-DD');
            prevStart = moment(prevEnd).subtract(30, "days").format('YYYY-MM-DD');
        }

        const url = 'https://app-test-best.herokuapp.com/spend?start_date=' + prevStart + "&end_date=" + prevEnd +
            '&group_by=campaign' +
            '&metrics=total_rev_120d,total_rev_30d,total_rev_1d,total_rev_3d,total_rev_7d,total_rev_14d,tracked_installs' +
            '&per_page=999' +
            '&granularity=totals-daily' +
            '&app_ids=' + app_id + '&channel_ids=' + channel_id;

        try {
            const response = await fetch(url, {
                method: 'GET', // или 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token2,
                },
                signal: this.state.controller.signal,
            });
            this.reqs++;
            const json = await response.json();

            this.setState(() => {
                let campaigns = json.data;

                for (let key in campaigns) {
                    if (this.state.stopAsync === true) {
                        break;
                    }
                    let campaignId = campaigns[key].attributes.campaign_id;

                    if (thisCampaigns[campanykey][campaignId] !== undefined) {
                        thisCampaigns[campanykey][campaignId].predicted = campaigns[key].attributes;
                    }
                }

                return {
                    Campaigns: thisCampaigns,
                }
            });

        } catch (error) {
            console.error('Ошибка:', error);
            console.error('Ошибка:', error.name);
            if (error.name === 'AbortError') { // обработать ошибку от вызова abort()

            }   else {
                this.setState(() => {
                    return {
                        error: true,
                        message: "Ошибка получения данных, возможно сервер недоступен, попробуйте позже #1214",
                    }
                });
            }
        }
    };

    getCountries = async (good = 1) => {
        let _this = this;
        let Apps = this.state.AppsFull;
        let time = 60000;
        if (this.state.geos !== null && Object.keys(this.state.geos).length > 0) {
            time = 10000;
        }


        this.setState(() => {
            return {
                loading: true,
            }
        });

        if (this.state.geos === null || Object.keys(this.state.geos).length <= 0) {
            if (this.state.stopAsync) {
                return null;
            }
            for (let i in Apps) {
                if (this.state.stopAsync) {
                    break;
                }
                let channels = Apps[i].channels;
                for (let c in channels) {
                    this.setCampaignsData(i, channels[c], false);
                }
            }
            ;
        }

        if (this.state.stopAsync) {
            return null;
        }
        setTimeout(function () {
            let geos = [];
            let campaigns = _this.state.Campaigns;
            for (let key in campaigns) {
                if (_this.state.stopAsync) {
                    break;
                }
                if (campaigns[key].loaded !== undefined && Object.keys(campaigns[key]).length > 3) {
                    let thisCampaigns = campaigns[key];
                    for (let k in thisCampaigns) {
                        if (_this.state.stopAsync) {
                            break;
                        }
                        if (thisCampaigns[k].countries !== undefined && Object.keys(thisCampaigns[k].countries).length > 0) {
                            let countries = thisCampaigns[k].countries;
                            for (let c in countries) {
                                if (_this.state.stopAsync) {
                                    break;
                                }
                                if (countries[c].spend !== null && countries[c].spend > 20) {
                                    geos.push(countries[c]);
                                }
                            }
                        }
                    }
                }
            }
            _this.setState(() => {
                return {
                    showGeos: true,
                    geos: geos,
                }
            });
        }, time)

    };


    // Получаем метрику по компаниям и странам и записываем её
    setCampaignsData = async (app_id, channel_id, show = true) => {

        let thisCampaigns = this.state.Campaigns;
        let dataChannels = this.state.dataChannels;
        let campanykey = channel_id + '' + app_id;

        // Если уже загружено, просто показываем/скрываем
        if (thisCampaigns[campanykey] !== undefined && thisCampaigns[campanykey].showCampaigns !== undefined && dataChannels[campanykey].loaded === true) {
            thisCampaigns[campanykey].showCampaigns = !thisCampaigns[campanykey].showCampaigns;
            this.setState(() => {
                return {
                    Campaigns: thisCampaigns,
                }
            });
            return;
        }

        if (thisCampaigns[campanykey] === undefined) {
            thisCampaigns[campanykey] = {};
        }
        thisCampaigns[campanykey].loading = true;
        // В ином случае загружаем данные &&  Ставим статус - загружается
        this.setState(() => {
            return {
                loading: true,
                Campaigns: thisCampaigns,
            }
        });
        const url = 'https://app-test-best.herokuapp.com/spend?start_date=' + this.state.dateStart + "&end_date=" + this.state.dateEnd +
            '&group_by=campaign,country' +
            '&metrics=spend,installs,cpi,total_rev_120d,total_rev_30d,total_rev_1d,tracked_installs' +
            '&per_page=999' +
            '&granularity=totals-daily' +
            '&app_ids=' + app_id + '&channel_ids=' + channel_id;

        try {
            const response = await fetch(url, {
                method: 'GET', // или 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token2,
                },
                signal: this.state.controller.signal,
            });
            this.reqs++;
            const json = await response.json();
            let thisCountries = this.state.Countries;


            // console.log('Успех:', JSON.stringify(json));
            this.setState(() => {
                let campaigns = json.data;

                for (let key in campaigns) {
                    if (this.state.stopAsync === true) {
                        break;
                    }
                    let campaignId = campaigns[key].attributes.campaign_id;
                    if (thisCampaigns[campanykey][campaignId] === undefined) {
                        thisCampaigns[campanykey][campaignId] = campaigns[key];
                    }
                    if (thisCampaigns[campanykey][campaignId].countries === undefined) {
                        thisCampaigns[campanykey][campaignId].countries = [];
                    }

                    if (thisCampaigns[campanykey][campaignId].countries !== undefined && thisCampaigns[campanykey][campaignId].countries[campaigns[key].attributes.country] === undefined) {
                        thisCampaigns[campanykey][campaignId].countries[campaigns[key].attributes.country] = campaigns[key].attributes;
                        thisCampaigns[campanykey][campaignId].countries[campaigns[key].attributes.country].loaded = true;
                        // get and set countries predicted
                        if (thisCountries[campanykey] === undefined) {
                            thisCountries[campanykey] = [];
                        }
                        if (thisCountries[campanykey][campaigns[key].attributes.country] === undefined) {
                            thisCountries[campanykey][campaigns[key].attributes.country] = [];
                            thisCountries[campanykey][campaigns[key].attributes.country].showCampaigns = false;
                        }
                        if (thisCountries[campanykey][campaigns[key].attributes.country].campaigns === undefined) {
                            thisCountries[campanykey][campaigns[key].attributes.country].campaigns = [];
                        }
                        if (thisCountries[campanykey][campaigns[key].attributes.country] !== undefined &&
                            Array.isArray(thisCountries[campanykey][campaigns[key].attributes.country].campaigns)) {

                            thisCountries[campanykey][campaigns[key].attributes.country].campaigns.push(campaigns[key]);
                        }
                    }


                    if (thisCampaigns[campanykey][campaignId].metrics === undefined) {
                        thisCampaigns[campanykey][campaignId].metrics = [];
                        thisCampaigns[campanykey][campaignId].metrics['spend'] = 0;
                        thisCampaigns[campanykey][campaignId].metrics['installs'] = 0;
                        thisCampaigns[campanykey][campaignId].metrics['tracked_installs'] = 0;
                        thisCampaigns[campanykey][campaignId].metrics['total_rev_120d'] = 0;
                        thisCampaigns[campanykey][campaignId].metrics['total_rev_1d'] = 0;
                        thisCampaigns[campanykey][campaignId].metrics['total_rev_30d'] = 0;
                    }

                    if (thisCountries[campanykey][campaigns[key].attributes.country].metrics === undefined) {
                        thisCountries[campanykey][campaigns[key].attributes.country].metrics = [];
                        thisCountries[campanykey][campaigns[key].attributes.country].metrics['spend'] = 0;
                        thisCountries[campanykey][campaigns[key].attributes.country].metrics['installs'] = 0;
                        thisCountries[campanykey][campaigns[key].attributes.country].metrics['tracked_installs'] = 0;
                        thisCountries[campanykey][campaigns[key].attributes.country].metrics['total_rev_120d'] = 0;
                        thisCountries[campanykey][campaigns[key].attributes.country].metrics['total_rev_1d'] = 0;
                        thisCountries[campanykey][campaigns[key].attributes.country].metrics['total_rev_30d'] = 0;
                        thisCountries[campanykey][campaigns[key].attributes.country].metrics['cpi'] = 0;
                    }


                    thisCountries[campanykey][campaigns[key].attributes.country].metrics['spend'] += campaigns[key].attributes.spend !== null ? campaigns[key].attributes.spend : 0;
                    thisCountries[campanykey][campaigns[key].attributes.country].metrics['installs'] += campaigns[key].attributes.installs !== null ? campaigns[key].attributes.installs : 0;
                    thisCountries[campanykey][campaigns[key].attributes.country].metrics['tracked_installs'] += campaigns[key].attributes.tracked_installs !== null ? campaigns[key].attributes.tracked_installs : 0;
                    thisCountries[campanykey][campaigns[key].attributes.country].metrics['total_rev_120d'] += campaigns[key].attributes.total_rev_120d !== null ? campaigns[key].attributes.total_rev_120d : 0;
                    thisCountries[campanykey][campaigns[key].attributes.country].metrics['total_rev_1d'] += campaigns[key].attributes.total_rev_1d !== null ? campaigns[key].attributes.total_rev_1d : 0;
                    thisCountries[campanykey][campaigns[key].attributes.country].metrics['total_rev_30d'] += campaigns[key].attributes.total_rev_30d !== null ? campaigns[key].attributes.total_rev_30d : 0;
                    thisCountries[campanykey][campaigns[key].attributes.country].metrics['cpi'] += campaigns[key].attributes.cpi !== null ? campaigns[key].attributes.cpi : 0;

                    if (thisCampaigns[campanykey][campaignId].loaded === undefined) {
                        thisCampaigns[campanykey][campaignId].loaded = false;
                    }

                    thisCampaigns[campanykey][campaignId].metrics['spend'] += campaigns[key].attributes.spend !== null ? campaigns[key].attributes.spend : 0;
                    thisCampaigns[campanykey][campaignId].metrics['installs'] += campaigns[key].attributes.installs !== null ? campaigns[key].attributes.installs : 0;
                    thisCampaigns[campanykey][campaignId].metrics['tracked_installs'] += campaigns[key].attributes.tracked_installs !== null ? campaigns[key].attributes.tracked_installs : 0;
                    thisCampaigns[campanykey][campaignId].metrics['total_rev_120d'] += campaigns[key].attributes.total_rev_120d !== null ? campaigns[key].attributes.total_rev_120d : 0;
                    thisCampaigns[campanykey][campaignId].metrics['total_rev_30d'] += campaigns[key].attributes.total_rev_30d !== null ? campaigns[key].attributes.total_rev_30d : 0;
                    thisCampaigns[campanykey][campaignId].metrics['total_rev_1d'] += campaigns[key].attributes.total_rev_1d !== null ? campaigns[key].attributes.total_rev_1d : 0;


                    thisCampaigns[campanykey][campaignId].showCounties = false;

                    if (show) {
                        thisCampaigns[campanykey].showCampaigns = true;
                    } else {
                        thisCampaigns[campanykey].showCampaigns = false;
                    }
                    dataChannels[campanykey].loaded = true;
                    thisCampaigns[campanykey].loaded = true;


                    // убираем статус - загружается
                    thisCampaigns[campanykey].loading = false;
                }

                return {
                    Countries: thisCountries,
                    Campaigns: thisCampaigns,
                    error: false,
                }

            }, () => {
                this.setCountriesPredicted(app_id, channel_id);
                this.setPrevCampaignsData(app_id, channel_id);
                this.setCampaignsDataPredicted(app_id, channel_id);
            });

        } catch (error) {
            console.error('Ошибка:', error);
            console.error('Ошибка:', error.name);
            if (error.name === 'AbortError') { // обработать ошибку от вызова abort()

            }   else {
                this.setState(() => {
                    return {
                        error: true,
                        message: "Ошибка получения данных, возможно сервер недоступен, попробуйте позже #1457",
                    }
                });
            }
        }
    };

    getCampaignsData = async (app_id, channel_id) => {

    };

    // Получаем все компании
    getCampaigns = async (index, show = true) => {
        let thisApps = this.state.Apps;

        let app_id = thisApps[index].app_id;
        if (thisApps[index].channels !== null) {
            thisApps[index].showChannels = !thisApps[index].showChannels;
            this.setState(() => {
                return {
                    Apps: thisApps,
                }
            });
            return;
        }


        this.setState(() => {
            return {
                loading: true,
            }
        });
        let url = 'https://app-test-best.herokuapp.com/campaigns?per_page=999';
        if (app_id) {
            url += '&app_id=' + app_id;
        }


        try {
            const response = await fetch(url, {
                method: 'GET', // или 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token2,
                },
                signal: this.state.controller.signal,
            });
            this.reqs++;
            const json = await response.json();
            // console.log('Успех:', JSON.stringify(json));
            this.setState(() => {
                let campaigns = json.data;
                let thisCampaigns = this.state.Campaigns;
                let dataChannels = this.state.dataChannels;
                let ThisChannels = this.state.Channels;
                let apps = this.state.Apps;
                if (apps[index] === undefined) {
                    return false;
                }
                apps[index]['channels'] = [];
                const channels = [];

                // Записываем App - его компании, Channels - его Apps
                for (let key in campaigns) {
                    if (this.state.stopAsync === true) {
                        break;
                    }
                    if (campaigns[key].attributes !== undefined) {
                        let channelId = campaigns[key].attributes.channel_id;
                        let campanykey = channelId + '' + app_id;
                        if (!channels.includes(channelId)) {
                            channels.push(channelId);
                        }

                        if (ThisChannels[channelId] !== undefined) {
                            if (ThisChannels[channelId].apps === undefined) {
                                ThisChannels[channelId].apps = [];
                            }
                            if (!ThisChannels[channelId].apps.includes(app_id)) {
                                ThisChannels[channelId].apps.push(app_id);
                                ThisChannels[channelId].empty = false;
                            }
                        }

                        campaigns[key].name = campaigns[key].attributes.name;
                        if (thisCampaigns[campanykey] === undefined) {
                            thisCampaigns[campanykey] = [];
                            thisCampaigns[campanykey][campaigns[key].id] = campaigns[key];
                        } else {
                            thisCampaigns[campanykey][campaigns[key].id] = campaigns[key];
                        }
                        thisCampaigns[campanykey].showCounties = false;
                        thisCampaigns[campanykey].loaded = false;
                        // Получаем по каналам метрику и храним в отдельном массиве по ключю channel_id+app_id
                        if (dataChannels[campanykey] === undefined) {
                            dataChannels[campanykey] = {};
                            if (dataChannels[campanykey].metrics === undefined) {
                                dataChannels[campanykey].metrics = null;
                                dataChannels[campanykey].empty = false;
                                dataChannels[campanykey].showCampaigns = true;
                                this.setChannelMetrics(app_id, channelId);
                                this.setChannelMetricsPredicted(app_id, channelId);
                            }
                        }


                    }
                }

                apps[index]['channels'] = channels;
                if (show) {
                    apps[index].showChannels = true;
                }


                return {
                    Apps: apps,
                    Channels: ThisChannels,
                    Campaigns: thisCampaigns,
                    dataChannels: dataChannels,
                    error: false,
                    loading: false,
                }

            }, () => {
            });

        } catch (error) {
            console.error('Ошибка:', error);
            console.error('Ошибка:', error.name);
            if (error.name === 'AbortError') { // обработать ошибку от вызова abort()

            }   else {
                this.setState(() => {
                    return {
                        error: true,
                        message: "Ошибка получения данных, возможно сервер недоступен, попробуйте позже #1591",
                    }
                });
            }
        }
    };

    getAppDataPredicted = async (key, app_id) => {
        let prevStart = moment(this.state.dateStart).subtract(60, "days").format('YYYY-MM-DD');
        let prevEnd = moment(this.state.dateStart).subtract(30, "days").format('YYYY-MM-DD');



        const url = 'https://app-test-best.herokuapp.com/spend?start_date=' + prevStart + "&end_date=" + prevEnd +
            '&group_by=app' +
            '&app_ids=' + app_id +
            '&metrics=total_rev_1d,total_rev_30d,tracked_installs&granularity=totals-daily' +
            '&per_page=999';
        try {
            const response = await fetch(url, {
                method: 'GET', // или 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token2,
                },
                signal: this.state.controller.signal,
            });
            this.reqs++;
            const Apps = this.state.AppsFull;
            const json = await response.json();
            if (json.data !== undefined && json.data[0] !== undefined && json.data[0].attributes !== undefined) {
                let item = json.data[0].attributes;
                for (let key in Apps) {
                    if (this.state.stopAsync === true) {
                        break;
                    }
                    if (Apps[key].name === json.data[0].attributes.name && Apps[key].platform === json.data[0].attributes.platform) {
                        let k = item.total_rev_30d / item.total_rev_1d;
                        if (isNaN(k)) {
                            k = 0;
                        }
                        Apps[key].predicted_k = k;
                        Apps[key].predicted_installs = item.tracked_installs;
                        break;
                    }
                }
                this.setState(() => {
                    return {
                        AppsFull: Apps,
                    }
                }, () => {

                });
            }


        } catch (error) {
            console.error('Ошибка:', error);
            console.error('Ошибка:', error.name);
            if (error.name === 'AbortError') { // обработать ошибку от вызова abort()

            }   else {
                this.setState(() => {
                    return {
                        error: true,
                        message: "Ошибка получения данных, возможно сервер недоступен, попробуйте позже #1654",
                    }
                });
            }
        }
    };

    getAppData = async (key_app, app_id) => {
        const offset = moment(this.state.dateEnd).diff(moment(this.state.dateStart), 'days');
        const prevEnd = moment(this.state.dateStart).subtract(1, 'days').format('YYYY-MM-DD');
        const prevStart = moment(prevEnd).subtract(offset, 'days').format('YYYY-MM-DD');
        const url = 'https://app-test-best.herokuapp.com/spend?start_date=' + prevStart + "&end_date=" + prevEnd +
            '&group_by=app' +
            '&app_ids=' + app_id +
            '&metrics=spend,installs,cpi,total_rev_120d,total_rev_30d,total_rev_1d,tracked_installs&granularity=totals-daily' +
            '&per_page=999';
        try {
            if (this.state.stopAsync === true) {
                return false;
            }
            const response = await fetch(url, {
                method: 'GET', // или 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token2,
                },
                signal: this.state.controller.signal,
            });
            this.reqs++;
            const Apps = this.state.AppsFull;
            const json = await response.json();

            if (json.data !== undefined && json.data[0] !== undefined && json.data[0].attributes !== undefined) {
                for (let key in Apps) {
                    if (this.state.stopAsync === true) {
                        break;
                    }
                    if (Apps[key].name === json.data[0].attributes.name && Apps[key].platform === json.data[0].attributes.platform) {
                        Apps[key].prevMetrics = json.data[0].attributes;
                        break;
                    }
                }
                this.setState(() => {
                    return {
                        AppsFull: Apps,
                    }
                }, () => {

                });
            }

        } catch (error) {
            console.error('Ошибка:', error);
            console.error('Ошибка:', error.name);
            if (error.name === 'AbortError') { // обработать ошибку от вызова abort()

            }   else {
                this.setState(() => {
                    return {
                        error: true,
                        message: "Ошибка получения данных, возможно сервер недоступен, попробуйте позже #1712",
                    }
                });
            }
        }
    };

    // Получаем все приложения и метрику по ним
    getDataNew = async (e) => {
        this.setState(() => {
            return {
                loading: true,
                stopAsync: false,
            }
        });

        const url = 'https://app-test-best.herokuapp.com/spend?start_date=' + this.state.dateStart + "&end_date=" + this.state.dateEnd +
            '&group_by=channel,app' +
            '&metrics=spend,installs,cpi,total_rev_1d,total_rev_120d,total_rev_30d,tracked_installs&granularity=totals-daily' +
            '&per_page=999';
        try {
            const responseData = await fetch(url, {
                method: 'GET', // или 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token2,
                },
                signal: this.state.controller.signal,
            });
            this.reqs++;

            const jsonData = await responseData.json();
            if(jsonData.code !== undefined) {
                if(jsonData.code === 504) {
                    this.setState(() => {
                        return {
                            error: true,
                            message: "Tenjin error, server timeout #1920",
                        }
                    });
                    return false;
                }   else if(jsonData.code === 204) {
                    this.setState(() => {
                        return {
                            error: true,
                            message: "Tenjin error, return empty request #1928",
                        }
                    });
                    return false;
                }   else if(jsonData.code === 304) {
                    this.setState(() => {
                        return {
                            error: true,
                            message: "Tenjin error, not return request #1928",
                        }
                    });
                    return false;
                }
                console.info("CODE: ", jsonData);
            }
            this.setState(() => {

                const date = jsonData.data;
                const apps = [];
                const channels = [];
                const dataChannels = [];

                for (let key in date) {
                    if (this.state.stopAsync) {
                        break;
                    }
                    if (date[key].attributes.app_id !== null) {
                        let app = Object.assign({}, date[key].attributes);
                        app.showChannels = false;
                        // app.channels = null;

                        if (this.state.stopAsync) {
                            break;
                        }

                        if (apps[app.app_id] === undefined) {
                            if (app.spend === null) {
                                app.spend = 0
                            }
                            ;
                            if (app.tracked_installs === null) {
                                app.tracked_installs = 0
                            }
                            ;
                            if (app.total_rev_1d === null) {
                                app.total_rev_1d = 0
                            }
                            ;
                            if (app.total_rev_120d === null) {
                                app.total_rev_120d = 0
                            }
                            ;
                            if (app.total_rev_30d === null) {
                                app.total_rev_30d = 0
                            }
                            ;
                            apps[app.app_id] = app;
                            apps[app.app_id].channels = [];
                            apps[app.app_id].channels.push(app.ad_network_id);
                        } else {
                            if (!apps[app.app_id].channels.includes(app.ad_network_id)) {
                                apps[app.app_id].spend += app.spend !== null ? app.spend : 0;
                                apps[app.app_id].tracked_installs += app.tracked_installs !== null ? app.tracked_installs : 0;
                                apps[app.app_id].total_rev_1d += app.total_rev_1d !== null ? app.total_rev_1d : 0;
                                apps[app.app_id].total_rev_120d += app.total_rev_120d !== null ? app.total_rev_120d : 0;
                                apps[app.app_id].total_rev_30d += app.total_rev_30d !== null ? app.total_rev_30d : 0;
                                apps[app.app_id].channels.push(app.ad_network_id);
                            }
                        }

                        app = Object.assign({}, date[key].attributes);

                        if (channels[app.ad_network_id] === undefined) {
                            channels[app.ad_network_id] = app;
                            channels[app.ad_network_id].empty = false;
                            channels[app.ad_network_id].showCampaigns = false;
                            channels[app.ad_network_id].showApps = false;
                            channels[app.ad_network_id].apps = [];
                            channels[app.ad_network_id].apps.push(app.app_id);
                        } else {
                            if (!channels[app.ad_network_id].apps.includes(app.app_id)) {
                                channels[app.ad_network_id].spend += app.spend !== null ? app.spend : 0;
                                channels[app.ad_network_id].tracked_installs += app.tracked_installs !== null ? app.tracked_installs : 0;
                                channels[app.ad_network_id].total_rev_1d += app.total_rev_1d !== null ? app.total_rev_1d : 0;
                                channels[app.ad_network_id].total_rev_120d += app.total_rev_120d !== null ? app.total_rev_120d : 0;
                                channels[app.ad_network_id].total_rev_30d += app.total_rev_30d !== null ? app.total_rev_30d : 0;
                                channels[app.ad_network_id].apps.push(app.app_id);
                            }
                        }

                        app = Object.assign({}, date[key].attributes);
                        let campanykey = app.ad_network_id + "" + app.app_id;

                        if (dataChannels[campanykey] === undefined) {
                            dataChannels[campanykey] = {};
                            if (dataChannels[campanykey].metrics === undefined) {
                                dataChannels[campanykey].metrics = null;
                                dataChannels[campanykey].empty = false;
                                dataChannels[campanykey].showCampaigns = false;
                            }
                        }
                        if (dataChannels[campanykey].metrics === null) {
                            dataChannels[campanykey].metrics = app;
                            // this.setChannelMetricsPrev(app.app_id, app.ad_network_id);
                        }

                    }
                }

                return {
                    Apps: apps,
                    AppsFull: apps,
                    Channels: channels,
                    loading: false,
                    dataChannels: dataChannels,
                    Campaigns: [],
                    stopAsync: false,
                    loadingData: false,
                }
            }, () => {
                this.getDataPrevNew();
                this.getDataPredictedNew();
            });

        } catch (error) {
            console.error('Ошибка:', error);
            console.error('Ошибка:', error.name);
            if (error.name === 'AbortError') { // обработать ошибку от вызова abort()

            }   else {
                this.setState(() => {
                    return {
                        error: true,
                        message: "Ошибка получения данных, возможно сервер недоступен, попробуйте позже #1808",
                    }
                });
            }

        }
    };


    // Получаем все приложения и метрику по ним за предыдущий период
    getDataPrevNew = async (e) => {
        const offset = moment(this.state.dateEnd).diff(moment(this.state.dateStart), 'days');
        const prevEnd = moment(this.state.dateStart).subtract(1, 'days').format('YYYY-MM-DD');
        const prevStart = moment(prevEnd).subtract(offset, 'days').format('YYYY-MM-DD');

        console.log(prevStart);
        console.log(prevEnd);

        this.setState(() => {
            return {
                loading: true,
                stopAsync: false,
            }
        });

        const url = 'https://app-test-best.herokuapp.com/spend?start_date=' + prevStart + "&end_date=" + prevEnd +
            '&group_by=channel,app' +
            '&metrics=spend,cpi,total_rev_1d,total_rev_120d,total_rev_30d,tracked_installs&granularity=totals-daily' +
            '&per_page=999';
        try {

            const responseData = await fetch(url, {
                method: 'GET', // или 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token2,
                },
                signal: this.state.controller.signal,
            });
            this.reqs++;

            if (this.state.stopAsync) {
                return false;
            }
            const jsonData = await responseData.json();

            let dataChannels = this.state.dataChannels;
            let ThisApps = this.state.AppsFull;
            let ThisChannels = this.state.Channels;

            this.setState(() => {
                const date = jsonData.data;
                for (let key in date) {
                    if (this.state.stopAsync) {
                        break;
                    }
                    if (date[key].attributes.app_id !== null) {
                        let app = Object.assign({}, date[key].attributes);

                        if (ThisApps[app.app_id] === undefined) {
                            continue;
                        }
                        if (ThisApps[app.app_id].prevMetrics === undefined || ThisApps[app.app_id].prevMetrics === null) {
                            if (app.spend === null) {
                                app.spend = 0
                            }
                            if (app.tracked_installs === null) {
                                app.tracked_installs = 0
                            }
                            if (app.total_rev_1d === null) {
                                app.total_rev_1d = 0
                            }
                            if (app.total_rev_120d === null) {
                                app.total_rev_120d = 0
                            }
                            if (app.total_rev_30d === null) {
                                app.total_rev_30d = 0
                            }
                            ThisApps[app.app_id].prevMetrics = app;
                        } else {
                            ThisApps[app.app_id].prevMetrics.spend += app.spend !== null ? app.spend : 0;
                            ThisApps[app.app_id].prevMetrics.tracked_installs += app.tracked_installs !== null ? app.tracked_installs : 0;
                            ThisApps[app.app_id].prevMetrics.total_rev_1d += app.total_rev_1d !== null ? app.total_rev_1d : 0;
                            ThisApps[app.app_id].prevMetrics.total_rev_120d += app.total_rev_120d !== null ? app.total_rev_120d : 0;
                            ThisApps[app.app_id].prevMetrics.total_rev_30d += app.total_rev_30d !== null ? app.total_rev_30d : 0;
                        }

                        app = Object.assign({}, date[key].attributes);

                        if (ThisChannels[app.ad_network_id] === undefined) {
                            break;
                        }
                        if (ThisChannels[app.ad_network_id].prevMetrics === undefined) {
                            ThisChannels[app.ad_network_id].prevMetrics = app;
                        } else {
                            ThisChannels[app.ad_network_id].prevMetrics.spend += app.spend !== null ? app.spend : 0;
                            ThisChannels[app.ad_network_id].prevMetrics.tracked_installs += app.tracked_installs !== null ? app.tracked_installs : 0;
                            ThisChannels[app.ad_network_id].prevMetrics.total_rev_1d += app.total_rev_1d !== null ? app.total_rev_1d : 0;
                            ThisChannels[app.ad_network_id].prevMetrics.total_rev_120d += app.total_rev_120d !== null ? app.total_rev_120d : 0;
                            ThisChannels[app.ad_network_id].prevMetrics.total_rev_30d += app.total_rev_30d !== null ? app.total_rev_30d : 0;
                        }

                        app = Object.assign({}, date[key].attributes);
                        let campanykey = app.ad_network_id + "" + app.app_id;

                        if (dataChannels[campanykey] === undefined) {
                            dataChannels[campanykey] = {};
                        }

                        if (dataChannels[campanykey].prevMetrics === undefined) {
                            if (app.spend === null) {
                                app.spend = 0
                            }
                            ;
                            if (app.tracked_installs === null) {
                                app.tracked_installs = 0
                            }
                            ;
                            if (app.total_rev_1d === null) {
                                app.total_rev_1d = 0
                            }
                            ;
                            if (app.total_rev_120d === null) {
                                app.total_rev_120d = 0
                            }
                            ;
                            if (app.total_rev_30d === null) {
                                app.total_rev_30d = 0
                            }
                            ;
                            dataChannels[campanykey].prevMetrics = app;

                            dataChannels[campanykey].empty = false;
                            dataChannels[campanykey].showCampaigns = false;
                        } else {
                            dataChannels[campanykey].prevMetrics.spend += app.spend !== null ? app.spend : 0;
                            dataChannels[campanykey].prevMetrics.tracked_installs += app.tracked_installs !== null ? app.tracked_installs : 0;
                            dataChannels[campanykey].prevMetrics.total_rev_1d += app.total_rev_1d !== null ? app.total_rev_1d : 0;
                            dataChannels[campanykey].prevMetrics.total_rev_120d += app.total_rev_120d !== null ? app.total_rev_120d : 0;
                            dataChannels[campanykey].prevMetrics.total_rev_30d += app.total_rev_30d !== null ? app.total_rev_30d : 0;
                        }

                    }
                }

                return {
                    AppsFull: ThisApps,
                    Channels: ThisChannels,
                    dataChannels: dataChannels,
                    loading: false,
                }
            });

        } catch (error) {
            console.error('Ошибка:', error);
            console.error('Ошибка:', error.name);
            if (error.name === 'AbortError') { // обработать ошибку от вызова abort()

            }   else {
                this.setState(() => {
                    return {
                        error: true,
                        message: "Ошибка получения данных, возможно сервер недоступен, попробуйте позже #1974",
                    }
                });
            }
        }
    };// Получаем все приложения и метрику по ним за предыдущий период


    getDataPredictedNew = async (e) => {
        let prevStart = moment(this.state.dateStart).subtract(60, "days").format('YYYY-MM-DD');
        let prevEnd = moment(this.state.dateStart).subtract(30, "days").format('YYYY-MM-DD');

        if(moment().diff(moment(this.state.dateStart), 'days') > 30) {
            prevEnd = this.state.dateStart;
            prevStart = moment(prevEnd).subtract(30, "days").format('YYYY-MM-DD');
        }   else {
            let nowSubFrom = moment().diff(moment(this.state.dateStart), 'days');
            let days30SubNowSub = 30-nowSubFrom;
            prevEnd = moment(this.state.dateStart).subtract(days30SubNowSub, "days").format('YYYY-MM-DD');
            prevStart = moment(prevEnd).subtract(30, "days").format('YYYY-MM-DD');
        }





        this.setState(() => {
            return {
                loading: true,
                stopAsync: false,
            }
        });

        const url = 'https://app-test-best.herokuapp.com/spend?start_date=' + prevStart + "&end_date=" + prevEnd +
            '&group_by=channel,app' +
            '&metrics=total_rev_1d,total_rev_3d,total_rev_7d,total_rev_14d,total_rev_120d,total_rev_30d,tracked_installs&granularity=totals-daily' +
            '&per_page=999';
        try {

            const responseData = await fetch(url, {
                method: 'GET', // или 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token2,
                },
                signal: this.state.controller.signal,
            });
            this.reqs++;

            const jsonData = await responseData.json();

            let dataChannels = this.state.dataChannels;
            let ThisApps = this.state.AppsFull;
            let ThisChannels = this.state.Channels;

            this.setState(() => {
                if (this.state.stopAsync) {
                    return false;
                }
                const date = jsonData.data;

                for (let key in date) {
                    if (this.state.stopAsync) {
                        break;
                    }
                    if (date[key].attributes.app_id !== null) {
                        let app = Object.assign({}, date[key].attributes);

                        if (ThisApps[app.app_id] === undefined) {
                            break;
                        }
                        if (ThisApps[app.app_id].predicted === undefined || ThisApps[app.app_id].predicted === null) {
                            if (app.total_rev_30d === null) {
                                app.total_rev_30d = 0
                            }
                            ;
                            if (app.tracked_installs === null) {
                                app.tracked_installs = 0
                            }
                            ;
                            if (app.total_rev_7d === null) {
                                app.total_rev_7d = 0
                            }
                            ;
                            if (app.total_rev_14d === null) {
                                app.total_rev_14d = 0
                            }
                            ;
                            if (app.total_rev_3d === null) {
                                app.total_rev_3d = 0
                            }
                            ;
                            if (app.total_rev_1d === null) {
                                app.total_rev_1d = 0
                            }
                            ;
                            ThisApps[app.app_id].predicted = app;
                        } else {
                            ThisApps[app.app_id].predicted.total_rev_30d += app.total_rev_30d !== null ? app.total_rev_30d : 0;
                            ThisApps[app.app_id].predicted.tracked_installs += app.tracked_installs !== null ? app.tracked_installs : 0;
                            ThisApps[app.app_id].predicted.total_rev_7d += app.total_rev_7d !== null ? app.total_rev_7d : 0;
                            ThisApps[app.app_id].predicted.total_rev_14d += app.total_rev_14d !== null ? app.total_rev_14d : 0;
                            ThisApps[app.app_id].predicted.total_rev_3d += app.total_rev_3d !== null ? app.total_rev_3d : 0;
                            ThisApps[app.app_id].predicted.total_rev_1d += app.total_rev_1d !== null ? app.total_rev_1d : 0;

                        }

                        app = Object.assign({}, date[key].attributes);

                        if (ThisChannels[app.ad_network_id] === undefined) {
                            break;
                        }
                        if (ThisChannels[app.ad_network_id].predicted === undefined) {
                            if (app.total_rev_30d === null) {
                                app.total_rev_30d = 0
                            }
                            ;
                            if (app.tracked_installs === null) {
                                app.tracked_installs = 0
                            }
                            ;
                            if (app.total_rev_7d === null) {
                                app.total_rev_7d = 0
                            }
                            ;
                            if (app.total_rev_14d === null) {
                                app.total_rev_14d = 0
                            }
                            ;
                            if (app.total_rev_3d === null) {
                                app.total_rev_3d = 0
                            }
                            ;
                            if (app.total_rev_1d === null) {
                                app.total_rev_1d = 0
                            }
                            ;
                            ThisChannels[app.ad_network_id].predicted = app;
                        } else {
                            ThisChannels[app.ad_network_id].predicted.total_rev_30d += app.total_rev_30d !== null ? app.total_rev_30d : 0;
                            ThisChannels[app.ad_network_id].predicted.tracked_installs += app.tracked_installs !== null ? app.tracked_installs : 0;
                            ThisChannels[app.ad_network_id].predicted.total_rev_7d += app.total_rev_7d !== null ? app.total_rev_7d : 0;
                            ThisChannels[app.ad_network_id].predicted.total_rev_14d += app.total_rev_14d !== null ? app.total_rev_14d : 0;
                            ThisChannels[app.ad_network_id].predicted.total_rev_3d += app.total_rev_3d !== null ? app.total_rev_3d : 0;
                            ThisChannels[app.ad_network_id].predicted.total_rev_1d += app.total_rev_1d !== null ? app.total_rev_1d : 0;
                        }

                        app = Object.assign({}, date[key].attributes);
                        let campanykey = app.ad_network_id + "" + app.app_id;

                        if (dataChannels[campanykey] === undefined) {
                            dataChannels[campanykey] = {};
                        }

                        if (dataChannels[campanykey].predicted === undefined) {
                            if (app.total_rev_30d === null) {
                                app.total_rev_30d = 0
                            }
                            ;
                            if (app.tracked_installs === null) {
                                app.tracked_installs = 0
                            }
                            ;
                            if (app.total_rev_7d === null) {
                                app.total_rev_7d = 0
                            }
                            ;
                            if (app.total_rev_14d === null) {
                                app.total_rev_14d = 0
                            }
                            ;
                            if (app.total_rev_3d === null) {
                                app.total_rev_3d = 0
                            }
                            ;
                            if (app.total_rev_1d === null) {
                                app.total_rev_1d = 0
                            }
                            ;
                            dataChannels[campanykey].predicted = app;
                        } else {
                            dataChannels[campanykey].predicted.total_rev_30d += app.total_rev_30d !== null ? app.total_rev_30d : 0;
                            dataChannels[campanykey].predicted.tracked_installs += app.tracked_installs !== null ? app.tracked_installs : 0;
                            dataChannels[campanykey].predicted.total_rev_7d += app.total_rev_7d !== null ? app.total_rev_7d : 0;
                            dataChannels[campanykey].predicted.total_rev_14d += app.total_rev_14d !== null ? app.total_rev_14d : 0;
                            dataChannels[campanykey].predicted.total_rev_3d += app.total_rev_3d !== null ? app.total_rev_3d : 0;
                            dataChannels[campanykey].predicted.total_rev_1d += app.total_rev_1d !== null ? app.total_rev_1d : 0;
                        }

                    }
                }

                return {
                    AppsFull: ThisApps,
                    Channels: ThisChannels,
                    dataChannels: dataChannels,
                    error: false,
                    loading: false,
                }
            });

        } catch (error) {
            console.error('Ошибка:', error);
            console.error('Ошибка:', error.name);
            if (error.name === 'AbortError') { // обработать ошибку от вызова abort()

            }   else {
                this.setState(() => {
                    return {
                        error: true,
                        message: "Ошибка получения данных, возможно сервер недоступен, попробуйте позже #2178",
                    }
                });
            }
        }
    };

    // Получаем все приложения и метрику по ним
    getData = async (e) => {
        this.setState(() => {
            return {
                loading: true,
                stopAsync: false,
            }
        });
        const url = 'https://app-test-best.herokuapp.com/spend?start_date=' + this.state.dateStart + "&end_date=" + this.state.dateEnd +
            '&group=app_id' +
            '&metrics=spend,installs,cpi,total_rev_1d,total_rev_120d,total_rev_30d,tracked_installs&granularity=totals-daily' +
            '&per_page=999';
        try {
            const response = await fetch(url, {
                method: 'GET', // или 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token2,
                },
                signal: this.state.controller.signal,
            });
            this.reqs++;

            const json = await response.json();
            this.setState((Data) => {
                let date = json.data;
                let apps = [];
                let k = 0;
                for (let key in date) {
                    if (date[key].attributes.bundle_id !== null) {
                        let app = date[key].attributes;
                        app.showChannels = false;
                        app.channels = null;
                        app['id'] = date[key].id;
                        apps.push(app);
                        this.getAppData(k, app.app_id);
                        this.getAppDataPredicted(k, app.app_id);
                        k++;
                    }
                }
                let clearChannels = [];
                let clearCampaigns = [];
                return {
                    Apps: apps,
                    AppsFull: apps,
                    error: false,
                    dataChannels: clearChannels,
                    Campaigns: clearCampaigns,
                    stopAsync: false,
                }
            }, () => {
                // this.getCampaigns();
                // this.getChannels();
                this.setState(() => {
                    let newApps = this.sortingArr(this.state.AppsFull, this.state.sorting);
                    return {
                        Apps: newApps,
                        AppsFull: newApps,
                        loading: false,
                    }
                }, () => {
                    for (let key in this.state.Apps) {
                        this.getCampaigns(key, false);
                    }
                })
            });

        } catch (error) {
            console.error('Ошибка:', error);
            console.error('Ошибка:', error.name);
            if (error.name === 'AbortError') { // обработать ошибку от вызова abort()

            }   else {
                this.setState(() => {
                    return {
                        error: true,
                        message: "Ошибка получения данных, возможно сервер недоступен, попробуйте позже #2258",
                    }
                });
            }
        }
    };


    render() {
        const {Apps, Channels, viewLevel1, viewLevel2, dataChannels, Campaigns, AppsFull, showDetails, showGeos, detailsData, detailsK,targetROAS} = this.state;
        return (
            <div className="app">
                {targetROAS > 0  ?
                    <Controls
                        dateStartHistory={this.state.dateStartHistory}
                        dateEndHistory={this.state.dateEndHistory}
                        history={this.state.history}
                        setHistory={this.setHistory}
                        lengths={Object.keys(AppsFull).length}
                        loadingData={this.state.loadingData}
                        toggleView={this.toggleView}
                        toggleROAS={this.toggleROAS}
                        targetROAS={targetROAS}
                        toggleTop5={this.toggleTop5}
                        changeDate={this.OnChangeDate}
                        changeDateHistory={this.OnChangeDateHistory}
                        getCountries={this.getCountries}
                        toggleShowEmpty={this.toggleShowEmpty}
                        showDetails={showDetails}
                    />
                : ""}

                {this.state.error && this.state.message ?
                    <div className="alert alert-danger" role="alert">
                        {this.state.message}
                    </div>
                    : ""
                }
                {this.state.loading ?
                    <div className="alert alert-primary" role="alert">
                        Загрузка...
                    </div>
                    : ""
                }
                {this.state.dateStart === "" ?
                    <div className="alert alert-success" role="alert">
                        Установите нужные даты
                    </div>
                    : ""
                }
                {showDetails && detailsData.length > 0 ?
                    <Details
                        history={this.state.history}
                        setHistory={this.setHistory}
                        detailsData={detailsData}
                        showDetails={this.showDetails}
                        detailsK={detailsK}
                        targetROAS={this.state.targetROAS}
                        sorting={this.sorting}
                        sort={this.state.sorting}
                    />
                    :
                    showGeos ?
                        <TableGeos
                            favorites={this.state.favorites}
                            showGeos={this.showGeos}
                            setFavorite={this.setFavorite}
                            getDetails={this.getDetails}
                            top5={this.state.top5}
                            showDetails={this.state.showDetails}
                            targetROAS={this.state.targetROAS}
                            showEmptyCountries={this.state.showEmptyCountries}
                            sorting={this.sorting}
                            sort={this.state.sorting}
                            Countries={this.state.Countries}
                            data={this.state.geos}
                            setSearch={this.setSearchText}
                            dataChannels={dataChannels}
                            getCampaigns={this.getCampaigns}
                            setCampaignsData={this.setCampaignsData}
                            changeShowCountries={this.changeShowCountries}
                            showCampaignsInCountry={this.showCampaignsInCountry}
                            channels={Channels}
                            viewLevel1={viewLevel1}
                            viewLevel2={viewLevel2}
                            campaigns={Campaigns}
                            onDeleted={this.deleteItem}
                            changeLast={this.OnChangeLast}
                        />
                        :
                        viewLevel1 === "1" ?
                            <Table
                                favorites={this.state.favorites}
                                getDetails={this.getDetails}
                                setFavorite={this.setFavorite}
                                top5={this.state.top5}
                                showDetails={this.state.showDetails}
                                targetROAS={this.state.targetROAS}
                                showEmptyCountries={this.state.showEmptyCountries}
                                sorting={this.sorting}
                                sort={this.state.sorting}
                                Countries={this.state.Countries}
                                data={this.state.searchText !== "" ? Apps : AppsFull}
                                setSearch={this.setSearchText}
                                dataChannels={dataChannels}
                                getCampaigns={this.getCampaigns}
                                setCampaignsData={this.setCampaignsData}
                                changeShowCountries={this.changeShowCountries}
                                showCampaignsInCountry={this.showCampaignsInCountry}
                                channels={Channels}
                                viewLevel1={viewLevel1}
                                viewLevel2={viewLevel2}
                                campaigns={Campaigns}
                                onDeleted={this.deleteItem}
                                changeLast={this.OnChangeLast}
                            />
                            :
                            <TableChannels
                                getDetails={this.getDetails}
                                AppsFull={this.state.AppsFull}
                                Countries={this.state.Countries}
                                showApps={this.showApps}
                                showDetails={this.state.showDetails}
                                top5={this.state.top5}
                                targetROAS={this.state.targetROAS}
                                showEmptyCountries={this.state.showEmptyCountries}
                                sorting={this.sorting}
                                sort={this.state.sorting}
                                data={this.state.searchText !== "" ? Apps : AppsFull}
                                setSearch={this.setSearchText}
                                dataChannels={dataChannels}
                                getCampaigns={this.getCampaigns}
                                setCampaignsData={this.setCampaignsData}
                                changeShowCountries={this.changeShowCountries}
                                showCampaignsInCountry={this.showCampaignsInCountry}
                                channels={Channels}
                                viewLevel1={viewLevel1}
                                viewLevel2={viewLevel2}
                                campaigns={Campaigns}
                                onDeleted={this.deleteItem}
                                changeLast={this.OnChangeLast}
                            />
                }


            </div>
        );
    }
};

export default App;

